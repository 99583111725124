<template>
  <div>
    <UserLayout>
      <CardTwoContentLoader class="mt-5" v-if="showLoader"/>
      <section class="bg-signIn d-flex" v-else>
        <div class="container">
          <div class="py-6">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-lg-11">
               
                <div class="d-lg-flex justify-content-between align-items-end mt-5">
                  <p class="text-end d-lg-none">
                     <router-link
                      :to="{ name: 'UserAddNewPrayerRequest' }"
                      class="updated btn shadow-sm"
                    >
                      Add New Prayer Request
                    </router-link>
                  </p>
                  <h2 class="header-text text-dark ">Prayer Requests</h2>
                  <router-link
                    :to="{ name: 'UserAddNewPrayerRequest' }"
                    class="updated btn shadow-sm d-none d-lg-flex"
                  >
                    Add New Prayer Request
                  </router-link>
                </div>

                <div class="row mt-4" v-if="userType == 'super_admin'">
                  <div class="col-12 col-lg-6">
                    <div
                      class="card p-card1 p-3 b-color d-flex flex-row shadow-sm"
                    >
                      <div class="mt-2">
                        <img
                          src="../../assets/prayerAnswered.svg"
                          alt=""
                          class="img-fluid me-2"
                          width="30"
                        />
                      </div>
                      <div class="">
                        <h4 class="mb-0">
                          {{ prayerDataCount?.answered_prayers }}
                        </h4>
                        <p class="text-body-secondary">Answered</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div
                      class="card p-card2 p-3 b-color d-flex flex-row shadow-sm"
                    >
                      <div class="mt-2">
                        <img
                          src="../../assets/prayerActive.svg"
                          alt=""
                          class="img-fluid me-2"
                          width="30"
                        />
                      </div>
                      <div class="">
                        <h4 class="mb-0">
                          {{ prayerDataCount?.active_prayers }}
                        </h4>
                        <p class="text-body-secondary">Active</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card p-3 mt-4 shadow-sm b-color">
                  <div
                    class="d-flex justify-content-between align-items-end"
                  >
                    <h4 class="text-dark mb-0">Active Prayers</h4>
                    <router-link
                      :to="{ name: 'UserAllActivePrayers' }"
                      class="text-primary"
                    >
                      View All
                    </router-link>
                  </div>

                  <div class="card p-card2 b-color p-3 mt-3" v-if="activePrayerRequestData.length <= 0">
                     <h5 class="text-center text-capitalize fw-medium">
                          No Active Prayers
                        </h5>
                  </div >

                  <div
                    class="card p-card2 b-color p-3 mt-3"
                    v-for="(prayer, index) in activePrayerRequestData"
                    :key="index"
                  >
                    <router-link
                      :to="{
                        name: 'UserPrayerRequestInfo',
                        params: { id: prayer.id },
                      }"
                      class="text-decoration-none text-black"
                    >
                      <div class="d-flex justify-content-between">
                        <h5>{{ prayer.subject }}</h5>
                        <p class="text-body-secondary">3 days Ago</p>
                      </div>
                      <p
                        class="text-body-secondary mt-0"
                        v-for="conversation in prayer.conversation?.messages"
                        :key="conversation"
                      >
                        {{ conversation?.message }}
                      </p>
                    </router-link>
                  </div>

                  <div
                    class="d-flex justify-content-between align-items-end mt-5"
                  >
                    <h4 class="text-dark mb-0">Answered Prayers</h4>
                    <router-link
                      :to="{ name: 'UserAllAnsweredPrayers' }"
                      class="text-primary"
                    >
                      View All
                    </router-link>
                  </div>

                  <div
                    class="card p-card1 b-color p-3 mt-3"
                    v-if="answeredRequestData.length <= 0"
                  >
                    <h5 class="text-center text-capitalize fw-medium">No Answered Prayers</h5>
                  </div>
                  <div
                    class="card p-card1 b-color p-3 mt-3"
                    v-for="(prayer, index) in answeredRequestData"
                    :key="index"
                  >
                    <div class="d-flex justify-content-between">
                      <h5>{{ prayer ? prayer.subject : "" }}</h5>
                      <p class="text-body-secondary">3 days Ago</p>
                    </div>
                    <p
                      class="text-body-secondary mt-0"
                      v-for="conversation in prayer.conversation?.messages"
                      :key="conversation"
                    >
                      {{ conversation?.message }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";

import axios from "@/Plugins/axios";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";

import UserLayout from "@/components/Layouts/UserLayout.vue";
import CardTwoContentLoader from "@/components/CardTwoContentLoader.vue"

import {useAuthStore} from "@/stores/auth"


const store = useAuthStore();

const userType = ref(store.user?.user_type);



const prayerPointEndpoint = ref('');


const prayerDataCount = ref({});

const showLoader = ref(false);

const prayerRequestData = ref([]);
const activePrayerRequestData = ref([]);
const answeredRequestData = ref([]);

function getPrayerRequestCount() {
  axios
    .get(`/admin/prayer-point-count`)
    .then((response) => {
      prayerDataCount.value = response.data.data;
    })
    .catch((error) => {
      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

function getAllPrayerRequest() {
  showLoader.value = true
  prayerPointEndpoint.value = utils.getPrayerPointEndpoint(userType.value)

  axios
    .get(`${prayerPointEndpoint.value}`)
    .then((response) => {
       showLoader.value = false
      prayerRequestData.value = response.data.data;

      activePrayerRequestData.value = prayerRequestData.value.filter(
        (item) => item.is_answered == false
      );
      answeredRequestData.value = prayerRequestData.value.filter(
        (item) => item.is_answered !== false
      );
    })
    .catch((error) => {
       showLoader.value = false

      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

onMounted(() => {
  
  if(userType.value == 'super_admin'){

    getPrayerRequestCount();
  }
  getAllPrayerRequest();
 
});
</script>

<style scoped>
/* * {
  outline: 1px solid red;
} */

.header-text {
  font-weight: 900;
  font-size: 2.3rem;
}
.header-text2 {
  font-weight: 900;
  font-size: 1.8rem;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.py-6 {
  padding: 6rem 0;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.p-card1 {
  background-color: rgba(248, 248, 248, 1) !important;
}

.p-card2 {
  background-color: rgba(237, 255, 227, 1);
}
</style>
