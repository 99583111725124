<template>
  <div class="media-player">
    <video v-if="isVideo(mediaUrl)" :src="mediaUrl" controls></video>
    <audio v-else-if="isAudio(mediaUrl)" :src="mediaUrl" controls></audio>
    <img v-else-if="isImage(mediaUrl)" :src="mediaUrl" alt="Media" class="img-fluid" height="200" width="300">
    <object v-else-if="isPdf(mediaUrl)" :data="mediaUrl" type="application/pdf" width="100%" height="500px"></object>
    <p v-else>Unsupported media type</p>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  mediaUrl: {
    type: String,
    required: true
  }
});

function getExtension(url) {
  const parts =  url.split('.').pop().toLowerCase();
  console.log(url.split('.').pop().toLowerCase())
  return parts;
}

function isVideo(url) {
  const extension = getExtension(url);
  return ['mp4', 'webm', 'avi', 'mov'].includes(extension);
}

function isAudio(url) {
  const extension = getExtension(url);
  return ['mp3', 'wav', 'ogg'].includes(extension);
}

function isImage(url) {
  const extension = getExtension(url);
  return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension);
}

function isPdf(url) {
  const extension = getExtension(url);
  return ['pdf'].includes(extension);
}

function isSupported(url) {
  const extension = getExtension(url);
  return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'mp4', 'webm', 'avi', 'mov', 'mp3', 'wav', 'ogg', 'pdf'].includes(extension);
}
</script>
