<template>
  <div>
    <AdminLayout Title="Recordings" :isRequired="false">
      <TableSkeletonLoader class="pt-6" v-if="loading" />

      <div class="container pt-6" v-else>
        <router-link
          :to="{ name: 'AdminRecordings' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <div class="row mt-4 d-flex justify-content-center">
          <div class="col-12 col-lg-7">
            <div class="position-relative">
              <a :href="`${recordingDetails?.link}`">
                <img
                  src="../../assets/video-circle2.png"
                  alt=""
                  class="img-fluid position-absolute"
                  style="left: 45%; top: 25%; cursor: pointer"
                />
              </a>
              <img
                src="../../assets/recordingtrumpet.png"
                alt=""
                class="img-fluid w-100"
              />
            </div>
            <div class="bg-card1 p-3 rounded-3">
              <div class="fw-semibold" style="font-size: 1.4rem">
                {{ recordingDetails?.title }}
              </div>

              <p style="font-size: 1.2rem fw-medium">
                {{ recordingDetails?.description }}
              </p>
              <div class="d-flex mt-3">
                <div class="d-flex align-items-center ms-2">
                  <img
                    src="../../assets/calendar-2b.svg"
                    alt=""
                    class="img-fluid mb-3 me-1"
                    width="20"
                    height="20"
                  />
                  <p>
                    {{ getFormattedDate(recordingDetails?.created_at) }}
                  </p>
                </div>
              </div>
            </div>

            <p class="rounded-3 mt-4">
              <button
                class="updated text-center w-100 shadow-sm"
                @click.prevent="showUpdateRecording = true"
              >
                Update Recording Details
              </button>
            </p>
            <p class="rounded-3 mt-4">
              <button
                class="remove-btn text-center w-100 shadow-sm"
                @click.prevent="showRemoveRecording = true"
              >
                Delete Recording
              </button>
            </p>
          </div>
        </div>
      </div>

      <Modal :show="showRemoveRecording">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="showRemoveRecording = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-sign-out.png"
                alt=""
                class="img-fluid"
                width="100"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">Remove Recording</p>
            <p class="fw-light mt-0 text-center">
              Are you sure you want to remove recording?
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <button
                class="signOutBtn btn-lg text-capitalize font-weight-bold"
                @click.prevent="removeRecording"
              >
                Yes, Remove
              </button>
              <button
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4"
                @click.prevent="showRemoveRecording = false"
              >
                Cancel and Go back
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>

      <Modal :show="showUpdateRecording">
        <template #content>
          <img
            src="../../assets/close-circle.png"
            alt=""
            class="img-fluid position-absolute"
            style="top: 0; right: 0; cursor: pointer"
            @click.prevent="showUpdateRecording = false"
          />
          <form
            action=""
            class="border-1 rounded-2 p-3"
            style="overflow-y: scroll"
          >
            <h3 class="header-text2">Update Recordings</h3>
            <div class="row">
              <div class="col-12">
                <div class="col-12">
                  <p class="form-label mt-3">Title</p>
                  <input
                    type="text"
                    required
                    v-model="recordingDetailsForm.title"
                    class="p-2 form-control"
                  />
                  <FormError :errorMessage="formErrors.title" />
                </div>
                <div class="col-12">
                  <p class="form-label mt-3">Link</p>
                  <input
                    type="text"
                    required
                    v-model="recordingDetailsForm.link"
                    class="p-2 form-control"
                  />
                  <FormError :errorMessage="formErrors.link" />
                </div>

                <div class="col-12">
                  <p class="form-label mt-3">Description</p>
                  <textarea
                    name=""
                    id=""
                    v-model="recordingDetailsForm.description"
                    class="form-control"
                  ></textarea>
                  <FormError :errorMessage="formErrors.description" />
                </div>
              </div>

              <div class="col-12 mt-4">
                <p class="">
                  <button
                    class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                    @click.prevent="updateRecordingDetail"
                  >
                    <span>Update Recordings</span>
                    <div>
                      <img
                        src="../../assets/arrow-right.svg"
                        alt=""
                        width="40"
                        height="40"
                        class="img-fluid"
                        v-if="!loading2"
                      />
                      <div
                        class="spinner-grow text-white"
                        role="status"
                        v-else-if="loading2"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </button>
                </p>
              </div>
            </div>
          </form>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
      <Modal :show="roleSuccessfullyChanged">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="markedSuccessful = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-check-success.svg"
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Recording Updated Successfully
            </p>
            <p class="text-center">
              You have successfully updated this recording
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <router-link
                :to="{ name: 'AdminRecordings' }"
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4 text-decoration-none"
                @click.prevent="markedSuccessful = false"
              >
                Back to Home
              </router-link>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";

import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import {
  getFormattedDate,
  getFormattedDateNumber,
  getFormattedAmount,
} from "@/Utils/handleResponse";

import Multiselect from "vue-multiselect";
import FormError from "@/components/FormError.vue";

const recordingDetails = ref({});
const showPassword = ref(false);

const loading = ref(false);
const loading2 = ref(false);

const formErrors = ref({});

const router = useRouter();

const { id } = useRoute().params;

const recordingDetailsForm = ref({
  _method: "PUT",
  title: "",
  link: "",
  description: "",
});

function getRecordingDetail() {
  loading.value = true;
  axios
    .get(`/recordings/${id}`)
    .then((response) => {
      recordingDetails.value = response.data.data;
      recordingDetailsForm.value.title = recordingDetails.value?.title;
      recordingDetailsForm.value.link = recordingDetails.value?.link;
      recordingDetailsForm.value.description =
        recordingDetails.value?.description;
      loading.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading.value = false;
    });
}

function updateRecordingDetail() {
  loading2.value = true;
  axios
    .put(`/admin/recordings/${id}`, recordingDetailsForm.value)
    .then((response) => {
      showUpdateRecording.value = false;
      roleSuccessfullyChanged.value = true;
      loading2.value = false;
      getRecordingDetail();
    })
    .catch((error) => {
      formErrors.value = catchAxiosExceptions(error).formErrors;

      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading2.value = false;
    });
}

function removeRecording() {
  axios
    .delete(`/recordings/${id}`)
    .then((response) => {
      utils.showToast("Successful", "success");
      router.push({ name: "AdminRecordings" });
    })
    .catch((error) => {
      formErrors.value = catchAxiosExceptions(error).formErrors;

      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

onMounted(() => {
  getRecordingDetail();
});

const showRemoveRecording = ref(false);
const roleSuccessfullyChanged = ref(false);
const showUpdateRecording = ref(false);

// function successfullySubmitted() {
//   showUpdateRecording.value = false;
//   roleSuccessfullyChanged.value = true;
// }
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
/* *{
    outline: 1px solid red;
} */

:deep(.multiselect__element) {
  background-color: transparent !important;
}

:deep(.multiselect__option--highlight) {
  background-color: blue !important;
  color: #fff !important;
}
.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.remove-btn {
  background-color: #ffeeee !important;
  font-weight: 500 !important;
  border: none;
  color: #d72509 !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}
.pt-6 {
  padding-top: 6rem !important;
}
.bg-card1 {
  background-color: #e4f5ff !important;
}
.btn-active {
  border: none !important;
  color: #f29036 !important;
  background-color: #fff9e2 !important;
  padding: 0.2rem !important;
  border-radius: 5px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.signOutBtn {
  background-color: #f24c35 !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.bg-delete {
  background-color: #fff4f4 !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}
</style>
