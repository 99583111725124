<template>
  <div>
    <AdminLayout Title="Prayer Request" :isRequired="false">
      <div class="container pt-6">
        <router-link
          :to="{ name: 'AdminPrayerRequest' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <div class="row mt-4 d-flex justify-content-center">
          <div class="col-12 col-lg-7">
            <div class="bg-card1 p-3 rounded-3">
              <div class="d-flex align-items-center">
                <img
                  src="../../assets/avatar.svg"
                  alt=""
                  class="img-fluid"
                  width="100"
                />
                <div class="ms-3">
                  <h5 class="mb-0">Emmanuel Okaka</h5>
                  <p class="text-body-secondary mt-0">
                    okakaemmanuel@gmail.com
                  </p>
                </div>
              </div>
              <div class="d-flex mt-3 row g-2">
                <div class="col-12 col-lg-4 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary">Submission Date</small>
                    <p class="fw-semibold mt-3">24th Jan 2024</p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary">Deadline</small>
                    <p class="fw-semibold mt-3">24th Jan 2024</p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary d-block mb-1"
                      >Status</small
                    >
                    <p class="pt-2">
                      <button class="fw-semibold btn-active d-block">
                        Active
                      </button>
                    </p>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-12">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary fw-semilight"
                      >Prayer Request</small
                    >
                    <p class="fw-semilight mt-3" style="font-weight: 400">
                      Merciful Father, I humbly come before You to confess my
                      sins and shortcomings. Wash me clean and purify my heart,
                      Lord, as I repent of my transgressions and seek Your
                      forgiveness. Heal the wounds of my past and grant me the
                      strength to forgive others as You have forgiven me, that I
                      may experience the fullness of Your grace and mercy.
                    </p>
                  </div>
                </div>
              </div>

              <form class="mt-3 b-color">
                <textarea name="" id="" cols="30" rows="10" class="form-control" placeholder="Enter your feedback here"></textarea>
                <p class="mt-4">
                 <button
                   class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                   @click.prevent="showActions = true"
                 >
                   <span>Submit Feedback to Prayer</span>
                   <div>
                     <img
                       src="../../assets/arrow-right.svg"
                       alt=""
                       width="40"
                       height="40"
                       class="img-fluid"
                       v-if="!loading"
                     />
                     <div
                       class="spinner-grow text-white"
                       role="status"
                       v-else-if="loading"
                     >
                       <span class="visually-hidden">Loading...</span>
                     </div>
                   </div>
                 </button>
               </p>
              </form>

            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  </div>
</template>
<script setup>
import {ref} from "vue"
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
const loading = ref(false)
</script>
<style scoped>
.pt-6 {
  padding-top: 6rem !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.bg-card1 {
  background-color: #e4f5ff !important;
}
.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.2rem !important;
  border-radius: 5px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
</style>
