<template>
  <div>
    <AdminLayout Title="Counselling" :isRequired="false">
      <TableSkeletonLoader class="pt-6" v-if="loading2" />

      <div class="container pt-6" v-else>
        <router-link
          :to="{ name: 'AdminCounselling' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <div class="row mt-4 d-flex justify-content-center">
          <div class="col-12 col-lg-7">
            <div
              class="p-4 position-relative home3 rounded-bottom-0 rounded-top-3"
              style="cursor: pointer"
            >
             
              <div class="text-white py-2">
                <h5 class="fw-semibold text-capitalize">
                  Create New Counselling Period
                </h5>
              </div>
            </div>
            <div class="bg-white rounded-bottom-1 p-3 b-color">
              <form class="mt-3 border-0">
                <div class="mb-3">
                  <p class="form-label fw-medium">Counselling Title</p>
                  <input
                    type="text"
                    class="form-control p-3"
                    placeholder="Enter title"
                    v-model="counsellingPayload.title"
                  />
                  <FormError :errorMessage="formErrors.title" />
                </div>
                <div class="mb-3">
                  <p class="form-label fw-medium">Assigned To</p>
                  <multiselect
                    v-model="selectedUserObject"
                    :customLabel="customLabel"
                    track-by="id"
                    value-key="id"
                    :options="users"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :show-no-results="false"
                    class=""
                  >
                    <template #noResult>
                      <span
                        >Oops! No User found. Consider changing your search
                      </span>
                    </template>
                  </multiselect>
                  <FormError :errorMessage="formErrors.assigned_to" />
                </div>
                <div class="mb-3">
                  <p class="form-label fw-medium">Start Time</p>
                 
                  <input
                   type="time"
                    class="form-control p-3"
                    v-model="counsellingPayload.start_time"
                  />
                  <FormError :errorMessage="formErrors.start_time" />
                </div>
                <div class="mb-3">
                  <p class="form-label fw-medium">End Time</p>
                 
                  
                  <input
                    type="time"
                    class="form-control p-3"
                    v-model="counsellingPayload.end_time"
                  />
                  <FormError :errorMessage="formErrors.end_time" />
                </div>

                <div class="mb-3">
                  <p class="form-label fw-medium">Session Date</p>
                  <input
                    type="date"
               
                    class="form-control p-3"
                    v-model="counsellingPayload.session_date"
                  />
                  <!-- <small></small> -->
                  <FormError :errorMessage="formErrors.session_date" />
                </div>
                <div class="mb-3">
                  <p class="form-label fw-medium">Session Duration</p>
                  <input
                    type="number"
                    placeholder="Number of minutes per session"
                    class="form-control p-3"
                    v-model="counsellingPayload.session_duration"
                  />
                  <!-- <small></small> -->
                  <FormError :errorMessage="formErrors.session_duration" />
                </div>
                <div class="mb-3">
                  <p class="form-label fw-medium">Current Status</p>
                  <multiselect
                    v-model="counsellingPayload.status"
                    :options="status"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :show-no-results="false"
                  >
                    <template #noResult>
                      <span
                        >Oops! No User found. Consider changing your search
                      </span>
                    </template>
                  </multiselect>
                  <FormError :errorMessage="formErrors.status"/>
                </div>

                <p class="mt-4">
                  <button
                    class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                    @click.prevent="postCounselling"
                  >
                    <span>Create Counselling Period</span>
                    <div>
                      <img
                        src="../../assets/arrow-right.svg"
                        alt=""
                        width="40"
                        height="40"
                        class="img-fluid"
                        v-if="!loading"
                      />
                      <div
                        class="spinner-grow text-white"
                        role="status"
                        v-else-if="loading"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </button>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal :show="postedSuccessfully">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="postedSuccessfully = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-check-success.svg"
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Counselling Session Posted Successfully
            </p>

            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <router-link
                :to="{ name: 'AdminCounselling' }"
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4 text-decoration-none"
                @click.prevent="postedSuccessfully = false"
              >
                Back to Home
              </router-link>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";
import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";
import FormError from "@/components/FormError.vue"


import Multiselect from "vue-multiselect";

const status = ref(["Active", "Upcoming", "Completed", "Inactive"]);

const loading = ref(false);
const loading2 = ref(false);
const postedSuccessfully = ref(false);

const formErrors = ref({})




const customLabel = ({ first_name, last_name }) =>
  `${first_name ? first_name : ""} ${last_name ? last_name : ""}`;

const users = ref([]);
const selectedUserObject = ref({});

const counsellingPayload = reactive({
  title: null,
  assigned_to: null,
  start_time: '',
  end_time: '',
  session_duration: null,
  session_date: null,
  status: null,
});





watch(selectedUserObject, (newVal, oldVal) => {
  counsellingPayload.assigned_to = newVal?.id;
});

function getUsers() {
  loading2.value = true;
  axios
    .get(`/admin/users`)
    .then((response) => {
      users.value = response.data.data;
      loading2.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading2.value = false;
    });
}

function postCounselling() {

  formErrors.value = {}

  loading.value = true;
  axios
    .post(`/admin/counselling-sessions`, counsellingPayload)
    .then((response) => {
      postedSuccessfully.value = true;
      loading.value = false;
    })
    .catch((error) => {
    formErrors.value = catchAxiosExceptions(error).formErrors

      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading.value = false;
    });
}

onMounted(() => {
  getUsers();
});
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
input,
textarea {
  background-color: #f5fbfc !important;
}

:deep(.multiselect__tags),
:deep(.multiselect__input),
:deep(.multiselect__single) {
  background-color: #f5fbfc !important;
}
.pt-6 {
  padding-top: 6rem !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.bg-card1 {
  background-color: #e4f5ff !important;
}
.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.2rem !important;
  border-radius: 5px;
}

:deep(.multiselect__element) {
  background-color: transparent !important;
}

:deep(.multiselect__option--highlight) {
  background-color: blue !important;
  color: #fff !important;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.home3 {
  background-color: rgba(224, 58, 111, 1) !important;
  background-image: url("../../assets/couselling-header.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}
</style>
