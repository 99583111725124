<template>
  <div>
    <DashboardNav
      @signOutEvent="handleSignOutEvent"
      @updatePassword="handleUpdatePassword"
    />

    <Modal :show="showModal">
      <template #content>
        <div
          class="d-flex justify-content-center flex-column position-relative"
        >
          <img
            src="../../assets/close-circle.png"
            alt=""
            class="img-fluid position-absolute"
            style="top: 0; right: 0; cursor: pointer"
            @click="showModal = false"
          />
          <div class="d-flex justify-content-center">
            <img
              src="../../assets/signnOut.svg"
              alt=""
              class="img-fluid"
              width="100"
            />
          </div>
          <p class="text-center mt-3 header-text2 mb-0">Sign Out</p>
          <p class="fw-light mt-0 text-center">
            Are you sure you want to sign out?
          </p>
          <p class="text-center d-block d-md-flex justify-content-center mt-3">
            <button
              @click.prevent="logOut"
              class="btn signOutBtn btn-lg text-capitalize font-weight-bold"
            >
              Yes Logout
            </button>
            <button
              class="btn loggedInBtn2 btn-lg text-capitalize font-weight-bold ms-2 ms-md-4"
              @click.prevent="showModal = false"
            >
              No Keep Me Logged In
            </button>
          </p>
        </div>
      </template>
      <!-- <template #footer>
        <p>hello</p>
      </template> -->
    </Modal>
    <Modal :show="showUpdatePassword" width="600" class="bg-transparent">
      <template #content>
        <div
          class="d-flex justify-content-center flex-column position-relative"
        >
          <img
            src="../../assets/close-circle.png"
            alt=""
            class="img-fluid position-absolute"
            style="top: 0; right: 0; cursor: pointer"
            @click="showUpdatePassword = false"
          />
          <form
            action=""
            class="border-1 rounded-2 p-3"
            style="overflow-y: scroll"
            @submit.prevent="updatePassword"
          >
            <h3 class="header-text2">Update Password</h3>
            <div class="row">
              <div class="col-12 mt-4 mb-3">
                <h6 class="form-label">Current Password</h6>
                <input
                  type="text"
                  placeholder="Enter your current password"
                  class="form-control p-3 form-bg"
                  v-model="updateForm.old"
                />
              </div>
              <div class="col-12 mt-4 mb-3">
                <h6 class="form-label">New Password</h6>
                <input
                  type="text"
                  placeholder="Enter your new password"
                  class="form-control p-3 form-bg"
                  v-model="updateForm.password"
                />
              </div>
              <div class="col-12 mt-4 mb-5">
                <h6 class="form-label">Re-enter New Password</h6>
                <input
                  type="text"
                  placeholder="Enter your new password"
                  class="form-control p-3 form-bg"
                  v-model="updateForm.password_confirmation"
                />
              </div>

              <p>
                <button
                  class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                  type="submit"
                >
                  <span>Save Changes</span>
                  <div>
                    <img
                      src="../../assets/arrow-right.svg"
                      alt=""
                      width="40"
                      height="40"
                      class="img-fluid"
                      v-if="!loading"
                    />
                    <div
                      class="spinner-grow text-white"
                      role="status"
                      v-else-if="loading"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </button>
              </p>
            </div>
          </form>
        </div>
      </template>
      <!-- <template #footer>
        <p>hello</p>
      </template> -->
    </Modal>

    <Modal :show="showSubmittedSuccessfully" width="650">
      <template #content>
        <div
          class="d-flex justify-content-center flex-column position-relative"
        >
          <img
            src="../../assets/close-circle.png"
            alt=""
            width="35"
            height="35"
            class="img-fluid position-absolute"
            style="top: 0; right: 0; cursor: pointer"
            @click.prevent="showSubmittedSuccessfully = false"
          />
          <div class="d-flex justify-content-center">
            <img
              src="../../assets/success.png"
              alt=""
              class="img-fluid"
              width="120"
            />
          </div>
          <p class="text-center mt-3 header-text2 mb-0">Password Updated</p>
          <p class="fw-light mt-1 text-center text-body-secondary">
            Your profile information have been updated successfully
          </p>
          <p
            class="text-center d-block d-md-flex justify-content-center mt-3 w-50 mx-auto"
          >
            <button
              class="btn updated btn-lg text-capitalize font-weight-bold w-100 ms-3 shadow-sm"
              @click="showSubmittedSuccessfully = false"
            >
              Back to Home
            </button>
          </p>
        </div>
      </template>
      <!-- <template #footer>
        <p>hello</p>
      </template> -->
    </Modal>
    <slot />
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import DashboardNav from "@/components/DashboardNav.vue";
import Modal from "@/components/Modal.vue";

import axios from "@/Plugins/axios";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";
import {useAuthStore} from "@/stores/auth"
// import { useEventBus } from "@/composables/event-bus";
// import { useMeetingChannelSubscription } from "@/composables/channel-subscription";

const store = useAuthStore();
const router = useRouter();
const showModal = ref(false);
const showUpdatePassword = ref(false);
const showSubmittedSuccessfully = ref(false);

const loading = ref(false);

const updateForm = ref({
  old: "",
  password: "",
  password_confirmation: "",
});

function handleSignOutEvent() {
  showModal.value = true;
}

function handleUpdatePassword() {
  showUpdatePassword.value = true;
}

function updatePassword() {
  loading.value = true;
  axios
    .post(`/change-password`, updateForm.value)
    .then((response) => {
      showUpdatePassword.value = false;
      showSubmittedSuccessfully.value = true;
      loading.value = false;
    })
    .catch((error) => {
      loading.value = false;
      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

function logOut(){
  router.push({name: 'UserLogin'});
  store.logout()
  .then(response => {
  })
  .catch(error => {
     catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
  })
}

// const { on } = useEventBus();
// on('showNotification', (data) => {
//   alert(data.message)
// })

// const { onJoinPrayerMeeting } = useMeetingChannelSubscription(5);
// onJoinPrayerMeeting((e)=> {
//   console.log(e);
// })
</script>
<style scoped>
.bg-transparent {
  background-color: transparent !important;
}
.signOutBtn {
  background-color: rgba(242, 76, 53, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.loggedInBtn2 {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 0.7rem 1.5rem;
  border-radius: 9px;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;
  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 800;
  font-size: 1.6rem;
}

.form-bg {
  background: rgba(245, 251, 252, 1) !important;
  color: #131313 !important;
}
</style>
