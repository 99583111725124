<template>
  <div>
    <section class="bg-signIn d-flex justify-content-center align-items-center">
      <div class="container">
        <div class="py-6 position-relative">
          <img
            src="../../assets/boy-singing.png"
            alt=""
            class="img-fluid boy-singing"
          />
          <img
            src="../../assets/prayingMan.png"
            alt=""
            class="img-fluid prayingMan"
          />
          <img
            src="../../assets/oldWoman.png"
            alt=""
            class="img-fluid oldWoman"
          />
          <img src="../../assets/oldMan.png" alt="" class="img-fluid oldMan" />
          <img
            src="../../assets/youngWoman.png"
            alt=""
            class="img-fluid youngWoman"
          />
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-lg-7">
              <div class="card p-3 shadow-sm">
                <form action="" class="border border-1 rounded-2 p-3" @submit.prevent="login">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <img
                        src="../../assets/logoP.jpg"
                        alt=""
                        class="img-fluid"
                      />
                      <h2 class="header-text mt-3">Welcome Back</h2>
                      <p class="mt-0">
                        Login to access your account and start exploring
                      </p>
                    </div>
                    <div class="col-lg-12">
                      <h6 class="form-label">Email Address/Phone Number</h6>
                      <input
                        type="text"
                        placeholder="Enter your email address or phone number"
                        class="form-control p-3"
                        required
                        v-model="logInForm.email"
                      />
                      <FormError :errorMessage="formErrors.email"/>
                    </div>
                    <div class="col-lg-12 mt-4">
                      <div
                        class="d-flex justify-content-between align-items-end"
                      >
                        <h6 class="form-label">Password</h6>
                        <router-link
                          :to="{name: 'ForgotPassword'}"
                          class="mb-0"
                          style="color: rgba(13, 32, 78, 1)"
                        >
                          Forgot Password
                        </router-link>
                      </div>

                      <div class="input-group">
                        <input
                          :type="showPassword ? 'text' : 'password'"
                          placeholder="********"
                          class="form-control p-3"
                          v-model="logInForm.password"
                          required
                        />
                        <span
                          class="input-group-text"
                          @click="togglePasswordVisibility"
                        >
                          <i
                            :class="
                              showPassword ? 'bi bi-eye' : 'bi bi-eye-slash' 
                            "
                          ></i>
                        </span>
                      </div>
                      <FormError :errorMessage="formErrors.password"/>

                    </div>
                    <div class="col-12 mt-5">
                      <p>
                        <button
                          class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                          
                        >
                          <span> Sign In</span>
                          <div>
                            <img
                              src="../../assets/arrow-right.svg"
                              alt=""
                              width="40"
                              height="40"
                              class="img-fluid"
                              v-if="!loading"
                            />
                            <div
                              class="spinner-grow text-white"
                              role="status"
                              v-else-if="loading"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </button>
                      </p>
                      <p>
                        <span style="color: rgba(170, 170, 170, 1)"
                          >Don't have an account?</span
                        ><router-link
                          :to="{name: 'UserSignUp'}"
                          class="text-decoration-none"
                          style="color: rgba(6, 17, 5, 1)"
                        >
                          Create One</router-link
                        >
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";
import FormError from "@/components/FormError.vue"
const authStore = useAuthStore();

const loading = ref(false);

const showPassword = ref(false);
const router = useRouter();

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

const logInForm = ref({
  email: "",
  password: "",
});

const formErrors = ref({})

async function login() {
  formErrors.value = {}
  loading.value = true;

  try {
    await authStore.login(logInForm.value);
    loading.value = false;
    utils.showToast("Success", "success");
    

  } catch (error) {
    catchAxiosExceptions(error);
    formErrors.value = catchAxiosExceptions(error).formErrors
    utils.showToast(catchAxiosExceptions(error).msg, "error");
    loading.value = false;
  }

  // setTimeout(() => {
  //   loading.value = false;

  // }, 3000);
}
</script>

<style scoped>
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.py-6 {
  padding: 6rem 0;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text {
  font-weight: 900;
  font-size: 2.3rem;
}

@media screen and (max-width: 768px) {
  .signInBtn {
    background-color: rgba(10, 64, 194, 1) !important;
    font-weight: 600 !important;
    border: none;
    color: #fff !important;
    font-size: 1rem !important;
    padding: 1rem 1.5rem;
    border-radius: 9px;
  }
  .boy-singing {
    display: none;
  }

  .youngWoman {
    display: none;
  }
  .oldMan {
    display: none;
  }

  .prayingMan {
    display: none;
  }
  .oldWoman {
    display: none;
  }
}

.boy-singing {
  position: absolute;
  top: 4%;
  left: 15%;
  z-index: 999;
}

.youngWoman {
  position: absolute;
  bottom: 9%;
  left: 0;
  z-index: 999;
}
.oldMan {
  position: absolute;
  bottom: 29%;
  right: 0;
  z-index: 999;
}
.prayingMan {
  position: absolute;
  top: 2%;
  right: 0;
  z-index: 999;
}

.oldWoman {
  position: absolute;
  bottom: -2.8%;
  left: 45%;
  z-index: 999;
}
</style>
