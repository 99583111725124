<template>
  <div class="modal-mask rounded-0 " v-show="show" transition="modal" >
    <div class="modal-wrapper ">
      <div class="modal-container p-0" :style="{ maxWidth: width + 'px' }">
        <!-- content -->
        <!-- <div class="modal-header ">
          <h6 class="text-primary p-0">{{ title }}</h6>
        </div> -->

        <!-- content -->
        <div class="modal-body m-0">
          <slot name="content" class=""></slot>
        </div>

        <!-- footer -->
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      twoWay: true,
    },

    title: {
      type: String,
      required: false,
    },
    width: {
      type: String,
      default: '550',
      required: false // Default width in pixels
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  border-radius: 15px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  overflow-y: scroll;

}

.modal-container {
   max-height: 95vh;
  max-width: v-bind(width)px;
  margin: 0px auto;
  padding: 0px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
 overflow: hidden;
  /* font-family: Helvetica, Arial, sans-serif; */
}

.modal-header h6 {
  margin-top: 0;
  text-align: center !important;
}

.modal-body {
  max-height: 90vh;
  margin: 0;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

/*
 * the following styles are auto-applied to elements with
 * v-transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter,
.modal-leave {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>


