<template>
  <div>
    <section class="bg-signIn d-flex justify-content-center align-items-center">
      <div class="container">
        <div class="py-6 position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-lg-5">
              <div class="card p-4 shadow-sm">
                <form action="" class="border border-1 rounded-2 p-4">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <img
                        src="../../assets/success.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-12 text-center">
                      <h2 class="header-text mt-3 mb-1 text-capitalize">
                       Prayer Point Submitted
                      </h2>
                      <p class="">
                        May God's blessing be always and forever!
                      </p>
                    </div>

                    <div class="col-12 mt-4">
                      <p
                        class="text-center d-block d-md-flex justify-content-center"
                      >
                        
                        <router-link
                        :to="{name: 'UserDashboard'}"
                          class="btn signInBtn2 btn-lg text-capitalize font-weight-bold ms-0 ms-md-4 shadow-sm"
                        >
                          Go to home
                        </router-link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup></script>

<style scoped>
/* *{
  outline: 1px solid red;
} */
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.py-6 {
  padding: 6rem 0;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.signInBtn2 {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text {
  font-weight: 900;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .signInBtn {
    background-color: rgba(10, 64, 194, 1) !important;
    font-weight: 600 !important;
    border: none;
    color: #fff !important;
    font-size: 1rem !important;
    padding: 1rem 1.5rem;
    border-radius: 9px;
  }
}
</style>
