<template>
  <div class="btn-group d-flex flex-wrap row">
    <div class="col-12">
      <button
        v-for="(option, index) in options"
        :key="index"
        @click="toggleSelection(option)"
        type="button"
        class="btn btn-outline-secondary m-2 shadow-sm rounded-3 btn-sm"
        :class="{ active: isSelected(option) }"
      >
        {{ formatTo12Hour(option.start_time) }} -
        {{ formatTo12Hour(option.end_time) }}
        <span v-if="isSelected(option)" class="ms-1"></span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      required: true,
    },
  },

  computed: {
    selectedOption: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  methods: {
    toggleSelection(option) {
      if (this.selectedOption.id === option.id) {
        this.selectedOption = {}; // Deselect the option
      } else {
        this.selectedOption = option; // Select the new option
      }
    },

    isSelected(option) {
      return this.selectedOption?.id === option.id;
    },

    formatTo12Hour(dateString) {
      // Create a Date object from the given string
      const date = new Date(dateString.replace(" ", "T"));

      // Extract hours and minutes
      let hours = date.getHours();
      const minutes = date.getMinutes();

      // Determine AM/PM
      const period = hours >= 12 ? "pm" : "am";

      // Convert to 12-hour format
      hours = hours % 12 || 12; // If 0, set to 12

      // Format minutes with leading zero if needed
      const formattedMinutes = minutes.toString().padStart(2, "0");

      // Return formatted time
      return `${hours}:${formattedMinutes}${period}`;
    },
  },
};
</script>

<style scoped>
.w-20 {
  width: 25% !important;
}

.btn-group button.active {
  background-color: rgba(10, 64, 194, 1);
  color: white;
  border: none;
  padding: 0.4rem 0.2rem;
  border-radius: 5px;
}

.btn-group button {
  background-color: rgba(240, 249, 255, 1);
  color: #131313;
  border: none;
  padding: 0.4rem 0.2rem;
  border-radius: 5px;
}
</style>
