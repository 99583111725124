<template>
  <div>
    <AdminLayout Title="Testimony" :isRequired="false">
      <TableSkeletonLoader class="pt-6" v-if="loading2" />

      <div class="container pt-6" v-else>
        <router-link
          :to="{ name: 'AdminTestimonies' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <div class="row mt-4 d-flex justify-content-center">
          <div class="col-12 col-lg-7">
            <div class="bg-card1 p-3 rounded-3">
              <div class="d-flex align-items-center">
                <img
                  src="../../assets/avatar.svg"
                  alt=""
                  class="img-fluid"
                  width="100"
                />
                <div class="ms-3">
                  <h5 class="mb-0">
                    {{ testimonyDetail?.conversation?.name ?? "No Name" }}
                  </h5>
                  <p class="text-body-secondary mt-0">example@gmail.com</p>
                </div>
              </div>
              <div class="d-flex mt-3 row g-2">
                <div class="col-12 col-lg-3 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary">Submission Date</small>
                    <p class="fw-semibold mt-3">
                      {{
                        getFormattedDateNumber(
                          testimonyDetail?.conversation?.created_at
                        ) ?? "No Date"
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-3 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary d-block mb-1"
                      >Status</small
                    >
                    <p class="pt-2">
                      <button class=" text-capitalize fw-semibold" :class="{'btn-pending': testimonyDetail?.status == 'pending', 'btn-active': testimonyDetail?.status !== 'pending'}">{{ testimonyDetail?.status }}</button>
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-3 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary">Date Accepted</small>
                    <p class="fw-semibold mt-3">24th Jan 2024</p>
                  </div>
                </div>
                <!-- <div class="col-12 col-lg-3 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary">Accepted By</small>
                    <p class="fw-semibold mt-3">Bro Tosin Alao</p>
                  </div>
                </div> -->
              </div>

              <div class="row mt-3">
                <div class="col-12">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary fw-semilight">{{
                      testimonyDetail?.testimony_category ?? "No Category"
                    }}</small>
                    <p class="fw-semilight mt-3" style="font-weight: 400">
                      {{ testimonyDetail?.content ?? "No Content" }}
                    </p>
                  </div>
                </div>
              </div>

              <p class="mt-4">
                <button
                  class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                  @click.prevent="showActions = true"
                >
                  <span>Provide Action</span>
                  <div>
                    <img
                      src="../../assets/arrow-right.svg"
                      alt=""
                      width="40"
                      height="40"
                      class="img-fluid"
                      v-if="!loading"
                    />
                    <div
                      class="spinner-grow text-white"
                      role="status"
                      v-else-if="loading"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal :show="showActions">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative pb-3"
          >
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="fw-semibold mt-3 mb-0">Provide Action</h4>
              <p class="fw-light mt-1 text-center text-body-secondary">
                <img
                  src="../../assets/close-circle.png"
                  alt=""
                  class="img-fluid"
                  @click="showActions = false"
                />
              </p>
            </div>

            <div
              class="card p-2 b-color d-flex justify-content-between flex-column mt-4 position-relative"
              style="cursor: pointer"
              @click.prevent="popApprovedTestimony"
            >
              <div class="d-flex align-items-center">
                <img
                  src="../../assets/admin-prayerreq2.png"
                  alt=""
                  class="img-fluid"
                  width="50"
                />
                <span class="fw-semilight ms-2 mt-1"
                  >Approve this Testimony</span
                >
              </div>
              <img
                src="../../assets/right-gray.png"
                alt=""
                class="img-fluid justify-self-end position-absolute"
                style="right: 1rem; top: 37%"
                width="9"
              />
              <!-- <div> -->

              <!-- </div> -->
            </div>
            <div
              class="card p-2 b-color bg-delete d-flex justify-content-between flex-column mt-4 position-relative"
              style="cursor: pointer"
              @click.prevent="rejectTestimony"
            >
              <div class="d-flex align-items-center">
                <img
                  src="../../assets/admin-bin.png"
                  alt=""
                  class="img-fluid"
                  width="50"
                />
                <span class="fw-semilight ms-2 mt-1" style="color: #d72509"
                  >Reject this Testimony</span
                >
              </div>
              <img
                src="../../assets/a-delete.png"
                alt=""
                class="img-fluid justify-self-end position-absolute"
                style="right: 1rem; top: 37%"
                width="9"
              />
              <!-- <div> -->

              <!-- </div> -->
            </div>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>

      <Modal :show="markedSuccessful">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="markedSuccessful = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-check-success.svg"
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">Testimony Approved</p>

            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <router-link
                :to="{ name: 'AdminTestimonies' }"
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4 text-decoration-none"
                @click.prevent="markedSuccessful = false"
              >
                Back to Home
              </router-link>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
      <Modal :show="rejectedSuccessfully">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="rejectedSuccessfully = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-check-success.svg"
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">Testimony Rejected</p>

            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <router-link
                :to="{ name: 'AdminTestimonies' }"
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4 text-decoration-none"
                @click.prevent="rejectedSuccessfully = false"
              >
                Back to Home
              </router-link>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import {
  getFormattedDate,
  getFormattedDateNumber,
  getFormattedAmount,
} from "@/Utils/handleResponse";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

const showActions = ref(false);
const rejectedSuccessfully = ref(false);
const markedSuccessful = ref(false);

const loading = ref(false);
const loading2 = ref(false);

const { id } = useRoute().params;

function popApprovedTestimony() {
  axios
    .post(`admin/testimony-status/${id}`, { status: 'accepted' })
    .then((response) => {
      markedSuccessful.value = true;
      showActions.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

function rejectTestimony() {
  axios
    .post(`admin/testimony-status/${id}`, { status: 'rejected' })
    .then((response) => {
      rejectedSuccessfully.value = true;
      showActions.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}



const testimonyDetail = ref({});

function getTestimonyDetail() {
  loading2.value = true;
  axios
    .get(`/testimonies/${id}`)
    .then((response) => {
      testimonyDetail.value = response.data.data;
      loading2.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading2.value = false;

    });
}

onMounted(() => {
  getTestimonyDetail();
});
</script>
<style scoped>
/* *{
    outline: 1px solid red;
} */
.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}
.pt-6 {
  padding-top: 6rem !important;
}
.bg-card1 {
  background-color: #e4f5ff !important;
}
.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}
.btn-pending {
  border: none !important;
  color: #f7c708 !important;
  background-color: #FFF9E2 !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.signOutBtn {
  background-color: #f24c35 !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.bg-delete {
  background-color: #fff4f4 !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}
</style>
