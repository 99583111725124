<template>
  <div>
    <AdminLayout Title="Devotionals" :isRequired="false">
      <div class="container pt-6">
        <router-link
          :to="{ name: 'AdminDevotionals' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <div class="row mt-4 d-flex justify-content-center">
          <div class="col-12 col-lg-7">
            <div
              class="p-4 position-relative home3 rounded-bottom-0 rounded-top-3"
              style="cursor: pointer"
            >
              <!-- <img
                src="../../assets/church2.png"
                class="position-absolute"
                style="right: 0.8rem; top: 0.6rem"
                alt=""
              /> -->
              <div class="text-white">
                <h5 class="card-title text-capitalize">Devotional</h5>
                <p class="card-text">
                  <img
                    src="../../assets/calendar-2.png"
                    alt=""
                    class="img-fluid"
                  />
                  Friday | 6:00 - 6:30 PM
                </p>
                <!-- <p class="card-text">
                  <img src="../../assets/location-minus.png" alt="" />

                  <span class="text-capitalize">Google Meet</span>
                </p> -->
              </div>
            </div>
            <div class="bg-white rounded-bottom-1 p-3 b-color">
              <form class="mt-3 border-0">
                <div class="mb-3">
                  <p class="form-label fw-medium">Topic</p>
                  <input
                    type="text"
                    class="form-control p-3"
                    placeholder="Enter topic here"
                  />
                </div>
                <div class="mb-3">
                  <p class="form-label fw-medium">Bible Text</p>
                  <input
                    type="text"
                    class="form-control p-3"
                    placeholder="Enter topic here"
                  />
                </div>
                <div class="mb-3">
                  <p class="form-label fw-medium">Message</p>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    class="form-control"
                    placeholder="Enter your message here"
                  ></textarea>
                </div>
                <div class="mb-3">
                  <p class="form-label fw-medium">Prayer Points</p>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    class="form-control"
                    placeholder="Enter your message here"
                  ></textarea>
                </div>
                <p class="mt-4">
                  <button
                    class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                    @click.prevent="postedSuccessfully = true"
                  >
                    <span>Post Devotionals</span>
                    <div>
                      <img
                        src="../../assets/arrow-right.svg"
                        alt=""
                        width="40"
                        height="40"
                        class="img-fluid"
                        v-if="!loading"
                      />
                      <div
                        class="spinner-grow text-white"
                        role="status"
                        v-else-if="loading"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </button>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal :show="postedSuccessfully">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="postedSuccessfully = false"
            />
            <div class="d-flex justify-content-center">
              <img src="../../assets/admin-check-success.svg" class="img-fluid" width="150"/>
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Devotional Posted Successfully
            </p>

            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <router-link
               :to="{name: 'AdminDevotionals'}"
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4 text-decoration-none"
                @click.prevent="postedSuccessfully = false"
              >
                Back to Home
              </router-link>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref } from "vue";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";

const loading = ref(false);
const postedSuccessfully = ref(false);
</script>
<style scoped>
input,
textarea {
  background-color: #f5fbfc !important;
}
.pt-6 {
  padding-top: 6rem !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.bg-card1 {
  background-color: #e4f5ff !important;
}
.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.2rem !important;
  border-radius: 5px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.home3 {
  background-color: rgba(224, 58, 111, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}
</style>
