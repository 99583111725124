import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/js/bootstrap.bundle.min";

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

// toast
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import { useAuthStore } from "./stores/auth";
import { createPinia } from 'pinia'
// import { firebaseApp } from '@/config/firebase';

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//    broadcaster: 'reverb',
//     key: process.env.VUE_APP_REVERB_APP_KEY,
//     wsHost: process.env.VUE_APP_REVERB_HOST,
//     // wsPort: process.env.VUE_APP_REVERB_PORT,
//     // wssPort: process.env.VUE_APP_REVERB_PORT,
//     forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
//     authEndpoint: process.env.VUE_APP_REVERB_AUTH_ENDPOINT,
//     auth: {
//         headers: {
//             Authorization: `Bearer ${localStorage.getItem('token')}`,
//             Accept: 'Application/json'
//         }
//     },
// });

const pinia = createPinia();
const app = createApp(App);

// app.config.globalProperties.$firebase = firebaseApp;

app.use(pinia);

const authStore = useAuthStore();
authStore.initAuthStore();

app.use(router)
app.use(ToastPlugin);
app.use(VueTelInput)
// app.use(VueApexCharts)
app.mount('#app')
