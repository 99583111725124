<template>
  <ContentLoader viewBox="0 0 1000 600">
    <!-- Top Bar -->
    <rect x="0" y="0" rx="4" ry="4" width="1000" height="50" />

    <!-- Sidebar -->
    <rect x="0" y="60" rx="4" ry="4" width="200" height="500" />

    <!-- First Card -->
    <rect x="220" y="60" rx="10" ry="10" width="220" height="150" />

    <!-- Second Card -->
    <rect x="460" y="60" rx="10" ry="10" width="220" height="150" />

    <!-- Third Card -->
    <rect x="700" y="60" rx="10" ry="10" width="220" height="150" />

    <!-- Chart -->
    <rect x="220" y="230" rx="10" ry="10" width="700" height="250" />

    <!-- Table Header -->
    <rect x="220" y="500" rx="4" ry="4" width="700" height="30" />

    <!-- Table Rows -->
    <rect x="220" y="540" rx="4" ry="4" width="700" height="30" />
    <rect x="220" y="580" rx="4" ry="4" width="700" height="30" />
  </ContentLoader>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  components: { ContentLoader }
};
</script>

<style scoped>
/* Add custom styles if needed */
</style>
