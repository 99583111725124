<template>
  <div>
    <UserLayout>
      <ContentLoading v-if="showLoader" />
      <section class="dashboardMain" v-else>
        <div class="container">
          <div class="py-6">
            <div class="row">
              <div class="col-12 col-lg-8">
                <h1 class="header-text mb-4 text-capitalize">
                  Good Morning {{ user }}
                </h1>

                <div class="card bg-tabs p-0 p-lg-3 b-color">
                  <ul class="nav nav-pills mb-3" role="tablist">
                    <li class="nav-item ms-lg-3" role="presentation">
                      <button
                        class="nav-link active rounded-pill"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        All
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link rounded-pill"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="false"
                      >
                        Prayer Meetings
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link rounded-pill"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="contact-tab-pane"
                        aria-selected="false"
                      >
                        Testimonies
                      </button>
                    </li>
                  </ul>

                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active p-3"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabindex="0"
                    >
                     
                      <div
                        class="card p-4 border-0"
                        v-if="
                          prayerMeetings.length <= 0
                        "
                      >
                        <p class="text-center text-capitalize fw-medium">
                          No Prayer Meetings
                        </p>
                      </div>
                      <div
                        class="card p-4 border-0"
                        v-if="
                           testimonies.length <= 0
                        "
                      >
                        <p class="text-center text-capitalize fw-medium">
                          No Testimonies
                        </p>
                      </div>
                      <div
                        v-for="(item, index) in prayerMeetings"
                        :key="index"
                        class="m-2 p-4 position-relative"
                         v-show="index < 4"
                        :class="[
                          'card',
                          customClasses[index % customClasses.length],
                        ]"
                        style="cursor: pointer"
                        @click.prevent="showMore(item)"
                      >
                   
                        <img
                          src="../../assets/church2.png"
                          class="position-absolute"
                          style="right: 0.8rem; top: 0.6rem"
                          alt=""
                        />
                        <div class="text-white">
                          <h5 class="card-title text-capitalize">
                            {{ item.title }}
                          </h5>
                          <p class="card-text">
                            <img
                              src="../../assets/calendar-2.png"
                              alt=""
                              class="img-fluid"
                            />
                            {{ item.recurring_start_time }} (WAT)
                          </p>
                          <p class="card-text">
                            <img src="../../assets/location-minus.png" alt="" />

                            <span class="text-capitalize">{{
                              item.meeting_location
                            }} </span>
                          </p>
                        </div>
                      </div>

                      <div
                        class="card border-0 bg-card2 p-4 my-3 shadow-sm"
                        v-for="(item, index) in testimonies"
                        :key="index"
                         v-show="index < 4"
                        :class="['card', bgCards[index % bgCards.length]]"
                      >
                    
                      <router-link :to="{name: 'UserTestimonyDetail', params: {id: item?.id}}" class="text-decoration-none text-dark">

                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img
                              :src="item?.user?.photo"
                              class="img-fluid me-2 mt-2"
                             
                               width="50"
                               height="50"
                              v-if="item?.user?.photo"
                              alt="Testimony Pictures"
                            />
                            <img
                              :src="require('@/assets/dummy-avatar-logo.svg')"
                              class="img-fluid me-2 "
                              width="40"
                              height="40"
                              v-else
                              alt="Testimony Pictures"
                            />
                            <div>
                              <h5
                                class="text-start font-weight-bold text-dark mb-0"
                              >
                                {{ item.user?.first_name }}
                                {{ item.user?.last_name }}
                              </h5>
                              <!-- <p class="text-body-secondary mt-0">
                                {{ item.user?.email }}
                              </p> -->
                            </div>
                          </div>


                          <div>{{item?.testimony_category ? item?.testimony_category : "No Category"}}</div>
                          <div> <img
                              src="../../assets/arrow-right-blue.png"
                              alt=""
                              class="img-fluid"
                            /></div>
                        </div>
                      </router-link>
                        <!-- <div class="py-3">
                          <p class="card-text text-start text-black">
                            {{ item.content }}
                          </p>
                        </div> -->
                      </div>
                    </div>
                    <div
                      class="tab-pane fade p-3"
                      id="profile-tab-pane"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      tabindex="0"
                    >
                      <div
                        class="card p-4 border-0"
                        v-if="prayerMeetings.length <= 0"
                      >
                        <p class="text-center text-capitalize fw-medium">
                          No Prayer Meetings
                        </p>
                      </div>
                      <div
                        v-for="(item, index) in prayerMeetings"
                        :key="index"
                        v-show="index < 4"
                        class="m-2 p-4 position-relative"
                        :class="[
                          'card',
                          customClasses[index % customClasses.length],
                        ]"
                        style="cursor: pointer"
                        @click.prevent="showMore(item)"
                      >
                        <img
                          src="../../assets/church2.png"
                          class="position-absolute"
                          style="right: 0.8rem; top: 0.6rem"
                          alt=""
                        />
                        <div class="text-white">
                          <h5 class="card-title text-capitalize">
                            {{ item.title }}
                          </h5>
                          <p class="card-text">
                            <img
                              src="../../assets/calendar-2.png"
                              alt=""
                              class="img-fluid"
                            />
                            {{ item.recurring_start_time }} (WAT)
                          </p>
                          <p class="card-text">
                            <img src="../../assets/location-minus.png" alt="" />

                            <span class="text-capitalize">{{
                              item.meeting_location
                            }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade p-3"
                      id="contact-tab-pane"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                      tabindex="0"
                    >
                      <div class="card bg-card1 p-3 shadow-sm border- my-3">
                        <router-link
                          :to="{ name: 'UserTestimonies' }"
                          class="text-decoration-none"
                        >
                          <div
                            class="d-flex justify-content-between align-items-center"
                          >
                            <span class="text-primary fw-semibold"
                              >Have a testimony to Share</span
                            >
                            <img
                              src="../../assets/arrow-right-blue.png"
                              alt=""
                              class="img-fluid"
                            />
                          </div>
                        </router-link>
                      </div>
                      <div
                        class="card p-4 border-0"
                        v-if="testimonies.length <= 0"
                      >
                        <p class="text-center text-capitalize fw-medium">
                          No Testimonies
                        </p>
                      </div>
                      <div
                        class="card border-0 bg-card2 p-4 my-3 shadow-sm"
                        v-for="(item, index) in testimonies"
                        v-show="index < 4"
                        :key="index"
                        :class="['card', bgCards[index % bgCards.length]]"
                      >
                      <router-link :to="{name: 'UserTestimonyDetail', params: {id: item?.id}}" class="text-decoration-none text-dark">

                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img
                              :src="item?.user?.photo"
                              class="img-fluid me-2 mt-2"
                             
                               width="50"
                               height="50"
                              v-if="item?.user?.photo"
                              alt="Testimony Pictures"
                            />
                            <img
                              :src="require('@/assets/dummy-avatar-logo.svg')"
                              class="img-fluid me-2 "
                              width="40"
                              height="40"
                              v-else
                              alt="Testimony Pictures"
                            />
                            <div>
                              <h5
                                class="text-start font-weight-bold text-dark mb-0"
                              >
                                {{ item.user?.first_name }}
                                {{ item.user?.last_name }}
                              </h5>
                              <!-- <p class="text-body-secondary mt-0">
                                {{ item.user?.email }}
                              </p> -->
                            </div>
                          </div>


                          <div>{{item?.testimony_category ? item?.testimony_category : "No Category"}}</div>
                          <div> <img
                              src="../../assets/arrow-right-blue.png"
                              alt=""
                              class="img-fluid"
                            /></div>
                        </div>
                      </router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-5">
                  <div class="row g-3">
                    <div
                      class="col-12 d-flex justify-content-between align-items-center"
                    >
                      <h3 class="header-text2">Trending Testimonies!</h3>
                      <router-link
                        :to="{ name: 'UserTestimonies' }"
                        class="text-decoration-none me-3"
                        >View All</router-link
                      >
                    </div>
                     <div
                        class="card p-4 border-0"
                        v-if="testimonies.length <= 0"
                      >
                        <p class="text-center text-capitalize fw-medium">
                          No Testimonies
                        </p>
                      </div>
                    <div
                      class="col-12 col-lg-6"
                      v-for="(item, index) in testimonies"
                      v-show="index < 2"
                      :key="index"
                    >
                    <router-link :to="{name: 'UserTestimonyDetail', params: {id: item?.id}}" class="text-decoration-none text-dark">
                       <div
                        class="card border-0 bg-card2 p-4  shadow-sm"
                        :class="['card', bgCards[index % bgCards.length]]"
                      >
                        <div class="d-flex">
                          <div>
                            <img
                              :src="item?.user?.photo"
                              class="img-fluid me-2 mt-2"
                             
                               width="50"
                               height="50"
                              v-if="item?.user?.photo"
                              alt="Testimony Pictures"
                            />
                            <img
                              :src="require('@/assets/dummy-avatar-logo.svg')"
                              class="img-fluid me-2 "
                              width="70"
                              height="70"
                              v-else
                              alt="Testimony Pictures"
                            />
                          </div>

                          <div>
                            <h5
                              class="text-start font-weight-bold text-dark mb-0 mt-3"
                            >
                              {{item.user ? item.user?.first_name : "Null" }}
                              {{item.user ? item.user?.last_name : "Null" }}
                            </h5>
                            <p class="text-body-secondary mt-0">
                              {{item.user ? item.user?.email : "Null" }}
                            </p>
                          </div>
                        </div>
                        <div class="py-3">
                          <p class="card-text text-start text-black">
                            {{ item.content ? item.content : "No Text"}}
                          </p>
                        </div>
                      </div>
                    </router-link>
                     
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div
                  class="col-12 d-flex justify-content-between align-items-center mt-6"
                >
                  <h5 class="">Recent Recordings</h5>
                  <router-link
                    :to="{ name: 'UserRecordings' }"
                    class="text-decoration-none me-3"
                    >View All</router-link
                  >
                </div>

                <div
                  class="card p-4 border-0 bg-card1"
                  v-if="recordData.length <= 0"
                >
                  <p class="text-center text-capitalize fw-medium">
                    No Recording
                  </p>
                </div>

                <div
                  class="card mt-0 b-color"
                  v-for="(data, index) in recordData"
                  :key="index"
                >
                  <div class="row g-0">
                    <div class="col-md-4">
                      <img
                        src="../../assets/woman-on-the-altar.png"
                        class="img-fluid w-100 h-100"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-8">
                      <div class="card-body p-2">
                        <h5
                          class="mt-2"
                          style="font-size: 1rem; font-weight: 600"
                        >
                          {{ data?.title }}
                        </h5>
                        <div class="d-flex">
                          <div class="d-flex align-items-center">
                            <img
                              src="../../assets/calendar-2b.svg"
                              alt=""
                              class="img-fluid mb-3 me-1"
                              width="20"
                              height="20"
                            />
                            <p>{{getFormattedDate(data?.created_at)}}</p>
                          </div>
                          <!-- <div class="d-flex align-items-center ms-3">
                            <img
                              src="../../assets/calendar-2b.svg"
                              alt=""
                              class="img-fluid mb-3 me-1"
                              width="20"
                              height="20"
                            />
                            <p>28th April</p>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ModalMeetings :show="showModal" width="600" class="p-0">
        <template #content>
          <div class="card home3 p-4 position-relative" style="cursor: pointer">
            <img
              src="../../assets/close-circle-white.png"
              class="position-absolute"
              style="right: 0.8rem; top: 0.6rem"
              alt=""
              @click="showModal = false"
            />
            <div class="text-white">
              <h5 class="card-title">{{ moreDetailsOnMeeting?.title }}</h5>
              <p class="card-text">
                <img
                  src="../../assets/calendar-2.png"
                  alt=""
                  class="img-fluid"
                />{{ moreDetailsOnMeeting?.recurring_start_time }}(WAT)
              </p>
              <p class="card-text">
                <img src="../../assets/location-minus.png" alt="" />

                <span>{{ moreDetailsOnMeeting?.meeting_location }}</span>
              </p>
            </div>
          </div>

        

          <p class="text-body-secondary mb-1 mt-1 p-4">
         {{moreDetailsOnMeeting?.description}}
          </p>

          <div class="mt-5 position-relative p-4">
            <p class="position-relative">
              <button
               @click.prevent="navigateToZoom(moreDetailsOnMeeting?.channel)"
              
                class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
              >
                <span>Join Meeting</span>
                <img
                  src="../../assets/arrow-right.svg"
                  alt=""
                  width="40"
                  height="40"
                  class="img-fluid"
                />
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </ModalMeetings>
    </UserLayout>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

import {useRouter} from "vue-router";

import axios from "@/Plugins/axios";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";
import ModalMeetings from "@/components/ModalMeetings.vue";
import UserLayout from "@/components/Layouts/UserLayout.vue";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";
import ContentLoading from "@/components/ContentLoading.vue";

import { useAuthStore } from "@/stores/auth";

const store = useAuthStore();

const router = useRouter();

const user = store.user?.first_name;

const showModal = ref(false);
const showLoader = ref(false);

const recordData = ref([]);

const prayerMeetings = ref([]);
const testimonies = ref([]);

const customClasses = ref(["home", "home2", "home3", "home4"]);
const bgCards = ref([
  "bg-card2",
  "bg-card3",
  "bg-card4",
  "bg-card5",
  "bg-card6",
]);

const moreDetailsOnMeeting = ref({});

async function initDashboard() {
  showLoader.value = true;
  try {
    const _recordData = await axios.get(`/recordings`);
    const _prayer_meetings = await axios.get(`/prayer-meetings`);
    const _testimonies = await axios.get(`/testimonies/?is_personal=false`);
    prayerMeetings.value = _prayer_meetings.data.data;
    testimonies.value = _testimonies.data.data.filter(item => item.status !== "pending");

    recordData.value = _recordData.data.data;
    showLoader.value = false;
    // console.log(recordData.value);
  } catch (error) {
    showLoader.value = false;

    catchAxiosExceptions(error);
    utils.showToast(catchAxiosExceptions(error).msg, "error");
  }
}

function navigateToZoom(channel) {
   router.push({name: 'AdminPrayerMeetingDetails', query: {meeting_channel: channel}});
}

function showMore(details) {
  showModal.value = true;
 moreDetailsOnMeeting.value = details
}

onMounted(() => {
  initDashboard();
});
</script>

<style scoped>
/* * {
  outline: 1px solid red;
} */

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  /* border-radius: 9px; */
}


/* Extra small devices (phones, 480px and up) */
@media only screen and (max-width: 768px) { 
  /* *{
    outline: 1px solid red;
  } */

  .nav{
    display: flex !important;
    align-items:center !important;
    justify-content: center !important;
    width: 100% !important;
  }

  .nav-item{
    width: 100% !important;

  }
 
   .nav-link {
  color: #131313;
  width: 100% !important;
  padding: 0.8rem 5rem !important;
  margin: 0.7rem !important;
  background-color: rgba(230, 243, 252, 1);
}
.nav-link.active {
  color: #fff;
  background-color: rgba(10, 64, 194, 1);
  padding: 0.8rem 5rem !important;
  margin: 0.7rem !important;

}
 }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) { 

 }

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {  }

/* Large devices (desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .nav-link {
    color: #131313;
    padding: 0.8rem 4.4rem !important;
    margin: 0 0.6rem !important;
    background-color: rgba(230, 243, 252, 1);
  }
  .nav-link.active {
    color: #fff;
    background-color: rgba(10, 64, 194, 1);
    padding: 0.8rem 4.4rem !important;
  }

 }

.dashboardMain {
  background-color: #fffefe;
}

.container-padding {
  padding: 15rem 0;
}

.header-text {
  font-weight: 900;
  font-size: 2rem;
}
.header-text2 {
  font-weight: 800;
  font-size: 1.6rem;
}

.pt-6 {
  padding-top: 4rem;
}
.mt-6 {
  margin-top: 2rem;
}

.py-6 {
  padding: 8rem 0;
}

.bg-tabs {
  background-color: rgba(248, 252, 255, 1);
}

.subheading1 {
  color: rgba(255, 255, 255, 1);
}
.subheading2 {
  color: rgba(6, 17, 5, 1);
}

.home {
  background-color: rgba(0, 21, 63, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.home2 {
  background-color: rgba(0, 55, 187, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.home3 {
  background-color: rgba(224, 58, 111, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.home4 {
  background-color: rgba(0, 79, 36, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.bg-card1 {
  background: rgba(229, 244, 254, 1);
}
.bg-card2 {
  background: rgba(255, 249, 227, 1);
}
.bg-card3 {
  background: rgba(255, 236, 231, 1);
}
.bg-card4 {
  background: rgba(222, 243, 209, 1);
}
.bg-card5 {
  background: rgba(242, 228, 245, 1);
}
.bg-card6 {
  background: rgba(215, 244, 227, 1);
}
</style>
