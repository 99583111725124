<template>
  <div>
    <section class="bg-signIn d-flex justify-content-center align-items-center">
      <div class="container">
        <div class="py-6 position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-lg-7">
              <div class="card p-3 shadow-sm">
                <form action="" class="border border-1 rounded-2 p-3">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <img
                        src="../../assets/logoP.jpg"
                        alt=""
                        class="img-fluid"
                      />
                      <h2 class="header-text mt-3">Create Account</h2>
                      <p class="mt-0">
                        Create your account and start exploring
                      </p>
                    </div>
                    <div class="col-12 col-md-6">
                      <h6 class="form-label">First Name</h6>
                      <input
                        type="text"
                        placeholder="Enter your first name"
                        class="form-control p-3"
                        v-model="signUpForm.first_name"
                        required
                      />
                      <FormError :errorMessage="formErrors.first_name"/>

                    </div>
                    <div class="col-12 col-md-6">
                      <h6 class="form-label">Last Name</h6>
                      <input
                        type="text"
                        placeholder="Enter your last name"
                        class="form-control p-3"
                        v-model="signUpForm.last_name"
                        required
                      />
                      <FormError :errorMessage="formErrors.last_name"/>

                    </div>
                    <div class="col-lg-12 my-4">
                      <h6 class="form-label">Email Address</h6>
                      <input
                        type="email"
                        placeholder="Enter your email address"
                        class="form-control p-3"
                        v-model="signUpForm.email"
                        required
                      />
                      <FormError :errorMessage="formErrors.email"/>

                    </div>
                    <div class="col-lg-12 mb-3">
                      <h6 class="form-label">Phone Number</h6>
                     

                      <vue-tel-input v-model="signUpForm.phone" class="p-2 rounded-3"
                        v-bind="bindProps"></vue-tel-input>
                      <FormError :errorMessage="formErrors.phone"/>

                    </div>
                    <div class="col-lg-12 mb-3">
                      <h6 class="form-label">Birthday</h6>
                      <input
                        type="date"
                        placeholder="Enter your birthday"
                        class="form-control p-3"
                        v-model="signUpForm.birthday"
                        required
                      />
                      <FormError :errorMessage="formErrors.birthday"/>

                    </div>
                    <div class="col-lg-12 mt-4">
                      <div
                        class="d-flex justify-content-between align-items-end"
                      >
                        <h6 class="form-label">House Address</h6>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter your current house address"
                        class="form-control p-3"
                        v-model="signUpForm.address"
                      />
                      <FormError :errorMessage="formErrors.address"/>

                    </div>
                    <div class="col-lg-6 col-12 mt-4">
                      <h6 class="form-label">Password</h6>

                      <div class="input-group">
                        <input
                          :type="showPassword ? 'text' : 'password'"
                          placeholder="********"
                          class="form-control p-3"
                          v-model="signUpForm.password"
                          required
                        />
                        <span
                          class="input-group-text"
                          @click="togglePasswordVisibility"
                        >
                          <i
                            :class="
                              showPassword ? 'bi bi-eye': 'bi bi-eye-slash'
                            "
                          ></i>
                        </span>
                      </div>
                      <FormError :errorMessage="formErrors.password"/>

                    </div>
                    <div class="col-lg-6 col-12 mt-4">
                      <h6 class="form-label"> Confirm Password</h6>

                      <div class="input-group">
                        <input
                          :type="showPassword2 ? 'text' : 'password'"
                          placeholder="********"
                          class="form-control p-3"
                          v-model="signUpForm.password_confirmation"
                          required
                        />
                        <span
                          class="input-group-text"
                          @click="togglePasswordVisibility2"
                        >
                          <i
                            :class="
                              showPassword2 ?  'bi bi-eye': 'bi bi-eye-slash'
                            "
                          ></i>
                        </span>
                      </div>
                      <FormError :errorMessage="formErrors.password_confirmation"/>

                    </div>
                   
                    <div class="col-12 mt-5">
                      <p>
                        <button
                          class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                          @click.prevent="signUp"
                        >
                          <span>Proceed</span>
                          <div>
                            <img
                              src="../../assets/arrow-right.svg"
                              alt=""
                              width="40"
                              height="40"
                              class="img-fluid"
                              v-if="!loading"
                            />
                            <div
                              class="spinner-grow text-white"
                              role="status"
                              v-else-if="loading"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </button>
                      </p>
                      <p>
                        <span style="color: rgba(170, 170, 170, 1)"
                          >Already have an account?</span
                        ><router-link
                          :to="{name: 'UserLogin'}"
                          class="text-decoration-none"
                          style="color: rgba(6, 17, 5, 1)"
                        >
                          Sign In</router-link
                        >
                      </p>
                      <!-- <VueTelInput
                        v-model="phone"
                        class="p-2"
                        v-bind="bindProps"
                      /> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue'
import {useRoute, useRouter} from "vue-router"
// import VueTelInput from 'vue-tel-input'

import { useAuthStore } from "@/stores/auth";
import {catchAxiosExceptions} from '@/Plugins/handleExceptions'
import utils from "@/Plugins/utils";
import FormError from "@/components/FormError.vue"

const authStore = useAuthStore();
// useApiFetch();
const formErrors = ref({})

const showPassword = ref(false);
const showPassword2 = ref(false);

const router = useRouter();

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};
const togglePasswordVisibility2 = () => {
  showPassword2.value = !showPassword2.value;
};

// watch(signUpForm.value, (newVal, oldVal) => {
//   if(newVal.password !== newVal.password_confirmation ){
//     utils.showToast("Password doesnt Match", "error");
//   }
// })

const loading = ref(false);

const signUpForm = ref({
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  password_confirmation: "",
  birthday: "",
  address: "",
  phone: "",
});

const bindProps = ref({
  // mode: "international",
  defaultCountry: "NG",
  disabledFetchingCountry: false,
  disabled: false,
  // disabledFormatting: false,
  placeholder: "Enter your Mobile number",
  required: true,
  enabledCountryCode: false,
  enabledFlags: true,
  validCharactersOnly: true,
  // preferredCountries: ["AU", "BR"],
  onlyCountries: [],
  ignoredCountries: [],
  autocomplete: "on",
  // name: "telephone",
  maxLen: 25,
  wrapperClasses: "",
  inputClasses: "",
  // dropdownOptions: {
  //   disabledDialCode: false,
  // },
  inputOptions: {
    showDialCode: true,
  },
});


async function signUp(){
  formErrors.value = {}

  loading.value = true;
  if (signUpForm.value.password != signUpForm.value.password_confirmation){
    utils.showToast("Password doesnt Match", "error");
    loading.value = false;
    return;
  }
    try {
    await authStore.signup(signUpForm.value);
    loading.value = false;
    utils.showToast("Success", "success");
    router.push({name: 'WelcomeAfterSignUp'});
    
    // console.log('Signup successful:', response)

  } catch (error) {
    loading.value = false;
    catchAxiosExceptions(error);
    formErrors.value = catchAxiosExceptions(error).formErrors

    utils.showToast(catchAxiosExceptions(error).msg, "error");
    // console.error('Signup failed:', error)
    
  }
} 
</script>

<style scoped>
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.py-6 {
  padding: 6rem 0;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text {
  font-weight: 900;
  font-size: 2.3rem;
}

@media screen and (max-width: 767px) {
  .signInBtn {
    background-color: rgba(10, 64, 194, 1) !important;
    font-weight: 600 !important;
    border: none;
    color: #fff !important;
    font-size: 1rem !important;
    padding: 1rem 1.5rem;
    border-radius: 9px;
  }
  .boy-singing {
    display: none;
  }

  .youngWoman {
    display: none;
  }
  .oldMan {
    display: none;
  }

  .prayingMan {
    display: none;
  }
  .oldWoman {
    display: none;
  }
}
</style>
