<template>
  <div>
    <footer>
      <div class="container">
        <div class="py-5">
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="d-flex align-items-center">
                <img src="../assets/logoP.jpg" alt="" class="img-fluid" />
                <h4 class="ms-3 fw-bold">Prophetic Prayer</h4>
              </div>
              <p class="subheading2 mt-2">
                Stay connected to what God is doing through<br class="d-none d-lg-block" /> Prophetic Prayer
                Depot. Download our app today <br class="d-none d-lg-block" />and never miss a moment of
                breakthrough!
              </p>
              <p class="mt-4">
                <img
                  src="../assets/playStore-dark.png"
                  alt=""
                  class="img-fluid"
                />
                <img
                  src="../assets/appStore-dark.png"
                  alt=""
                  class="img-fluid ms-2"
                />
              </p>
            </div>

            <div class="col-12 col-lg-2">
              <h4 class="fw-bold">Pages</h4>
              <ul class="list-unstyled mt-4">
                <li class="my-3">
                  <router-link to="#"  class="text-decoration-none footer-links">Who We Are</router-link>
                </li>
                <li class="my-3">
                  <router-link to="#"  class="text-decoration-none footer-links">Testimonies</router-link>
                </li>
             
                <li  class="my-3">
                  <router-link to="#" class="text-decoration-none footer-links">Upcoming Events</router-link>
                </li>
                <li  class="my-3">
                  <router-link :to="{name: 'PrivacyPolicy'}" class="text-decoration-none footer-links">Privacy Policy</router-link>
                </li>
              </ul>
            </div>
            <div class="col-12 col-lg-4">
              <h4 class="fw-bold">Contacts</h4>
              <ul class="list-unstyled mt-3">
                <li class="my-3 d-flex">
                  <img src="../assets/phone.png" alt="" class="img-fluid">
                  <router-link to="#"  class="text-decoration-none footer-links ms-2">(406) 555-0120</router-link>
                </li>
                <li class="my-3 d-flex">
                  <img src="../assets/email.png" alt="" class="img-fluid">
                  <router-link to="#"  class="text-decoration-none footer-links ms-2">support@propheticprayerdepot.com</router-link>
                </li>
                <li class="my-3 d-flex">
                  <img src="../assets/location.svg" alt="" class="img-fluid" width="25" height="5">
                  <router-link to="#"  class="text-decoration-none footer-links ms-2">2972 Westheimer Rd. Santa Ana,<br class="d-none d-lg-block" /> Illinois 85486 </router-link>
                </li>
               
              </ul>
            </div>
            <div class="col-12 col-lg-2">
              <h4 class="fw-bold">Social Media</h4>
              <ul class="list-unstyled d-flex mt-3">
                <li class="me-2">
                  <img src="../assets/facebook.svg" alt="" class="img-fluid">
                </li>
                <li class="me-2">
                  <img src="../assets/twitter.svg" alt="" class="img-fluid">
                </li>
                <li class="me-2">
                  <img src="../assets/youtube.png" alt="" class="img-fluid">
                </li>
                <li  class="">
                  <img src="../assets/instagram.svg" alt="" class="img-fluid">
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup></script>

<style scoped>

/* *{
  outline: 1px solid red;
} */
.subheading2 {
  color: rgba(6, 17, 5, 1);
}

.footer-links{
  color:#131313 !important;
}
</style>
