<template>
  <div>
    <AdminLayout Title="Prayer Meetings" :isRequired="false">
      <div class="container">
        <div class="row d-flex justify-content-end pt-6 mb-3">
          <div class="col-12 d-flex justify-content-end">
            <router-link
              :to="{ name: 'AdminAddPrayerMeetings' }"
              class="loggedInBtn2 shadow-sm text-decoration-none"
              >Add New Meeting</router-link
            >
          </div>
        </div>
        <TableSkeletonLoader class="mt-3" v-if="loading" />

        <div class="row" v-else>
          <div class="col-12 col-lg-4">
            <div class="row bg-white py-3 px-1 rounded-2 b-color">
              <div class="col-12">
                <div
                  class="card p-2 border-0 bg-card1 d-flex justify-content-between flex-column"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p class="text-body-secondary">All Meetings</p>
                    <img
                      src="../../assets/admin-all-meet.png"
                      class="img-fluid d-flex align-self-start"
                      width="50"
                    />
                  </div>
                  <div class="d-flex">
                    <h5 class="mt-3 fw-semibold">{{ meetings.length }}</h5>
                  </div>
                </div>
                <div
                  class="card p-2 border-0 bg-card2 mt-3 d-flex justify-content-between flex-column"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p class="text-body-secondary">Upcoming Meetings</p>
                    <img
                      src="../../assets/admin-upcoming-meet.png"
                      class="img-fluid d-flex align-self-start"
                      width="50"
                    />
                  </div>
                  <div class="d-flex">
                    <h5 class="mt-3 fw-semibold">0</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div class="bg-white p-2 rounded-2 b-color">
              <div class="row d-flex align-items-center">
                <div class="col-12">
                  <input
                    type="text"
                    placeholder="Enter to search"
                    class="form-control p-2 ms-0"
                  />
                </div>
                <!-- <div class="col-12 col-lg-6 d-flex justify-content-end">
                  <div class="d-flex p-1 mb-1">
                    <button class="m-1 btn-accent1 p-1">All</button>
                    <button class="m-1 btn-accent2 p-1" href="#">Admin</button>
                    <button class="m-1 btn-accent2 p-1" href="#">
                      Reviewer
                    </button>
                    <button class="m-1 btn-accent2 p-1" href="#">
                      Treasurer
                    </button>
                  </div>
                </div> -->
              </div>
            </div>

            <div class="card p-2 b-color mt-3">
              <div v-if="meetings.length == 0">No Prayer Meetings</div>
              <div
                class="m-1 p-3 position-relative home rounded-3 text-white"
                v-for="(meeting, index) in meetings"
                :key="meeting?.id"
                :class="['card', customClasses[index % customClasses.length]]"
                style="cursor: pointer"
              >
                <router-link
                  :to="{
                    name: 'AdminPrayerMeetingDetails',
                    query: {
                      meeting_channel: meeting?.channel,
                      id: meeting?.id,
                    },
                  }"
                  class="text-decoration-none text-white"
                >
                  <img
                    src="../../assets/church2.png"
                    class="position-absolute img-fluid"
                    style="right: 0.8rem; top: 0.9rem"
                    alt=""
                    width="80"
                    height="80"
                  />
                  <div class="text-white">
                    <h5 class="card-title text-capitalize">
                      {{ meeting?.title ?? "No Title" }}
                    </h5>
                    <p class="card-text d-flex align-items-center">
                      <img
                        src="../../assets/calendar-2.png"
                        alt=""
                        class="img-fluid me-2"
                      />
                      <span>
                        {{ meeting?.recurring_start_time ?? "No Time" }}
                      </span>
                    </p>
                    <p class="card-text">
                      <img src="../../assets/location-minus.png" alt="" />

                      <span class="text-capitalize">{{
                        meeting?.meeting_location ?? "No Location"
                      }}</span>
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
            <nav aria-label="Page navigation" v-if="links.length != 0">
              <ul class="pagination justify-content-end">
                <!-- Previous button -->
                <li
                  class="page-item"
                  :class="{ disabled: !links[0]?.url }"
                  @click="changePage(currentPage - 1)"
                >
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>

                <!-- Page links -->
                <li
                  class="page-item"
                  v-for="link in links.slice(1, -1)"
                  :key="link.label"
                  :class="{ active: link?.active }"
                  @click="changePageFromUrl(link?.url)"
                >
                  <a class="page-link" href="#">{{ link?.label }}</a>
                </li>

                <!-- Next button -->
                <li
                  class="page-item"
                  :class="{ disabled: !links[links.length - 1]?.url }"
                  @click="changePage(currentPage + 1)"
                >
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";
import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

const customClasses = ref(["home", "home2", "home3", "home4"]);
const loading = ref(false);

const meetings = ref([]);
const currentPage = ref(1);
const totalPages = ref(0);
const paginator = ref({});
const links = ref([]);

const changePageFromUrl = (url) => {
  if (url) {
    const urlParams = new URL(url).searchParams;
    const page = urlParams.get("page");
    getMeetings(parseInt(page, 10));
  }
};

const changePage = (page) => {
  if (page > 0 && page <= totalPages.value) {
    getMeetings(page);
  }
};

function getMeetings(page = 1) {
  loading.value = true;
  axios
    .get(`/prayer-meetings?page=${page}`)
    .then((response) => {
      meetings.value = response.data.data;

      paginator.value = response.data.meta;
      links.value = paginator.value.links;
      // gettingNextPage.value = false;
      currentPage.value = paginator.value.current_page;
      totalPages.value = paginator.value.total;
      loading.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading.value = false;
    });
}
onMounted(() => {
  getMeetings(currentPage.value);
});
</script>
<style scoped>
/* *{
  outline: 1px solid red;
} */

.btn-accent1 {
  background-color: rgba(234, 241, 255, 1);
  color: rgba(24, 41, 101, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}
.btn-accent2 {
  background-color: rgba(246, 246, 246, 1);
  color: rgba(96, 96, 96, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}

.loggedInBtn2 {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.filter {
  border: 1px solid rgba(218, 229, 239, 1);
  backdrop-filter: blur(20px);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.02);
  border-radius: 9px;
}
.pt-6 {
  padding-top: 6rem !important;
}

.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}

.bg-white {
  background-color: #fff;
}

.bg-card1 {
  background-color: #e4f5ff !important;
}
.bg-card2 {
  background-color: #e1ffea !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.Fi {
  background-color: #fcf3ff !important;
}

.home {
  background-color: rgba(0, 21, 63, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.home2 {
  background-color: rgba(0, 55, 187, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.home3 {
  background-color: rgba(224, 58, 111, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.home4 {
  background-color: rgba(0, 79, 36, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
