<template>
  <div>
    <UserLayout>
      <TableSkeletonLoader class="pt-6" v-if="loading2" />

      <section class="bg-signIn d-flex" v-else>
        <div class="container">
          <div class="py-6">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-lg-11">
                <div
                  class="d-lg-flex justify-content-between align-items-end mt-5"
                >
                  <p class="text-end d-lg-none">
                    <button
                      class="updated btn shadow-sm"
                      @click.prevent="showAddTestimony = true"
                    >
                      Add New Testimony
                    </button>
                  </p>
                  <h2 class="header-text text-dark">All Testimonies</h2>
                  <button
                    class="updated btn shadow-sm d-none d-lg-flex"
                    @click.prevent="showAddTestimony = true"
                  >
                    Add New Testimony
                  </button>
                </div>

                <div
                  class="b-color p-3 mt-4 card"
                  v-if="testimonies.length == 0"
                >
                  <p style="font-size: 1.3rem">No Testimonies or Testimony is Pending⏳</p>
                </div>
                <div class="b-color p-2 mt-4 card" v-else>
                  <div
                    class="card border-0 bg-card2 p-4 m-2 shadow-sm"
                    v-for="(item, index) in testimonies"
                    :key="index"
                    :class="['card', bgCards[index % bgCards.length]]"
                  >
                  <router-link :to="{name: 'UserTestimonyDetail', params: {id: item?.id}}" class="text-decoration-none text-dark">

                    <div class="d-flex">
                      <div>
                        <img
                          :src="
                            item?.file_path
                              ? item?.file_path
                              : require('@/assets/dummy-avatar-logo.svg')
                          "
                          alt=""
                          class="img-fluid rounded-circle mt-2 me-2"
                          width="50"
                          height="50"
                        />
                      </div>

                      <div>
                        <h5
                          class="text-start font-weight-bold text-dark mb-0 mt-3"
                        >
                          {{ item.user?.first_name }} {{ item.user?.last_name }}
                        </h5>
                        <p class="text-body-secondary mt-0">
                          {{ item.user?.email }}
                        </p>
                      </div>
                    </div>
                    <div class="py-3">
                      <p class="card-text text-start text-black">
                        {{ item.content }}
                      </p>
                    </div>
                  </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal :show="showAddTestimony" width="600">
        <template #content>
          <img
            src="../../assets/close-circle.png"
            alt=""
            class="img-fluid position-absolute"
            style="top: 2%; right: 2%; cursor: pointer"
            @click="showAddTestimony = false"
          />
          <form
            action=""
            class="border-1 rounded-2 p-lg-4 p-2"
            style="overflow-y: scroll"
            @submit.prevent="postTestimony"
          >
            <div class="row">
              <div class="col-12">
                <h3 class="header-text2 mt-3">Add New Video Testimony</h3>
              </div>

              <div class="dropzone-container">
                <p class="form-label mt-3">Upload Audio/Video Image</p>
                <label
                  for="fileInput"
                  class="dropzone bg-dropbox w-100"
                  @dragover.prevent="dragOver"
                  @dragleave="dragLeave"
                  @drop.prevent="handleDrop"
                >
                  <img
                    src="../../assets/cloud-upload-fill.png"
                    class="img-fluid"
                  />
                  <p>Click to upload Testimonies here</p>
                  <span>mp3,mp4,jpeg. 5Mb Max</span>
                  <input
                    type="file"
                    id="fileInput"
                    @change="handleFiles"
                    class="d-none"
                    accept="audio/*, video/*, image/*"
                  />
                </label>
              </div>

              <div
                v-if="testimonyPayload.testimony_file"
                class="d-flex align-items-center"
              >
                <p class="mt-3 me-2">
                  Selected File:
                  <span class="fw-bold">{{
                    testimonyPayload.testimony_file.name
                  }}</span>
                </p>
                <button class="btn btn-sm ml-2" @click="removeFile()">
                  <img src="../../assets/close-circle.png" alt="" />
                </button>
              </div>

              <div class="col-12 mt-4 mb-3">
                <p class="form-label">Testimony Description</p>
                <textarea
                  placeholder="You can also describe your testimony in words here"
                  class="form-control p-3"
                  v-model="testimonyPayload.content"
                />
              </div>

              <div class="col-12 mt-5">
                <p>
                  <button
                    class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                    type="submit"
                  >
                    <span>Submit Testimony</span>
                    <div>
                      <img
                        src="../../assets/arrow-right.svg"
                        alt=""
                        width="40"
                        height="40"
                        class="img-fluid"
                        v-if="!loading"
                      />
                      <div
                        class="spinner-grow text-white"
                        role="status"
                        v-else-if="loading"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </button>
                </p>
              </div>
            </div>
          </form>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>

      <Modal :show="showSubmitAnonymously">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click="showSubmitAnonymously = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/submittedAnonymously.png"
                alt=""
                class="img-fluid"
                width="200"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">Submit Anonymously</p>
            <p class="fw-light mt-1 text-center text-body-secondary">
              Would you like to let people know this testimony is from you
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <button
                class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100"
                @click.prevent="showProfileUpdated = false"
              >
                Let People Know It's Me
              </button>
              <button
                class="btn updated btn-lg text-capitalize font-weight-bold w-100 ms-3"
                @click.prevent="successFormSubmission"
              >
                Hide Who I Am
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>
      <Modal :show="showSubmittedSuccessfully">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/success.png"
                alt=""
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Testimony Submitted
            </p>
            <p class="fw-light mt-1 text-center text-body-secondary">
              Your testimony has been successfully submitted
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3 w-50 mx-auto"
            >
              <button
                class="btn updated btn-lg text-capitalize font-weight-bold w-100 ms-3"
                @click="showSubmittedSuccessfully = false"
              >
                Close
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>
    </UserLayout>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import UserLayout from "@/components/Layouts/UserLayout.vue";
import Modal from "@/components/Modal.vue";

import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import axios from "@/Plugins/axios";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";

const showAddTestimony = ref(false);
const showSubmitAnonymously = ref(false);
const showSubmittedSuccessfully = ref(false);

const loading = ref(false);
const loading2 = ref(false);

const testimonyPayload = ref({
  content: null,
  testimony_file: null,
});

const bgCards = ref([
  "bg-card2",
  "bg-card3",
  "bg-card4",
  "bg-card5",
  "bg-card6",
]);
const testimonies = ref([]);

// Reactive array to store files
const uploadedFiles = ref([]);

watch(uploadedFiles, (newVal, oldVal) => {
  uploadedFiles.value = newVal;
});
// Handle files selected via input
const handleFiles = (event) => {
  const files = event.target.files;
  if (files.length > 0) {
    testimonyPayload.value.testimony_file = files[0];
  }
};

// Handle files dropped into the dropzone
const handleDrop = (event) => {
  const files = event.dataTransfer.files;

  if (files.length > 0) {
    testimonyPayload.value.testimony_file = files[0];
  }
};

const removeFile = () => {
  testimonyPayload.value.testimony_file = null; // Remove the file from the model
  const fileInput = document.getElementById("fileInput");
  fileInput.value = ""; // Reset the file input field
};
// function removeFile(index) {
//   uploadedFiles.value.splice(index, 1);
// }

function postTestimony() {
  loading.value = true;
  axios
    .post(`/testimonies`, testimonyPayload.value, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      loading.value = false;

      showSubmittedSuccessfully.value = true;
      showAddTestimony.value = false;

      testimonyPayload.value.content = null;
      testimonyPayload.value.testimony_file = null;
      getAllTestimonies();
    })
    .catch((error) => {
      loading.value = false;
      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

function getAllTestimonies() {
  loading2.value = true;
  axios
    .get(`testimonies/?is_personal=false`)
    .then((response) => {
      testimonies.value = response.data.data.filter(
        (item) => item.status !== "pending"
      );
      loading2.value = false;
    })
    .catch((error) => {
      loading2.value = false;

      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

onMounted(() => {
  getAllTestimonies();
});
</script>

<style scoped>
/* * {
  outline: 1px solid red;
} */

.dropzone-container {
  position: relative;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.bg-dropbox {
  background-color: rgba(245, 251, 252, 1);
}
.header-text {
  font-weight: 900;
  font-size: 2.3rem;
}
.header-text2 {
  font-weight: 900;
  font-size: 1.8rem;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.py-6 {
  padding: 6rem 0;
}

.bg-card2 {
  background: rgba(255, 249, 227, 1);
}
.bg-card3 {
  background: rgba(255, 236, 231, 1);
}
.bg-card4 {
  background: rgba(222, 243, 209, 1);
}
.bg-card5 {
  background: rgba(242, 228, 245, 1);
}
.bg-card6 {
  background: rgba(215, 244, 227, 1);
}
</style>
