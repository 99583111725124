// store/auth.ts
import { defineStore } from 'pinia'
import axios  from "@/Plugins/axios"
import router from "@/router";





export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: JSON.parse(localStorage.getItem('user')) || null,
        token: localStorage.getItem("token") || null,
        isAuthenticated: false,
    }),
    actions: {
        async login(credentials) {
           
            const response = await axios.post("/login", credentials);

            const token = response.data.data.token;
            localStorage.setItem("token", token);
            const savedToken = localStorage.getItem("token");
            await this.initAuthStore(savedToken);

        },

        async signup(signUpForm) {
          
            const response = await axios.post("/register", signUpForm);
            const token = response.data.data.token;
            localStorage.setItem("token", token);
            const savedToken = localStorage.getItem("token");
            await this.initAuthStore(savedToken);
        },
        async setUser(token) {
           token = localStorage.getItem("token") || null
            const response = await axios.get("/me", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            this.user = response.data.data;
            localStorage.setItem("user", JSON.stringify(this.user));

            if(this.user?.user_type == "member"){
             router.push({name: 'UserDashboard'});
            } else {
                router.push({name: 'AdminDashboard'});
            }
        },

        async initAuthStore(token) {
            if (token) {
                this.token = token;

                await this.setUser(token);
                this.isAuthenticated = true;
            }
        },

        async logout() {
           
            await axios.post("/logout", {
                headers: { Authorization: `Bearer ${this.token}` },
            });
            this.token = null;
            this.user = null;
            
            this.isAuthenticated = false
            localStorage.removeItem("user");
            localStorage.removeItem('token')




        }
    }
})

