export const getFormattedAmount = (amount) => {
    return new Intl.NumberFormat("en-US").format(amount);
};

export const getFormattedDate = (date) => {
    var options = {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
    };

    const theDate = new Date(date);

    var formattedDate = theDate.toLocaleDateString("en-US", options);

    return formattedDate;
};

export const getFormattedDateNumber = (date) => {
    const theDate = new Date(date);

    // Extract year, month, and day
    const year = theDate.getFullYear();
    const month = String(theDate.getMonth() + 1).padStart(2, '0');
    const day = String(theDate.getDate()).padStart(2, '0');

    // Return in yyyy-mm-dd format
    return `${day}-${month}-${year}`;
};