<template>
  <div>
    <AdminLayout Title="Counselling" :isRequired="false">
      <TableSkeletonLoader class="pt-6" v-if="loading"/>
      <div class="container" v-else>
        <div class="row d-flex justify-content-end pt-6 mb-3">
          <div class="col-12 d-flex justify-content-end">
            <router-link
              :to="{ name: 'AdminAddCounselling' }"
              class="loggedInBtn2 shadow-sm text-decoration-none"
              >Create New Counselling</router-link
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="row bg-white py-3 px-1 rounded-2 b-color">
              <div class="col-12">
                <!-- <div
                  class="card p-2 border-0 bg-card2 mb-3 d-flex justify-content-between flex-column"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p class="text-body-secondary">Active Counselling</p>
                    <img
                      src="../../assets/admin-active-counselling.png"
                      class="img-fluid d-flex align-self-start"
                      width="50"
                    />
                  </div>
                  <div class="d-flex">
                    <h5 class="mt-3 fw-semibold">1,269</h5>
                  </div>
                </div>
                <div
                  class="card p-2 border-0 bg-card3 mb-3 d-flex justify-content-between flex-column"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p class="text-body-secondary">Closed Counselling</p>
                    <img
                      src="../../assets/admin-closed-counselling.png"
                      class="img-fluid d-flex align-self-start"
                      width="50"
                    />
                  </div>
                  <div class="d-flex">
                    <h5 class="mt-3 fw-semibold">1,269</h5>
                  </div>
                </div> -->
                <div
                  class="card p-2 border-0 bg-card1 d-flex justify-content-between flex-column"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p class="text-body-secondary">Total Sessions</p>
                    <img
                      src="../../assets/admin-total-sessions.png"
                      class="img-fluid d-flex align-self-start"
                      width="50"
                    />
                  </div>
                  <div class="d-flex">
                    <h5 class="mt-3 fw-semibold">{{ counselling.length }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div class="bg-white p-2 rounded-2 b-color">
              <div class="row d-flex align-items-center">
                <div class="col-12 col-lg-8">
                  <input
                    type="text"
                    placeholder="Enter to search"
                    class="form-control p-2 ms-0"
                  />
                </div>
                <div class="col-12 col-lg-4 d-flex justify-content-end">
                  <div class="d-flex p-1 mb-1">
                    <button class="m-1 btn-accent1 p-1">All</button>
                    <button class="m-1 btn-accent2 p-1" href="#">Active</button>
                    <button class="m-1 btn-accent2 p-1" href="#">Closed</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card p-3 b-color mt-3">
              <div class="row">
                <div v-if="counselling.length == 0">
                   No Counselling Session
                </div>
                <div class="col-12 col-lg-6" v-for="session in counselling" :key="session.id">
                  <router-link
                    :to="{ name: 'AdminCounsellingDetail', params: {id: session?.id} }"
                    class="text-decoration-none"
                  >
                    <div
                      class="position-relative rounded-3 border mt-3"
                      style="cursor: pointer"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center px-2 pt-3 pb-2"
                      >
                        <span class="fw-semibold text-dark"
                          >{{ session?.title }} </span
                        >
                        <button class="btn-active">{{ session?.status }}</button>
                      </div>
                      <hr class="" />
                      <div class="row px-2">
                        <div class="col-12">
                          <div>
                            <span class="text-body-secondary" style="font-size: 0.9rem">Created By</span>
                            <p class="fw-semibold text-dark" style="font-size: 0.93rem">{{ session?.created_by?.first_name }} {{ session?.created_by?.last_name }}</p>
                          </div>
                        </div>
                        <div class="col-12">
                          <div>
                            <span class="text-body-secondary" style="font-size: 0.9rem">Validity Period</span>
                            <p class="fw-semibold text-dark" style="font-size: 0.93rem">{{ getFormattedDate(session?.start_time) }} - {{ getFormattedDate(session?.end_time) }}</p>
                          </div>
                        </div>
                        <!-- <div class="col-12">
                          <div>
                            <span class="text-body-secondary" style="font-size: 0.9rem">Registered Members</span>
                            <p class="fw-semibold text-dark" style="font-size: 0.93rem">120 people</p>
                          </div>
                        </div> -->
                        <div class="col-12">
                          <div>
                            <span class="text-body-secondary" style="font-size: 0.9rem">Created On</span>
                            <p class="fw-semibold text-dark" style="font-size: 0.93rem">{{getFormattedDate(session?.created_by?.created_at) }}</p>
                          </div>
                        </div>
                        <div class="col-12">
                          <div>
                            <span class="text-body-secondary" style="font-size: 0.9rem">Session Time</span>
                            <p class="fw-semibold text-dark" style="font-size: 0.93rem">{{ session?.session_duration }} Minutes</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
               
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  </div>
</template>
<script setup>
import {ref, onMounted} from "vue";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";

import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";


import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";


const counselling = ref([])

const loading = ref(false);

async function initCounselling() {
  loading.value = true;
  try {
    const _counsellingData = await axios.get(`/admin/counselling-sessions`);

    counselling.value = _counsellingData.data.data
  
    loading.value = false;
  } catch (error) {
    utils.showToast(catchAxiosExceptions(error).msg, "error");
    loading.value = false;
  }
}

onMounted(() => {
  initCounselling();
});
</script>
<style scoped>
/* *{
  outline: 1px solid red;
} */

.btn-accent1 {
  background-color: rgba(234, 241, 255, 1);
  color: rgba(24, 41, 101, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}
.btn-accent2 {
  background-color: rgba(246, 246, 246, 1);
  color: rgba(96, 96, 96, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}

.loggedInBtn2 {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.filter {
  border: 1px solid rgba(218, 229, 239, 1);
  backdrop-filter: blur(20px);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.02);
  border-radius: 9px;
}
.pt-6 {
  padding-top: 6rem !important;
}

.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}

.bg-white {
  background-color: #fff;
}

.bg-card1 {
  background-color: #e4f5ff !important;
}
.bg-card2 {
  background-color: #e1ffea !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.bg-card3 {
  background-color: #ffeeee !important;
}

.home {
  background-color: rgba(0, 21, 63, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.home2 {
  background-color: rgba(0, 55, 187, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.home3 {
  background-color: rgba(224, 58, 111, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.home4 {
  background-color: rgba(0, 79, 36, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
