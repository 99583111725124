<template>
  <div>
    <AdminLayout Title="Settings" :isRequired="false">
      <TableSkeletonLoader class="pt-6" v-if="loading2" />

      <div class="container pt-6" v-else>
        <div class="row d-flex justify-content-center mt-4 g-3">
          <div class="col-12 col-lg-7">
            <div class="card b-color p-4 shadow-sm">
              <p class="text-center position-relative mt-4">
                <img
                  :src="
                    updatePayload?.photo
                      ? updatePayload.photo
                      : require('@/assets/dummy-avatar-logo.svg')
                  "
                  alt=""
                  class="img-fluid rounded-circle"
                  width="150"
                  height="100"
                />
                <!-- <img
                  src="../../assets/editProfileBtn.png"
                  alt=""
                  class="img-fluid position-absolute shadow-sm rounded-circle"
                  width="50"
                  style="left: 55%"
                /> -->
              </p>

              <div class="rounded-2 p-3" v-if="!showEdit">
                <div class="row">
                  <div class="col-12 col-md-6 my-3">
                    <h6 class="form-label">First Name</h6>
                    <p class="mt-2">{{ updatePayload.first_name }}</p>
                  </div>
                  <div class="col-12 col-md-6 my-3">
                    <h6 class="form-label">Last Name</h6>
                    <p class="mt-2">{{ updatePayload.last_name }}</p>
                  </div>
                  <div class="col-lg-6 my-4">
                    <h6 class="form-label">Email Address</h6>
                    <p class="mt-2">{{ updatePayload.email }}</p>
                  </div>
                  <div class="col-lg-6 mt-3">
                    <h6 class="form-label">Phone Number</h6>
                    <p class="mt-2">{{ updatePayload.phone }}</p>
                  </div>

                  <div class="col-lg-12 mt-4">
                    <h6 class="form-label">Home Address</h6>
                    <p class="mt-2">{{ updatePayload.address }}</p>
                  </div>

                  <div class="col-12 mt-5">
                    <p>
                      <button
                        class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                        type="submit"
                        @click.prevent="showEditForm"
                      >
                        <span>Proceed to updating Profile</span>
                        <div>
                          <img
                            src="../../assets/arrow-right.svg"
                            alt=""
                            width="40"
                            height="40"
                            class="img-fluid"
                            v-if="!loading"
                          />
                          <div
                            class="spinner-grow text-white"
                            role="status"
                            v-else-if="loading"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </button>
                    </p>
                  </div>
                </div>
              </div>

              <form class="rounded-2 p-3" v-if="showEdit">
                <div class="row">
                  <div class="col-12 col-md-6 my-3">
                    <h6 class="form-label">First Name</h6>
                    <input
                      type="text"
                      placeholder="Enter your first name"
                      class="form-control p-3 bg-form"
                      v-model="updatePayload.first_name"
                    />
                  </div>
                  <div class="col-12 col-md-6 my-3">
                    <h6 class="form-label">Last Name</h6>
                    <input
                      type="text"
                      placeholder="Enter your last name"
                      class="form-control p-3 bg-form"
                      v-model="updatePayload.last_name"
                    />
                  </div>
                  <div class="col-lg-12 my-4">
                    <h6 class="form-label">Email Address</h6>
                    <input
                      type="text"
                      placeholder="Enter your email address"
                      class="form-control p-3 bg-form"
                      v-model="updatePayload.email"
                    />
                  </div>
                  <div class="col-lg-12 mb-3">
                    <h6 class="form-label">Phone Number</h6>
                    <vue-tel-input
                      v-model="updatePayload.phone"
                      class="p-2 rounded-3 bg-form"
                    />
                  </div>

                  <div class="col-lg-12 my-4">
                    <h6 class="form-label">Home Address</h6>
                    <input
                      type="text"
                      class="form-control p-3 bg-form"
                      v-model="updatePayload.address"
                    />
                  </div>
                  <div class="col-lg-12 my-4">
                    <h6 class="form-label">Profile Picture</h6>
                    <input
                      type="file"
                      ref="fileInput"
                      class="form-control p-3 bg-form"
                      @change="handleFileChange"
                    />
                  </div>

                  <div class="col-12 mt-5">
                    <p>
                      <button
                        class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                        type="submit"
                        @click.prevent="updateProfile"
                      >
                        <span>Update Profile</span>
                        <div>
                          <img
                            src="../../assets/arrow-right.svg"
                            alt=""
                            width="40"
                            height="40"
                            class="img-fluid"
                            v-if="!loading"
                          />
                          <div
                            class="spinner-grow text-white"
                            role="status"
                            v-else-if="loading"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </button>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal :show="postedSuccessfully">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="postedSuccessfully = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-check-success.svg"
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Updated Successfully
            </p>

            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <router-link
                :to="{ name: 'AdminDashboard' }"
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4 text-decoration-none"
                @click.prevent="postedSuccessfully = false"
              >
                Back to Home
              </router-link>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";
// import FormError from "@/components/FormError.vue";
// import VueTelInput from 'vue-tel-input'

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
// import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";

import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

const updatePayload = ref({});


const showEdit = ref(false);

function showEditForm() {
  showEdit.value = true;
}

const formErrors = ref({});

const loading = ref(false);
const loading2 = ref(false);
const postedSuccessfully = ref(false);
const bindProps = ref({
  mode: "international",
  defaultCountry: "",
  disabledFetchingCountry: false,
  disabled: false,
  // disabledFormatting: false,
  placeholder: "Enter your Mobile number",
  required: true,
  enabledCountryCode: true,
  enabledFlags: true,
  // validCharactersOnly: true,
  // preferredCountries: ["AU", "BR"],
  onlyCountries: [],
  ignoredCountries: [],
  autocomplete: "on",
  // name: "telephone",
  maxLen: 25,
  wrapperClasses: "",
  inputClasses: "",
  // dropdownOptions: {
  //   disabledDialCode: false,
  // },
  inputOptions: {
    showDialCode: false,
  },
});

function getProfile() {
  loading2.value = true;
  axios
    .get(`/me`)
    .then((response) => {
      updatePayload.value = response.data.data;
      loading2.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading2.value = false;
    });
}

function updateProfile() {
  formErrors.value = {};
  loading.value = true;
  const updatedForm = {
  ...updatePayload.value,
  '_method': 'PUT'
}
  axios
    .post(`/me/update`, updatedForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      loading.value = false;
      postedSuccessfully.value = true;
      getProfile()
    })
    .catch((error) => {
      loading.value = false;
      formErrors.value = error.response?.data?.errors;
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

const fileInput = ref(null);

function handleFileChange(event) {
  const file = event.target.files[0];
  if (file) {
    updatePayload.value.photo = file;
    console.log(updatePayload.value.photo, "0909")
  } else {
    updatePayload.value.photo = null;
  }
}

onMounted(() => {
  getProfile();
});
</script>
<style scoped>
:deep(.vti__input) {
  background-color: #f5fbfc !important;
  /* padding: 0.7rem; */
  /* border-color: #417BDF; */
}

:deep(.vue-tel-input) {
  background-color: #f5fbfc !important;
  color: #131313 !important;
  border-radius: 4px;
  /* border-color: #417bdf !important; */
}
input,
textarea {
  background-color: #f5fbfc !important;
}
.pt-6 {
  padding-top: 6rem !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.bg-card1 {
  background-color: #e4f5ff !important;
}
.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.2rem !important;
  border-radius: 5px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.home3 {
  background-color: rgba(224, 58, 111, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}
</style>
