<template>
  <div>
    <AdminLayout Title="Devotionals" :isRequired="false">
      <div class="container pt-6">
        <router-link
          :to="{ name: 'AdminDevotionals' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <div class="row mt-4 d-flex justify-content-center">
          <div class="col-12 col-lg-7">
            <div
              class="p-4 position-relative home3 rounded-bottom-0 rounded-top-3"
              style="cursor: pointer"
            >
              <div class="position-absolute" style="right: 3rem; top: 2rem">
                <img src="../../assets/devot-edit.svg" alt="" />
                <img
                  src="../../assets/devot-delete.svg"
                  @click.prevent="showDeleteConfirmation = true"
                  alt=""
                />
              </div>
              <div class="text-white">
                <h5 class="card-title text-capitalize">Devotional</h5>
                <p class="card-text">
                  <img
                    src="../../assets/calendar-2.png"
                    alt=""
                    class="img-fluid"
                  />
                  Friday | 6:00 - 6:30 PM
                </p>
                <!-- <p class="card-text">
                  <img src="../../assets/location-minus.png" alt="" />

                  <span class="text-capitalize">Google Meet</span>
                </p> -->
              </div>
            </div>
            <div class="bg-white rounded-bottom-1 p-3 b-color">
              <p class="fw-semibold mb-1">Bible Text : Isaiah 11: 1-2</p>
              <p class="mt-0">
                The Spirit of the LORD shall rest upon Him, The Spirit of wisdom
                and understanding, The Spirit of counsel and might, The Spirit
                of knowledge and of the fear of the LORD.
              </p>

              <p class="fw-semibold mt-4 mb-1">Message</p>
              <p>
                Merciful Father, I humbly come before You to confess my sins and
                shortcomings. Wash me clean and purify my heart, Lord, as I
                repent of my transgressions and seek Your forgiveness. Heal the
                wounds of my past and grant me the strength to forgive others as
                You have forgiven me, that I may experience the fullness of Your
                grace and mercy.
              </p>
              <p class="fw-semibold mt-4 mb-1">Prayer Points</p>
              <p>
                Merciful Father, I humbly come before You to confess my sins and
                shortcomings. Wash me clean and purify my heart, Lord, as I
                repent of my transgressions and seek Your forgiveness. Heal the
                wounds of my past and grant me the strength to forgive others as
                You have forgiven me, that I may experience the fullness of Your
                grace and mercy.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal :show="showDeleteConfirmation" width="500">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="showDeleteConfirmation = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-bin2.svg"
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Do you want to delete this Devotional?
            </p>

            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <button
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4 text-decoration-none"
                @click.prevent="deleteDevotional"
              >
                Confirm
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
      <Modal :show="deletedSuccessfully">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="deletedSuccessfully = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-check-success.svg"
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Devotional Posted Successfully
            </p>

            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <router-link
                :to="{ name: 'AdminDevotionals' }"
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4 text-decoration-none"
                @click.prevent="deletedSuccessfully = false"
              >
                Back to Home
              </router-link>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";

const loading = ref(false);
const router = useRouter();
const showDeleteConfirmation = ref(false);
const deletedSuccessfully = ref(false);

function deleteDevotional() {
  showDeleteConfirmation.value = false;
  deletedSuccessfully.value = true;
  setTimeout(() => {
    router.push({ name: "AdminDevotionals" });
  }, 1000);
}
</script>
<style scoped>
/* *{
    outline: 1px solid green;
} */
input,
textarea {
  background-color: #f5fbfc !important;
}
.pt-6 {
  padding-top: 6rem !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.bg-card1 {
  background-color: #e4f5ff !important;
}
.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.2rem !important;
  border-radius: 5px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.home3 {
  background-color: rgba(224, 58, 111, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}
</style>
