<template>
  <div class="d-flex">
    <!-- Sidebar (initially collapsed) -->
    <nav id="sidebar" class="sidebar b-right">
      <!-- Sidebar Header with Toggle Icon -->
      <div class="sidebar-header bg-sidebar-header text-white sticky-top top-0">
        <div class="d-flex align-items-center p-sm">
          <a href="#" class="mb-0">
            <img
              src="../../assets/logoP.jpg"
              alt="Logo"
              class="rounded-circle"
            />
          </a>
          <h5 class="mt-3 fw-bold text-black ms-3 pb-3 fs-medium">
            Prophetic Prayer
          </h5>

          <button
            @click.prevent="handleCloseSidebar"
            class="btn btn-light btn-sm ms-auto d-lg-none rounded"
          >
            <i class="bi bi-x"></i>
          </button>
        </div>
      </div>

      <!-- Sidebar Body -->
      <div class="sidebar-body pt-3 bg-sidebar-header border-0" style="min-height:100vh">
        <ul class="nav flex-column">
          <li class="nav-item py-0" v-for="link in links" :key="link.name">
            <router-link
              class="nav-link mx-3 mb-1"
              :to="link.route"
              active-class="active"
            >
              <img :src="require(`../../assets/${link.icon}`)" alt="" class="img-fluid ms-2 me-2 mb-1" />
              {{ link.name }}
            </router-link>

          </li>

          <li class="nav-item mt-6">

            <router-link class="nav-link mx-3 mb-1" to="#" @click.prevent="showLogout = true"
              ><img
                src="../../assets/admin-logout.png"
                alt=""
                class="ms-2 img-fluid me-2 mb-1"
              /><span style="color: #d72509">Logout</span></router-link
            >
          </li>
        </ul>
      </div>
    </nav>

    <!-- Main content -->
    <div class="content-wrapper ">
      <!-- Top Navbar -->
      <nav class="bg-sidebar-header p-sm b-right flex-grow-1 fixed-top">
        <div class="container sticky-top top-0 left-0">
          <div class="d-flex align-items-center" style="min-height: 7.5vh">
            <button
              @click.prevent="handleCloseSidebar"
              class="btn btn-light btn-sm d-lg-none"
            >
              <i class="bi bi-list"></i>
            </button>
            <h4 class="fw-bold text-black ms-3 ms-lg-0">{{ Title }}</h4>

            <div class="d-lg-flex ms-lg-auto d-none" v-if="isRequired">
              <!-- <div class="d-flex filter p-2 mb-1">
                <button class="m-1 btn-accent1 p-1">All Time</button>
                <button class="m-1 btn-accent2 p-1" href="#">Today</button>
                <button class="m-1 btn-accent2 p-1" href="#">This Week</button>
                <button class="m-1 btn-accent2 p-1" href="#">This Month</button>
              </div> -->

              <div class="dropdown more dropstart me-1 d-none d-lg-flex ms-4">
                <a
                  href="#"
                  class="d-flex align-items-center text-decoration-none dropdown-toggle nav-link py-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="../../assets/admin-alarm.png"
                    alt=""
                    class="img-fluid me-1"
                  />
                </a>

                <ul
                  class="dropdown-menu dropdown-menu-light text-small shadow w-100 min-width-md-400px mt-5"
                  style=""
                >
                  <li class="w-100">
                    <p class="mb-2 ms-3 header-text">Notification</p>
                  </li>
                  <li class="w-100">
                   <span class="ms-4"> No Notification</span>

                  </li>
                  <!-- <li class="w-100">
                    <ul class="list-group list-group-flush my-0 px-3">
                      <li
                        class="list-group-item my-2 d-flex justify-content-between p-3 border-bottom"
                        style="cursor: pointer"
                      >
                        <div class="d-flex">
                          <img
                            src="../../assets/notif-meeting-starts.png"
                            alt=""
                            class="me-3 img-fluid"
                            width="45"
                          />
                          <div>
                            <h6 class="mb-0">Meeting Starting Soon</h6>
                            <p
                              class="text-body-secondary m-0"
                              style="font-size: 0.95rem"
                            >
                              Prayer meeting is starting in 5 min!
                            </p>
                          </div>
                        </div>
                        <span class="ps-5 text-primary">Just Now</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content-between p-3 border-bottom"
                        style="cursor: pointer"
                      >
                        <div class="d-flex">
                          <img
                            src="../../assets/notif-new-recordings.png"
                            alt=""
                            class="me-3 img-fluid"
                            width="45"
                          />
                          <div>
                            <h6 class="mb-0">New Recording Uploaded</h6>
                            <p
                              class="text-body-secondary m-0"
                              style="font-size: 0.95rem"
                            >
                              Night of Bliss has been uploaded
                            </p>
                          </div>
                        </div>
                        <span class="ps-5 text-primary">Just Now</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content-between p-3 border-bottom"
                        style="cursor: pointer"
                      >
                        <div class="d-flex">
                          <img
                            src="../../assets/notif-prayer-approved.png"
                            alt=""
                            class="me-3 img-fluid"
                            width="45"
                          />
                          <div>
                            <h6 class="mb-0">Prayer Point Approved</h6>
                            <p
                              class="text-body-secondary m-0"
                              style="font-size: 0.95rem"
                            >
                              Your prayer point has been approved
                            </p>
                          </div>
                        </div>
                        <span class="ps-5 text-primary">Just Now</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content-between p-3 border-bottom"
                        style="cursor: pointer"
                      >
                        <div class="d-flex">
                          <img
                            src="../../assets/notif-testimony-approved.png"
                            alt=""
                            class="me-3 img-fluid"
                            width="45"
                          />
                          <div>
                            <h6 class="mb-0">Testimony Approved</h6>
                            <p
                              class="text-body-secondary m-0"
                              style="font-size: 0.95rem"
                            >
                              Your testimony has been approved
                            </p>
                          </div>
                        </div>
                        <span class="ps-5 text-primary">Just Now</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content-between p-3 border-bottom"
                        style="cursor: pointer"
                      >
                        <div class="d-flex">
                          <img
                            src="../../assets/notif-daily-devotional.png"
                            alt=""
                            class="me-3 img-fluid"
                            width="45"
                          />
                          <div>
                            <h6 class="mb-0">Daily Devotional</h6>
                            <p
                              class="text-body-secondary m-0"
                              style="font-size: 0.95rem"
                            >
                              Scriptures for you
                            </p>
                          </div>
                        </div>
                        <span class="ps-5 text-primary">Just Now</span>
                      </li>
                    </ul>
                  </li> -->
                </ul>
              </div>

              <div class="dropdown d-flex d-none d-lg-flex ms-1 custom">
                <!-- <img src="../assets/alarm.png" alt="" class="img-fluid me-2" /> -->
                <a
                  href="#"
                  class="d-flex align-items-center text-decoration-none dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >

                   <img
                  :src="
                    user?.photo
                      ? user?.photo
                      : require('@/assets/dummy-avatar-logo.svg')
                  "
                  alt=""
                  class="img-fluid rounded-circle me-2"
                  width="32"
                  height="32"
                />

                  <span class="fw-semibold text-black"
                    >{{ user?.first_name }} {{ user?.last_name }}
                  </span>
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-light text-small shadow d-none"
                  style=""
                >
                  <li>
                    <a class="dropdown-item text-dark" href="#">Profile</a>
                  </li>
                  <!-- <li><hr class="dropdown-divider" /></li> -->
                  <!-- <li>
                    <a class="dropdown-item text-dark" href="#">Sign out</a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <!-- Dynamic Content -->
      <div class="container-fluid mt-4 bg-grey min-height-full">
        <Modal :show="showLogout">
          <template #content>
            <div
              class="d-flex justify-content-center flex-column position-relative"
            >
              <img
                src="../../assets/close-circle.png"
                alt=""
                class="img-fluid position-absolute"
                style="top: 0; right: 0; cursor: pointer"
                @click.prevent="showLogout = false"
              />
              <div class="d-flex justify-content-center">
                <img
                  src="../../assets/admin-sign-out.png"
                  alt=""
                  class="img-fluid"
                  width="100"
                />
              </div>
              <p class="text-center mt-3 header-text2 mb-0">Sign Out</p>
              <p class="fw-light mt-0 text-center">
                Are you sure you want to sign out?
              </p>
              <p
                class="text-center d-block d-md-flex justify-content-center mt-3"
              >
                <button
                  @click.prevent="logOut"
                  class="signOutBtn btn-lg text-capitalize font-weight-bold"
                >
                  Yes Logout
                </button>
                <button
                  class="loggedInBtn2 btn-lg text-capitalize font-weight-bold ms-2 ms-md-4"
                  @click.prevent="showLogout = false"
                >
                  No Keep Me Logged In
                </button>
              </p>
            </div>
          </template>
          <!-- <template #footer>
        <p>hello</p>
      </template> -->
        </Modal>
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Modal from "@/components/Modal.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";

import { useAuthStore } from "@/stores/auth";

import axios from "@/Plugins/axios";


const router = useRouter();

const store = useAuthStore();

const is_expanded = ref(false);

function toggleMenu() {
  is_expanded.value = !is_expanded.value;

  document.querySelector(".sidebar").classList.add("active");
  // document.querySelector(".content-wrapper").classList.add("active");
}

const handleCloseSidebar = () => {
  document.querySelector(".sidebar").classList.toggle("active");
};

    

const user = store?.user;

const links = ref([
  {
    name: "Home",
    route: { name: "AdminDashboard" },
    icon: "admin-home-2.png",
  },
  {
    name: "Prayer Request",
    route: { name: "AdminPrayerRequest" },
    icon: "admin-message-notif.png",
  },
  {
    name: "Members",
    route: { name: "AdminMembers" },
    icon: "admin-profile-2user.png",
  },
  // {
  //   name: "Devotionals",
  //   route: { name: "AdminDevotionals" },
  //   icon: "admin-devotional.png",
  // },
  {
    name: "Testimonies",
    route: { name: "AdminTestimonies" },
    icon: "admin-testimony.png",
  },
  {
    name: "Roles & Permissions",
    route: { name: "AdminRoles" },
    icon: "admin-roles.png",
  },
  {
    name: "Prayer Meetings",
    route: { name: "AdminPrayerMeetings" },
    icon: "admin-prayer-meetings.png",
  },
  {
    name: "Announcements",
    route: { name: "AdminAnnouncement" },
    icon: "microphone-2menu.png",
  },
  {
    name: "Counselling",
    route: { name: "AdminCounselling" },
    icon: "admin-counselling.png",
  },
  {
    name: "Recordings",
    route: { name: "AdminRecordings" },
    icon: "videoMenu.png",
  },
  {
    name: "Profile & Settings",
    route: { name: "AdminSettings" },
    icon: "admin-setting-2.png",
  },
]);

const showLogout = ref(false);

const props = defineProps({
  Title: {
    type: String,
    required: false,
  },
  isRequired: {
    type: Boolean,
    required: false,
  },
});

// State to track whether the sidebar is collapsed
const isCollapsed = ref(false);

function logOut() {
  router.push({ name: "UserLogin" });
  store
    .logout()
    .then((response) => {})
    .catch((error) => {
      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

// Function to toggle sidebar visibility
const toggleSidebar = () => {
  isCollapsed.value = !isCollapsed.value;
};

// Track the current active link
const currentActive = ref("Dashboard"); // Default to 'Dashboard' being active

// Function to set the active link
const setActive = (linkName) => {
  currentActive.value = linkName;

  console.log(currentActive.value);
};
</script>

<style scoped>
/* Styling for the sidebar */

.nav-item .nav-link.active {
  background-color: rgba(238, 243, 255, 1) !important;
  color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border-radius: 8px;
}

.nav-item .nav-link.active img {
  filter: invert(16%) sepia(87%) saturate(3229%) hue-rotate(223deg)
    brightness(91%) contrast(103%);
}

.nav-item .nav-link {
  color: inherit !important;
  transition: background-color 0.3s, color 0.3s !important;
  font-weight: 400 !important;
  padding: 0.8rem 0.3rem;
  border-radius: 8px;
}

.signOutBtn {
  background-color: #f24c35 !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.loggedInBtn2 {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

/* .nav-link:hover {
  background-color: #e0f0ff !important;
  color: darkblue !important;
} */

.fixed-top {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    z-index: 1 !important;
}
.sidebar {
  width: 280px;
  position: fixed;
  top: 0;
  left:0;
  bottom: 0;
  /* z-index: 10000; */
  /* display: flex;
  flex-direction: column; */
  transition: width 0.3s ease;
  min-height: 100vh;
  overflow-x: hidden;
  transition: transform 0.5s ease-in-out;


  transform: translateX(-0);
}

.sidebar.active {
  width: 0;
  transform: translateX(-0);
}

/* * {
  outline: 1px solid red;
} */
.min-height-full {
  min-height: 100vh;
}
/* #sidebar.collapsed {
  width: 70px; 
} */

/* Adjust main content position when sidebar is collapsed */
.flex-grow-1 {
  margin-left: 280px;
  transition: margin-left 0.3s ease;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 300px);
  margin-left: 280px;
  width: 100%;
  /* transition: width 0.3s ease-in-out; */
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
}

.content-wrapper.active {
  margin-left: 0;
  width: 100%;
}

@media only screen and (max-width: 596px) {
  .content-wrapper {
    width: 100%;
    margin-left: 0;
  }
}
@media only screen and (min-width: 568px) and (max-width: 991px) {
  .content-wrapper {
    width: 100%;
    margin-left: 0;
  }
}

/* #sidebar.collapsed ~ .flex-grow-1 {
  margin-left: 70px;
} */

/* Sidebar Header and Body */
/* .sidebar-header {
  height: 60px;
} */

/* .sidebar-body {
  height: calc(100% - 60px);
  overflow-y: auto;
} */

.bg-sidebar-header {
  background-color: rgba(255, 255, 255);
  border-bottom: 1px solid rgba(218, 229, 239, 1);
}

.b-right {
  border-right: 1px solid rgba(218, 229, 239, 1);
  backdrop-filter: blur(20px);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.02);
  /* border-width: 10px; */
}

.filter {
  border: 1px solid rgba(218, 229, 239, 1);
  backdrop-filter: blur(20px);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.02);
  border-radius: 9px;
}

/* Button and icon styling */
.btn {
  padding: 0.25rem 0.5rem;
}

.p-sm {
  padding: 0.7rem;
}

.fs-medium {
  font-size: 1.2rem;
}

.btn-accent1 {
  background-color: rgba(234, 241, 255, 1);
  color: rgba(24, 41, 101, 1);
  border-radius: 5px;
  border: none;
}
.btn-accent2 {
  background-color: rgba(246, 246, 246, 1);
  color: rgba(96, 96, 96, 1);
  border-radius: 5px;
  border: none;
}

.mt-6 {
  margin-top: 4rem !important;
}

.min-width-md-200px {
  min-width: 400px;
}
.min-width-md-400px {
  min-width: 500px;
  /* min-height: 100vh; */
}
.more .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: none !important;
}

.custom .dropdown-toggle::after {
  content: "";
  display: none;
  background-image: url("../../assets/arrow-square-down.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.7rem;
  height: 1.7rem;
  border: none;
  /* margin-left: 0.5em; */
  vertical-align: middle;
}

/* #sidebar.active {
  width: 0;
  transform: translate(-0);
} */

@media only screen and (max-width: 567px) {
 .sidebar {
   width: 0;
  transform: translateX(-100);
  z-index: 10000;
 }

 .sidebar.active {
  width: 280px;
  transform: translateX(0);
}
  .flex-grow-1 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 568px) and (max-width: 991px) {
 
    .sidebar {
   width: 0;
  transform: translateX(-100);
  /* z-index: 10000; */
 }

 .sidebar.active {
  width: 280px;
  transform: translateX(0);
}
 

  #sidebar.active {
    transform: translate(-0);
  }

  .flex-grow-1 {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .min-width-md-200px {
    width: 100%;
  }

    .sidebar {
   width: 0;
  transform: translateX(-100);
  z-index: 10000;
 }
  .min-width-md-400px {
    width: 100%;
  }
}

.header-text2 {
  font-weight: 800;
  font-size: 1.6rem;
}

.header-text {
  font-weight: 300;
  font-size: 1.4rem;
}

.bg-grey {
  background-color: #f9f8fa !important;
}
</style>
