<template>
  <div>
    <UserLayout>
      <!-- <BulletList/> -->
      <TableSkeletonLoader class="pt-6" v-if="loading2" />

      <section class="bg-signIn d-flex" v-else>
        <div class="container">
          <div class="py-6 position-relative">
            <h2 class="header-text">Profile</h2>
            <div class="row d-flex justify-content-center mt-4 g-3">
              <div class="col-12 col-lg-5">
                <div class="card b-color p-4 shadow-sm">
                  <p class="text-center position-relative mt-4">
                    <img
                      :src="
                        userDetails?.photo
                          ? userDetails.photo
                          : require('@/assets/dummy-avatar-logo.svg')
                      "
                      alt=""
                      class="img-fluid rounded-circle"
                      width="150"
                      height="150"
                    />
                    <!-- <img
                      src="../../assets/editProfileBtn.png"
                      alt=""
                      class="img-fluid position-absolute shadow-sm rounded-circle"
                      width="50"
                      style="left: 60%"
                    /> -->
                  </p>


                  <div class="row mt-3 mb-3 g-3">
                    <div class="col-md-4">
                      <div
                        class="card bg-accent1 border-0 shadow-sm px-2 py-3 text-center"
                      >
                        <h4>{{ prayerRequests }}</h4>
                        <p class="text-body-secondary">Prayer Requests</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div
                        class="card bg-accent2 border-0 shadow-sm py-3 px-1 text-center"
                      >
                        <h4>{{ answeredPrayers }}</h4>
                        <p class="text-body-secondary">Answered Prayers</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div
                        class="card bg-accent3 border-0 shadow-sm px-2 py-3 text-center"
                      >
                        <h4>{{ testimonies }}</h4>
                        <p class="text-body-secondary">Testimonies</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-5">
                    <p>
                      <button
                        class="btn btn-accent1 shadow-sm text-capitalize fw-semilight w-100"
                        type="submit"
                        @click.prevent="showEditForm"
                      >
                        Update Profile
                      </button>
                    </p>
                  </div>
                  <button
                    class="btn-accent btn shadow-sm"
                    @click.prevent="showDeleteModal = true"
                  >
                    Delete My Profile
                  </button>
                </div>
              </div>

              <div
                class="rounded-2 p-3 col-12 col-lg-7 card b-color"
                v-if="!showEdit"
              >
                <div class="row">
                  <div class="col-12 my-3">
                    <h6 class="form-label">First Name</h6>
                    <p class="mt-2">{{ userDetails.first_name }}</p>
                  </div>
                  <div class="col-12 my-3">
                    <h6 class="form-label">Last Name</h6>
                    <p class="mt-2">{{ userDetails.last_name }}</p>
                  </div>
                  <div class="col-12 my-4">
                    <h6 class="form-label">Email Address</h6>
                    <p class="mt-2">{{ userDetails.email }}</p>
                  </div>
                  <div class="col-12 mt-3">
                    <h6 class="form-label">Phone Number</h6>
                    <p class="mt-2">{{ userDetails.phone }}</p>
                  </div>

                  <div class="col-lg-12 mt-4">
                    <h6 class="form-label">Home Address</h6>
                    <p class="mt-2">{{ userDetails.address }}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-7" v-if="showEdit">
                <div class="card p-3 shadow-sm b-color">
                  <form
                    action=""
                    class="rounded-2 p-3"
                    @submit.prevent="updateProfile"
                  >
                    <div class="row">
                      <div class="col-12 col-md-6 my-3">
                        <h6 class="form-label">First Name</h6>
                        <input
                          type="text"
                          placeholder="Enter your first name"
                          class="form-control p-3 bg-form"
                          v-model="userDetails.first_name"
                        />
                      </div>
                      <div class="col-12 col-md-6 my-3">
                        <h6 class="form-label">Last Name</h6>
                        <input
                          type="text"
                          placeholder="Enter your last name"
                          class="form-control p-3 bg-form"
                          v-model="userDetails.last_name"
                        />
                      </div>
                      <div class="col-lg-12 my-4">
                        <h6 class="form-label">Email Address</h6>
                        <input
                          type="text"
                          placeholder="Enter your email address"
                          class="form-control p-3 bg-form"
                          v-model="userDetails.email"
                        />
                      </div>
                      <div class="col-lg-12 mb-3">
                        <h6 class="form-label">Phone Number</h6>
                        <vue-tel-input
                          v-model="userDetails.phone"
                          class="p-2 rounded-3 bg-form"
                          v-bind="bindProps"
                        />
                      </div>
                      <div class="col-lg-12 mt-4">
                        <div
                          class="d-flex justify-content-between align-items-end"
                        >
                          <h6 class="form-label">House Address</h6>
                        </div>
                        <input
                          type="text"
                          placeholder="Enter your current house address"
                          class="form-control p-3 bg-form"
                          v-model="userDetails.address"
                        />
                      </div>
                      <div class="col-lg-12 mt-4">
                        <div
                          class="d-flex justify-content-between align-items-end"
                        >
                          <h6 class="form-label">Date of Birth</h6>
                        </div>
                        <input
                          type="date"
                          placeholder="02/05/2018"
                          class="form-control p-3 bg-form"
                          v-model="userDetails.birthday"
                        />
                      </div>

                      <div class="col-lg-12 my-4">
                        <h6 class="form-label">Profile Picture</h6>
                        <input
                          type="file"
                          ref="fileInput"
                          class="form-control p-3 bg-form"
                          @change="handleFileChange"
                        />
                      </div>
                      <div class="col-12 mt-5">
                        <p>
                          <button
                            class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                            type="submit"
                          >
                            <span>Update Profile</span>
                            <div>
                              <img
                                src="../../assets/arrow-right.svg"
                                alt=""
                                width="40"
                                height="40"
                                class="img-fluid"
                                v-if="!loading"
                              />
                              <div
                                class="spinner-grow text-white"
                                role="status"
                                v-else-if="loading"
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          </button>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal :show="showProfileUpdated" width="500">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <!-- <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click="showProfileUpdated = false"
            /> -->
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/success.png"
                alt=""
                class="img-fluid"
                width="100"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Profile Information Updated
            </p>
            <p class="fw-light mt-1 text-center text-body-secondary">
              Your profile information have been updated successfully
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3 w-50 mx-auto"
            >
              <button
                class="btn updated btn-lg text-capitalize font-weight-bold w-100"
                @click.prevent="showProfileUpdated = false"
              >
                Close
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>

      <Modal :show="showDeleteModal">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="showDeleteModal = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/signOutPic.png"
                alt=""
                class="img-fluid"
                width="100"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">Delete My Profile</p>
            <p class="fw-light mt-0 text-center">
              Are you sure you want to delete your profile?
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <button
                class="btn signOutBtn text-capitalize font-weight-bold"
                @click.prevent="deleteProfile"
              >
                Yes Delete My Profile
              </button>
              <button
                class="btn updated text-capitalize font-weight-bold ms-0 ms-md-4"
                @click.prevent="showDeleteModal = false"
              >
                No, Keep Me My Profile
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>
    </UserLayout>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import UserLayout from "@/components/Layouts/UserLayout.vue";
import Modal from "@/components/Modal.vue";
import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import axios from "@/Plugins/axios";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";

import { useAuthStore } from "@/stores/auth";

const store = useAuthStore();

const user = store?.user;


const testimonies = ref(user?.answered_prayers);

// import BulletList from "@/components/BulletList.vue"

const phone = ref(null);

const showEdit = ref(false);

function showEditForm() {
  showEdit.value = true;
}

const loading = ref(false);
const loading2 = ref(false);

const showProfileUpdated = ref(false);
const showDeleteModal = ref(false);

const userDetails = ref({});

const prayerRequests = ref("")
const answeredPrayers = ref("")

function getUserProfile() {
  loading2.value = true;
  axios
    .get(`/me`)
    .then((response) => {
      userDetails.value = response.data.data;
      loading2.value = false;
       prayerRequests.value = userDetails.value?.prayer_requests;
       answeredPrayers.value = userDetails.value?.answered_prayers;
    })
    .catch((error) => {
      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading2.value = false;
    });
}


function updateProfile() {
  loading.value = true;
  const updatedForm = {
    ...userDetails.value,
    _method: "PUT",
  };
  axios
    .post(`/me/update`, updatedForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      showProfileUpdated.value = true;
      loading.value = false;
      getUserProfile();
      store.setUser();
    })
    .catch((error) => {
      loading.value = false;

      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

function deleteProfile() {
  axios
    .delete(`/me/delete`)
    .then((response) => {
      utils.showToast("Success", "success");
    })
    .catch((error) => {
      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

const bindProps = ref({
  // mode: "international",
  defaultCountry: "NG",
  disabledFetchingCountry: false,
  disabled: false,
  // disabledFormatting: false,
  placeholder: "Enter your Mobile number",
  required: true,
  enabledCountryCode: false,
  enabledFlags: true,
  validCharactersOnly: true,
  // preferredCountries: ["AU", "BR"],
  onlyCountries: [],
  ignoredCountries: [],
  autocomplete: "on",
  // name: "telephone",
  maxLen: 25,
  wrapperClasses: "",
  inputClasses: "",
  // dropdownOptions: {
  //   disabledDialCode: false,
  // },
  inputOptions: {
    showDialCode: true,
  },
});

const fileInput = ref(null);

function handleFileChange(event) {
  const file = event.target.files[0];
  if (file) {
    userDetails.value.photo = file;
  }
}

onMounted(() => {
  getUserProfile();
});
</script>

<style scoped>
:deep(.vti__input) {
  background-color: rgba(245, 251, 252, 1) !important;
}
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.btn-accent1 {
  background-color: rgba(234, 241, 255, 1);
  color: rgba(24, 41, 101, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}

.bg-accent1 {
  background-color: #fdfdec !important;
}
.bg-accent2 {
  background-color: #e4fde8 !important;
}
.bg-accent3 {
  background-color: #ecefff !important;
}

.py-6 {
  padding: 6rem 0;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text {
  font-weight: 900;
  font-size: 2.3rem;
}
.header-text2 {
  font-weight: 800;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .signInBtn {
    background-color: rgba(10, 64, 194, 1) !important;
    font-weight: 600 !important;
    border: none;
    color: #fff !important;
    font-size: 1rem !important;
    padding: 1rem 1rem;
    border-radius: 9px;
  }
}

.b-color {
  border: 1px solid #ddedff !important;
}

.bg-form {
  background: rgba(245, 251, 252, 1) !important;
}

.btn-accent {
  background-color: rgba(255, 225, 225, 1) !important;
  color: rgba(215, 9, 9, 1);
  font-weight: 400 !important;
  border: none;
  font-size: 1rem !important;
  padding: 0.5rem 1.2rem;
  border-radius: 9px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 0.6rem 1rem;
  border-radius: 9px;
}

.signOutBtn {
  background-color: rgba(242, 76, 53, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
</style>
