<template>
  <div>
    <AdminLayout Title="Roles and Permissions" :isRequired="false">
      <div class="container">
        <TableSkeletonLoader class="pt-6" v-if="loading" />

        <div v-else>
          <div class="row d-flex justify-content-end pt-6 mb-3">
            <div class="col-12 d-flex justify-content-end">
              <button
                @click.prevent="showAddNewUser = true"
                class="loggedInBtn2 shadow-sm text-decoration-none"
              >
                Add New User
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="row bg-white py-3 px-1 rounded-2 b-color">
                <div class="col-12">
                  <div
                    class="card p-2 border-0 bg-card1 d-flex justify-content-between flex-column"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p class="text-body-secondary">Inactive Admin</p>
                      <img
                        src="../../assets/inactive-admin.png"
                        class="img-fluid d-flex align-self-start"
                        width="50"
                      />
                    </div>
                    <div class="d-flex">
                      <h5 class="mt-3 fw-semibold">0</h5>
                    </div>
                  </div>
                  <div
                    class="card p-2 border-0 bg-card2 d-flex justify-content-between flex-column mt-2"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p class="text-body-secondary">Active Admin</p>
                      <img
                        src="../../assets/active-admin.png"
                        class="img-fluid d-flex align-self-start"
                        width="50"
                      />
                    </div>
                    <div class="d-flex">
                      <h5 class="mt-3 fw-semibold">{{ users.length }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-8">
              <div class="bg-white p-2 rounded-2 b-color">
                <div class="row align-items-center">
                  <div class="col-12">
                    <input
                      type="text"
                      placeholder="Enter to search"
                      class="form-control p-2 ms-0 w-100"
                    />
                  </div>
                  <!-- <div class="col-12 col-lg-4">
                    <div class="d-flex  p-1 mb-1">
                      <button class="m-1 btn-accent1 p-1">All</button>
                      <button class="m-1 btn-accent2 p-1" href="#">Admin</button>
                      <button class="m-1 btn-accent2 p-1" href="#">
                       Reviewer
                      </button>
                      <button class="m-1 btn-accent2 p-1" href="#">
                       Treasurer
                      </button>
                    
                    </div>
                  </div> -->
                </div>
              </div>

              <div class="mt-4">
                <div
                  class="card text-center p-3 border-0 b-color"
                  v-if="users.length == 0"
                >
                  No Users
                </div>
                <div v-for="(user, index) in users" :key="user?.id">
                  <router-link
                    :to="{ name: 'AdminRoleDetail', params: { id: user?.id } }"
                    class="text-decoration-none text-dark"
                  >
                    <div
                      class="bg-white p-3 b-color rounded-3 d-lg-flex justify-content-between align-items-center mb-2"
                    >
                      <div class="d-flex align-items-center">
                        <p
                          class="fw-semibold rounded-circle p-2"
                          :class="[customClasses[index % customClasses.length]]"
                        >
                          {{ user?.first_name.charAt(0)
                          }}{{ user?.last_name.charAt(0) }}
                        </p>
                        <p class="ms-2">
                          {{ user?.first_name }} {{ user?.last_name }}
                        </p>
                      </div>
                      <p
                        class="text-body-secondary align-self-end text-capitalize p-2 rounded-3"
                      >
                        {{ formatWord(user?.user_type) ?? "" }}
                      </p>
                      <p class="fw-semilight align-self-center">
                        Joined {{ getFormattedDate(user?.created_at) ?? "" }}
                      </p>
                    </div>
                  </router-link>
                </div>

                <nav aria-label="Page navigation" v-if="links.length != 0">
                  <ul class="pagination justify-content-end">
                    <!-- Previous button -->
                    <li
                      class="page-item"
                      :class="{ disabled: !links[0]?.url }"
                      @click="changePage(currentPage - 1)"
                    >
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>

                    <!-- Page links -->
                    <li
                      class="page-item"
                      v-for="link in links.slice(1, -1)"
                      :key="link.label"
                      :class="{ active: link?.active }"
                      @click="changePageFromUrl(link?.url)"
                    >
                      <a class="page-link" href="#">{{ link?.label }}</a>
                    </li>

                    <!-- Next button -->
                    <li
                      class="page-item"
                      :class="{ disabled: !links[links.length - 1]?.url }"
                      @click="changePage(currentPage + 1)"
                    >
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal :show="showAddNewUser">
        <template #content>
          <img
            src="../../assets/close-circle.png"
            alt=""
            class="img-fluid position-absolute"
            style="top: 0; right: 0; cursor: pointer"
            @click.prevent="closeForm"
          />
          <form
            action=""
            class="border-1 rounded-2 p-3"
            style="overflow-y: scroll"
          >
            <h3 class="header-text2">Add New User</h3>
            <div class="row">
              <div class="col-12">
                <p class="form-label mt-3">First Name</p>
                <input
                  type="text"
                  required
                  class="p-2 form-control"
                  placeholder="Enter your first name"
                  v-model="adminUserForm.first_name"
                />
                <FormError :errorMessage="formErrors.first_name" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Last Name</p>
                <input
                  type="text"
                  required
                  class="p-2 form-control"
                  v-model="adminUserForm.last_name"
                  placeholder="Enter your last Name"
                />
                <FormError :errorMessage="formErrors.last_name" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Email</p>
                <input
                  type="email"
                  required
                  v-model="adminUserForm.email"
                  class="p-2 form-control"
                  placeholder="Enter your email address"
                />
                <FormError :errorMessage="formErrors.email" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Phone Number</p>
                <input
                  type="text"
                  required
                  v-model="adminUserForm.phone"
                  class="p-2 form-control"
                  placeholder="Enter your current phone number"
                />
                <FormError :errorMessage="formErrors.phone" />
              </div>

              <div class="col-12">
                <p class="form-label mt-3">User Type</p>
                <multiselect
                  v-model="selectedUserType"
                  :options="userTypes"
                  label="name"
                  track-by="key"
                  value-key="key"
                  :searchable="true"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :show-no-results="false"
                >
                  <template #noResult>
                    <span
                      >Oops! No User found. Consider changing your search
                    </span>
                  </template>
                </multiselect>
                <FormError :errorMessage="formErrors.status" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Password</p>
                <div class="input-group">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="********"
                    class="form-control p-2 animated-placeholder"
                    v-model="adminUserForm.password"
                    required
                  />
                  <span
                    class="input-group-text"
                    @click="togglePasswordVisibility"
                  >
                    <i
                      :class="showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"
                    ></i>
                  </span>
                </div>
                <FormError :errorMessage="formErrors.password" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Password Confirmation</p>
                <div class="input-group">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="********"
                    class="form-control p-2 animated-placeholder"
                    required
                    v-model="adminUserForm.password_confirmation"
                  />
                  <span
                    class="input-group-text"
                    @click="togglePasswordVisibility"
                  >
                    <i
                      :class="showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"
                    ></i>
                  </span>
                </div>
                <FormError :errorMessage="formErrors.password_confirmation" />
              </div>

              <div class="col-12 mt-4">
                <p class="">
                  <button
                    class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                    @click.prevent="postAdminUser"
                  >
                    <span>Invite User</span>
                    <div>
                      <img
                        src="../../assets/arrow-right.svg"
                        alt=""
                        width="40"
                        height="40"
                        class="img-fluid"
                        v-if="!loading2"
                      />
                      <div
                        class="spinner-grow text-white"
                        role="status"
                        v-else-if="loading2"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </button>
                </p>
              </div>
            </div>
          </form>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";
import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

import FormError from "@/components/FormError.vue";

import Multiselect from "vue-multiselect";

const showAddNewUser = ref(false);
const showPassword = ref(false);

const currentPage = ref(1);
const totalPages = ref(0);
const paginator = ref({});
const links = ref([]);

const customClasses = ref(["bg1", "bg3", "bg4", "bg5", "bg6", "bg7"]);

const formErrors = ref({});

const changePageFromUrl = (url) => {
  if (url) {
    const urlParams = new URL(url).searchParams;
    const page = urlParams.get("page");
    initPrayerRequest(parseInt(page, 10));
  }
};

const changePage = (page) => {
  if (page > 0 && page <= totalPages.value) {
    initPrayerRequest(page);
  }
};

const adminUserForm = ref({
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  password: "",
  password_confirmation: "",
  user_type: "",
});

const selectedUserType = ref("");

watch(selectedUserType, (newVal, oldVal) => {
  adminUserForm.value.user_type = newVal?.key;
});

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

function closeForm() {
  showAddNewUser.value = false;
  formErrors.value = false;
}

const loading = ref(false);
const loading2 = ref(false);

const users = ref([]);

function formatWord(word = "") {
  return word.replace(/_/g, " ");
}

const userTypes = ref([
  { name: "Super Admin", key: "super_admin" },
  { name: "Member", key: "member" },
]);

function postAdminUser() {
  loading2.value = true;
  axios
    .post(`/admin/users`, adminUserForm.value)
    .then((response) => {
      utils.showToast("User successfully added", "success");
      showAddNewUser.value = false;
      adminUserForm.value = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
        user_type: "",
      };
      loading2.value = false;
      getUsers();
    })
    .catch((error) => {
      formErrors.value = catchAxiosExceptions(error).formErrors;
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading2.value = false;
    });
}

function getUsers(page = 1) {
  loading.value = true;
  axios
    .get(`/admin/users?page=${page}`)
    .then((response) => {
      paginator.value = response.data.meta;
      links.value = paginator.value.links;
      // gettingNextPage.value = false;
      currentPage.value = paginator.value.current_page;
      totalPages.value = paginator.value.total;

      users.value = response.data.data;
      loading.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading.value = false;
    });
}
onMounted(() => {
  getUsers(currentPage.value);
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
/* * {
  outline: 1px solid red;
} */
/* .initials {
  width: 40px;
  height: 60px;
  border-radius: 50%;
  padding: 1.2rem 1rem 0.3rem 1rem;
  color: #16c046;
} */

/* :deep(.multiselect__tags),
:deep(.multiselect__input),
:deep(.multiselect__single) {
  background-color: #f5fbfc !important;
} */

:deep(.multiselect__element) {
  background-color: transparent !important;
}

:deep(.multiselect__option--highlight) {
  background-color: blue !important;
  color: #fff !important;
}
.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.btn-accent1 {
  background-color: rgba(234, 241, 255, 1);
  color: rgba(24, 41, 101, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}
.btn-accent2 {
  background-color: rgba(246, 246, 246, 1);
  color: rgba(96, 96, 96, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}
.loggedInBtn2 {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.filter {
  border: 1px solid rgba(218, 229, 239, 1);
  backdrop-filter: blur(20px);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.02);
  border-radius: 9px;
}
.pt-6 {
  padding-top: 6rem !important;
}

.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}

.bg-white {
  background-color: #fff;
}

.bg-card1 {
  background-color: #e9f4ff;
}
/* .bg-card2 {
  background-color: #fdf2ff;
} */
.bg-card3 {
  background-color: #fff9e2;
}
.bg-card2 {
  background-color: #e1ffea !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}

.bg1 {
  background-color: #e0fdeb !important;
  color: #65c453 !important;
}
.bg2 {
  background-color: #e0fdeb !important;
  color: #65c453 !important;
}
.bg3 {
  background-color: #e1faff !important;
  color: #3184c1 !important;
}
.bg4 {
  background-color: #fef4e0 !important;
  color: #f39e32 !important;
}
.bg5 {
  background-color: #fef4e0 !important;
  color: #f39e32 !important;
}
.bg6 {
  background-color: #f9f3ff !important;
  color: #b14bd0 !important;
}
.bg7 {
  background-color: #fdf1f0 !important;
  color: #e83e33 !important;
}
</style>
