<template>
  <div>
    <UserLayout>

      <section class="bg-signIn d-flex justify-content-center">
        <div class="container">
          <div class="py-6 position-relative">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-lg-7">
                <div>
                  <router-link
                    :to="{ name: 'UserDashboard' }"
                    class="text-decoration-none text-body-secondary ms-2"
                  >
                    <img
                      src="../../assets/back.png"
                      alt=""
                      class="img-fluid me-2 mb-1"
                    />Back</router-link
                  >
                </div>
                <h3 class="header-text mt-3">Contact Information</h3>
              </div>
  
              <div class="col-12 col-lg-7 mt-3">
                <div class="card b-color p-5">
                  <div class="d-flex align-items-center flex-column">
                    <img
                      src="../../assets/contactInfo.png"
                      alt=""
                      class="img-fluid mb-3"
                      width="200"
                    />
                   
                  </div>
  
                  <div class="mt-3 mb-3 d-flex">
                    <img src="../../assets/callIcon.svg" alt="" class="img-fluid mb-2 me-2">
                    <div>
  
                      <h5 class="fw-bold mb-0">Call Support</h5>
                      <p class="text-body-secondary mb-0">Monday to Sunday 10:30am- 6:00pm</p>
                    </div>
                  </div>
                  <div class="mt-1 mb-3 d-flex">
                     <img src="../../assets/sms-star.svg" alt="" class="img-fluid mb-2 me-2">
                    <div>
                      <h5 class="fw-bold mb-0">Mail</h5>
                      <p class="text-body-secondary mb-0">support@propheticprayers.com</p>
                    </div>
                   
                  </div>

                  <div class="mt-1 mb-3">
                    <div>
                     <h5 class="form-label mb-3 fw-semibold"><img src="../../assets/sms-star.svg" alt="" class="img-fluid mb-2 me-2">Message</h5>
                    <textarea name="" id="" cols="30" rows="10" class="form-control"></textarea>
                      <div class="col-12 mt-5">
                    <p>
                      <button
                        class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                        type="submit"
                        @click.prevent="postMessage"
                      >
                        <span>Submit Message</span>
                        <div>
                          <img
                            src="../../assets/arrow-right.svg"
                            alt=""
                            width="40"
                            height="40"
                            class="img-fluid"
                            v-if="!loading"
                          />
                          <div
                            class="spinner-grow text-white"
                            role="status"
                            v-else-if="loading"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </button>
                    </p>
                  </div>
                    </div>
                   
                  </div>
               
  
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>

  
  </div>
</template>

<script setup>
import {ref} from "vue"
import UserLayout from "@/components/Layouts/UserLayout.vue"
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

const loading = ref(false);


function postMessage() {
  loading.value = true;
  
  axios
    .post(`/contact-message`)
    .then((response) => {
      loading.value = false;
      utils.showToast("Success", "success");

    })
    .catch((error) => {
      loading.value = false;
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}


</script>

<style scoped>
/* *{
  outline: 1px solid red;
} */
.bg-dropbox {
  background-color: rgba(245, 251, 252, 1);
}
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.py-6 {
  padding: 7rem 0;
}

.header-text {
  font-weight: 700;
  font-size: 2rem;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.8rem;
}
.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
</style>

