<template>
  <span class="text-danger" v-if="errorMessage">{{ errorMessage[0] }}</span>
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: Array,
      default: [],
    },
  },
};
</script>
