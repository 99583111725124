<template>
  <div>
    <AdminLayout Title="Recordings" :isRequired="false">
      <TableSkeletonLoader class="mt-3 pt-6" v-if="loading" />

      <div class="container" v-else>
        <div class="row d-flex justify-content-end pt-6 mb-3">
          <div class="col-12 d-flex justify-content-end">
            <button
              @click="showAddRecordings = true"
              class="loggedInBtn2 shadow-sm text-decoration-none"
            >
              Add New Recording
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-3">
            <div class="row bg-white py-3 px-1 rounded-2 b-color">
              <div class="col-12">
                <div
                  class="card p-3 border-0 bg-card2 mt-1 d-flex justify-content-between flex-column"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p class="text-body-secondary">Total Recordings</p>
                    <img
                      src="../../assets/recordingPageIcon.png"
                      class="img-fluid d-flex align-self-start"
                      width="50"
                    />
                  </div>
                  <div class="d-flex">
                    <h5 class="mt-3 fw-semibold">{{ recordings.length }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-9">
            <div class="bg-white p-2 rounded-2 b-color">
              <div class="row d-flex align-items-center">
                <div class="col-12">
                  <input
                    type="text"
                    placeholder="Enter to search"
                    class="form-control p-2 ms-0"
                  />
                </div>
                <!-- <div class="col-12 col-lg-6 d-flex justify-content-end">
                  <div class="d-flex p-1 mb-1">
                    <button class="m-1 btn-accent1 p-1">All</button>
                    <button class="m-1 btn-accent2 p-1" href="#">Admin</button>
                    <button class="m-1 btn-accent2 p-1" href="#">
                      Reviewer
                    </button>
                    <button class="m-1 btn-accent2 p-1" href="#">
                      Treasurer
                    </button>
                  </div>
                </div> -->
              </div>
            </div>

            <div class="card p-2 b-color mt-3">
              <div class="my-3 mt-0" v-if="recordings.length == 0">
                No Recordings
              </div>
              <div class="row" v-else>
                <div class="col-12">
                  <div
                    class="my-3 mt-0"
                    v-for="recording in recordings"
                    :key="recording.id"
                  >
                    <div class="row g-0">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-md-3">
                            <img
                              src="../../assets/woman-on-the-altar.png"
                              class="img-fluid w-100 h-100"
                              alt="..."
                            />
                          </div>
                          <div class="col-md-9">
                            <div
                              class="card-body p-2 d-lg-flex d-block justify-content-between align-items-center mt-5"
                            >
                              <div>
                                <h5
                                  class="mt-2"
                                  style="font-size: 1.4rem; font-weight: 600"
                                >
                                  {{ recording?.title }}
                                </h5>
                                <div class="d-flex">
                                  <div class="d-flex align-items-center ms-2">
                                    <img
                                      src="../../assets/calendar-2b.svg"
                                      alt=""
                                      class="img-fluid mb-3 me-1"
                                      width="20"
                                      height="20"
                                    />
                                    <p>
                                      {{
                                        getFormattedDate(recording?.created_at)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="mt-5">
                                <router-link
                                  :to="{
                                    name: 'AdminRecordingDetail',
                                    params: { id: recording?.id },
                                  }"
                                  class="text-decoration-none text-dark updated"
                                  >Details</router-link
                                >
                                <button
                                  @click.prevent="listenToRecording(recording?.link)"
                                  target="_blank"
                                  class="btn signInBtn ms-3"
                                >
                                  Listen To Recordings
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal :show="showAddRecordings">
        <template #content>
          <img
            src="../../assets/close-circle.png"
            alt=""
            class="img-fluid position-absolute"
            style="top: 0; right: 0; cursor: pointer"
            @click.prevent="showAddRecordings = false"
          />
          <form
            action=""
            class="border-1 rounded-2 p-3"
            style="overflow-y: scroll"
          >
            <h3 class="header-text2">Add New Recording</h3>
            <div class="row">
              <div class="col-12">
                <p class="form-label mt-3">Title</p>
                <input
                  type="text"
                  required
                  class="p-2 form-control"
                  placeholder="Enter Recording title"
                  v-model="recordingForm.title"
                />
                <FormError :errorMessage="formErrors.title" />
              </div>

              <div class="col-12">
                <p class="form-label mt-3">Link</p>
                <input
                  placeholder="example: youtube link"
                  v-model="recordingForm.link"
                  class="form-control"
                />
                <FormError :errorMessage="formErrors.link" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Description</p>
                <textarea
                  name=""
                  id=""
                  v-model="recordingForm.description"
                  class="form-control"
                ></textarea>
                <FormError :errorMessage="formErrors.description" />
              </div>

              <div class="col-12 mt-4">
                <p class="">
                  <button
                    class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                    @click.prevent="postRecordings"
                  >
                    <span>Add Recordings</span>
                    <div>
                      <img
                        src="../../assets/arrow-right.svg"
                        alt=""
                        width="40"
                        height="40"
                        class="img-fluid"
                        v-if="!loading2"
                      />
                      <div
                        class="spinner-grow text-white"
                        role="status"
                        v-else-if="loading2"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </button>
                </p>
              </div>
            </div>
          </form>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>

      <Modal :show="showSubmittedSuccessfully">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/success.png"
                alt=""
                class="img-fluid"
                width="100"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">Recording Uploaded</p>
            <p class="fw-light mt-1 text-center text-body-secondary">
              Successfully Uploaded recordings
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3 w-50 mx-auto"
            >
              <button
                class="btn updated btn-lg text-capitalize font-weight-bold w-100 ms-3"
                @click="showSubmittedSuccessfully = false"
              >
                Close
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>

      <Modal :show="showRecordings" width="650">
        <template #content>
        
          <div class="position-relative rounded-pill mb-0 p-0">
            <img
              src="../../assets/close-circle.png"
              class="position-absolute"
              style="right: 0.8rem; top: 0.6rem"
              alt=""
              @click="showRecordings = false"
            />
          </div>
          <iframe
            width="560"
            height="315"
            :src="`https://www.youtube.com/embed/${currentLink}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            class="mt-5"
          ></iframe>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";
import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

import FormError from "@/components/FormError.vue";

const loading = ref(false);
const loading2 = ref(false);

const showSubmittedSuccessfully = ref(false);

const showAddRecordings = ref(false);
const showRecordings = ref(false);

const recordings = ref([]);

const currentLink = ref(null);

watch(currentLink, (newVal, oldVal) => {
  currentLink.value = newVal;
});

function getYouTubeVideoId(url) {
  // Remove the protocol (http:// or https://)
  let cleanUrl = url?.replace("https://", "");

  // Split the URL at the slash after youtu.be/
  let parts = cleanUrl?.split("/");

  // Get the second element (index 1), which contains the video ID
  let videoId = parts[1] ?? "";
  // Return the video ID
  return videoId;
}

const formErrors = ref({});

const recordingForm = ref({
  title: "",
  link: null,
  description: null,
});

function listenToRecording(link) {
  currentLink.value = getYouTubeVideoId(link) ?? "";
  showRecordings.value = true;
}

function postRecordings() {
  loading2.value = true;
  axios
    .post(`/admin/recordings`, recordingForm.value)
    .then((response) => {
      showAddRecordings.value = false;
      showSubmittedSuccessfully.value = true;
      loading2.value = false;
      getRecordings();
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      formErrors.value = catchAxiosExceptions(error).formErrors;
      loading2.value = false;
    });
}

function getRecordings() {
  loading.value = true;
  axios
    .get(`/recordings`)
    .then((response) => {
      recordings.value = response.data.data;
      loading.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading.value = false;
    });
}
onMounted(() => {
  getRecordings();
});
</script>
<style scoped>
/* *{
  outline: 1px solid red;
} */
.loggedInBtn2 {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.btn-accent1 {
  background-color: rgba(234, 241, 255, 1);
  color: rgba(24, 41, 101, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}
.btn-accent2 {
  background-color: rgba(246, 246, 246, 1);
  color: rgba(96, 96, 96, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 0.5rem 1rem;
  border-radius: 9px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 400 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 0.5rem 0.7rem;
  border-radius: 9px;
}
.filter {
  border: 1px solid rgba(218, 229, 239, 1);
  backdrop-filter: blur(20px);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.02);
  border-radius: 9px;
}
.pt-6 {
  padding-top: 6rem !important;
}

.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}

.bg-white {
  background-color: #fff;
}

.bg-card1 {
  background-color: #e4f5ff !important;
}
.bg-card2 {
  background-color: #e1ffea !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}
</style>
