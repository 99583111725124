<template>
  <div>
    <UserLayout>
      <section
        class="bg-signIn d-flex justify-content-center align-items-center"
      >
        <div class="container">
          <div class="py-6 position-relative">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-lg-7">
                <div class="mt-3 mb-3">
                  <div>
                    <router-link
                      :to="{ name: 'UserDashboardPrayerRequest' }"
                      class="text-decoration-none text-body-secondary ms-2"
                    >
                      <img
                        src="../../assets/back.png"
                        alt=""
                        class="img-fluid me-2 mb-1"
                      />Back</router-link
                    >
                  </div>
                </div>
                <div class="card p-3 shadow-sm">
                  <form
                    action=""
                    class="border border-1 rounded-2 p-3"
                    @submit.prevent="submitPrayer"
                  >
                    <div class="row">
                      <div class="col-12">
                        <h3 class="header-text mt-3">Add a New Prayer Point</h3>
                        <p class="mt-0">What is this prayer for?</p>
                      </div>
                      <div class="col-12 mb-3">
                        <MultiSelectButtons
                          v-model="selectedOptions"
                          :options="myOptions"
                          @add-new-prayer-request="showAddMore = true"
                        />
                        <!-- {{ selectedOptions }} -->

                         <FormError :errorMessage="formErrors.subject"/>

                      </div>
                      <div class="col-12">
                        <h6 class="form-label">Prayer Point</h6>
                        <textarea
                          placeholder="Enter your prayer point here"
                          class="form-control p-3"
                          v-model="prayerForm.text"
                        />
                         <FormError :errorMessage="formErrors.text"/>
                      </div>
                      <div class="col-12 mt-4 mb-3">
                        <h6 class="form-label">Prayer Point Deadline</h6>
                        <input
                          type="date"
                          placeholder="Select Project Dealine"
                          class="form-control p-3"
                          v-model="prayerForm.deadline"
                        />
                         <FormError :errorMessage="formErrors.deadline"/>
                      </div>

                      <div class="col-12 mt-5">
                        <p>
                          <button
                            class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                            type="submit"
                          >
                            <span>Submit Prayer Point</span>
                            <div>
                              <img
                                src="../../assets/arrow-right.svg"
                                alt=""
                                width="40"
                                height="40"
                                class="img-fluid"
                                v-if="!loading"
                              />
                              <div
                                class="spinner-grow text-white"
                                role="status"
                                v-else-if="loading"
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          </button>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal :show="showAddMore" width="650">
        <template #content>
          <div class="d-flex justify-content-center flex-column">
            <div class="position-relative">
              <img
                src="../../assets/close-circle.png"
                alt=""
                class="img-fluid position-absolute"
                style="top: 0; right: 0; cursor: pointer"
                @click.prevent="showAddMore = false"
              />
            </div>
            <form action="">
              <div class="mt-3 mb-3">
                <h3 class="mb-2">Reason For Prayer</h3>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  class="form-control"
                  placeholder="Kindly State what this prayer is for"
                  v-model="moreReason"
                  required
                ></textarea>
              </div>

              <p>
                <button
                  class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                  @click.prevent="handleAddNew"
                >
                  <span>Add Reason</span>
                  <div>
                    <img
                      src="../../assets/arrow-right.svg"
                      alt=""
                      width="40"
                      height="40"
                      class="img-fluid"
                      v-if="!loading"
                    />
                    <div
                      class="spinner-grow text-white"
                      role="status"
                      v-else-if="loading"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </button>
              </p>
            </form>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>

      <Modal :show="showSubmittedSuccessfully" width="550">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click="showSubmittedSuccessfully = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/requestSubmitted.png"
                alt=""
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Prayer Request Submitted
            </p>
            <p class="fw-light mt-1 text-center text-body-secondary">
              We have received your prayer requests and will commence supporting
              with you with our prayers
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <router-link
                class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100"
                :to="{ name: 'UserDashboardPrayerRequest' }"
              >
                Back to Home
              </router-link>
              <button
                class="btn updated btn-lg text-capitalize font-weight-bold w-100 ms-3"
                @click.prevent="sowASeed"
              >
                Sow A Seed
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </UserLayout>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import MultiSelectButtons from "@/components/MultiSelectButtons.vue";
import Modal from "@/components/Modal.vue";
import UserLayout from "@/components/Layouts/UserLayout.vue";

import axios from "@/Plugins/axios";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";
import FormError from "@/components/FormError.vue"


const selectedOptions = ref({});
const loading = ref(false);

const router = useRouter();

const showAddMore = ref(false);

const showSubmittedSuccessfully = ref(false);

const moreReason = ref("");

const formErrors = ref({})

watch(selectedOptions, (newValue, oldValue) => {
  selectedOptions.value = newValue;
  prayerForm.value.subject = newValue?.label;
  console.log(prayerForm.value.subject);
});
const myOptions = ref([
  { value: "option1", label: "Visa Approval" },
  { value: "option2", label: "Fruit of the Womb" },
  { value: "option3", label: "Financial Breakthrough" },
  { value: "option4", label: "Job Appointment" },
  { value: "option5", label: "Job Promotion" },
  { value: "option6", label: "Life Partner" },
  { value: "option7", label: "Exam Success" },
]);

const prayerForm = ref({
  subject: "",
  text: "",
  deadline: "",
});

function handleAddNew() {
  myOptions.value.push({
    value: `option${myOptions.value.length + 1}`,
    label: moreReason.value,
  });
  moreReason.value = "";
  showAddMore.value = false;
}

function submitPrayer() {
   formErrors.value = {}
  loading.value = true;
  axios
    .post(`/prayer-points`, prayerForm.value)
    .then((response) => {
      loading.value = false;
      showSubmittedSuccessfully.value = true;
      // utils.showToast("Success", "success");
      prayerForm.value.deadline = "";
      prayerForm.value.subject = "";
      prayerForm.value.text = "";
      selectedOptions.value = [];
    })
    .catch((error) => {
      loading.value = false;
      catchAxiosExceptions(error);
      formErrors.value = catchAxiosExceptions(error).formErrors
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

function sowASeed() {
  showSubmittedSuccessfully.value = false;
  router.push({ name: "UserSowASeed" });
}
</script>

<style scoped>
/* *{
  outline: 1px solid red;
} */
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.py-6 {
  padding: 6rem 0;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}
.header-text {
  font-weight: 700;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .signInBtn {
    background-color: rgba(10, 64, 194, 1) !important;
    font-weight: 600 !important;
    border: none;
    color: #fff !important;
    font-size: 1rem !important;
    padding: 1rem 1.5rem;
    border-radius: 9px;
  }
  .boy-singing {
    display: none;
  }

  .header-text {
  font-weight: 700;
  font-size: 1.5rem;
}
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
</style>
