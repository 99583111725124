import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

export default {
  showToast: (message , type = "success") => {
    const $toast = useToast();

    if (!["success", "info", "warning", "error"].includes(type)) {
      type = "success";
    }

    let toastOptions = {
      position: "top-right",
      queue: true,
    };

    //
    if (type == "success") {
      $toast.success(message, toastOptions);
    } else if (type == "info") {
      $toast.info(message, toastOptions);
    } else if (type == "warning") {
      $toast.warning(message, toastOptions);
    } else {
      $toast.error(message, toastOptions);
    }
  },


    getPrayerPointEndpoint: (userType) => {
    const baseEndpoint = `/prayer-points`;
    let isAdmin = userType == `super_admin`;
    return isAdmin
      ? `/admin/prayer-points`
      : baseEndpoint;
  },
};
