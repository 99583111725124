<template>
  <div class="btn-group d-flex flex-wrap row">
    <div class="col-12">
      <button 
       v-for="(option, index) in options" :key="index"
        @click="toggleSelection(option)"
        type="button"
        class="btn btn-outline-secondary m-2 shadow-sm rounded-3 btn-sm"
        :class="{ 'active': isSelected(option) }"
      >
        {{ option.label }}
        <span v-if="isSelected(option)" class="ms-1"></span>
      </button>
      <button @click.prevent="$emit('add-new-prayer-request')" class="btn btn-outline-secondary btn-sm shadow-sm rounded-3">Add New</button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['add-new-prayer-request','update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Array,
      required: true
    }
  },

  computed: {
    selectedOption: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },

  methods: {
    toggleSelection(option) {
      if (this.selectedOption.value === option.value) {
        this.selectedOption = {}; // Deselect the option
      } else {
        this.selectedOption = option; // Select the new option
      }
    },

    isSelected(option) {
      return this.selectedOption.value === option.value;
    },

    triggerSignOut() {
      this.$emit('add-new-prayer-request');
    },
  }
}
</script>

<style scoped>
.w-20 {
  width: 25% !important;
}

.btn-group button.active {
  background-color: rgba(10, 64, 194, 1);
  color: white;
  border: none;
  padding: 0.4rem 0.2rem;
  border-radius: 5px;
}

.btn-group button {
  background-color: rgba(240, 249, 255, 1);
  color: #131313;
  border: none;
  padding: 0.4rem 0.2rem;
  border-radius: 5px;
}
</style>
