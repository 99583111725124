export const catchAxiosExceptions = (error) => {
  let errorResponse = {
    formErrors: {},
    status: null,
    msg: "",
  };

  if (error.response) {
    errorResponse.formErrors = error.response.data.errors
      ? error.response.data.errors
      : {};

    // the toast message
    errorResponse.msg = error.response.data.message;
  }

  return errorResponse;
};
