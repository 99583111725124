<template>
  <div class="card-skeleton" style="max-height: 60vh">
    <content-loader
    viewBox="0 0 200 260"
    :speed="5"
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <!-- <circle cx="31" cy="31" r="15" />  -->
    <rect x="2" y="18" rx="2" ry="2" width="240" height="30" /> 
    <rect x="2" y="54" rx="2" ry="2" width="240" height="30" /> 
    <!-- <rect x="2" y="80" rx="2" ry="2" width="200" height="30" />  -->
    <!-- <rect x="0" y="60" rx="2" ry="2" width="400" height="100" /> -->
  </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  components: {
    ContentLoader
  }
}
</script>
