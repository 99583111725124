<template>
  <div>
    <UserLayout>
      <section class="bg-signIn d-flex justify-content-center">
        <div class="container">
          <div class="py-6 position-relative">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-lg-7">
                <div>
                  <router-link
                    :to="{ name: 'UserAddNewPrayerRequest' }"
                    class="text-decoration-none text-body-secondary ms-2"
                  >
                    <img
                      src="../../assets/back.png"
                      alt=""
                      class="img-fluid me-2 mb-1"
                    />Back</router-link
                  >
                </div>
                <h3 class="header-text mt-3">Sow A Seed</h3>
              </div>
  
              <div class="col-12 col-lg-7 mt-3">
                <div class="card b-color p-5">
                  <div class="d-flex align-items-center flex-column">
                    <img
                      src="../../assets/Bank.png"
                      alt=""
                      class="img-fluid mb-3"
                      width="200"
                    />
                    <h4 class="header-text2 text-center">Make a Transfer</h4>
                    <p class="text-center">
                      Make a bank transfer to the account number below to fund
                      your wallet.
                    </p>
                  </div>
  
                  <div class="mt-3 mb-2">
                    <p class="text-body-secondary mb-0">Bank Name</p>
                    <h5 class="fw-bold mt-0">Wema Bank</h5>
                  </div>
                  <div class="mt-1 mb-2 d-flex justify-content-between">
                    <div>
                      <p class="text-body-secondary mb-0">Account Number</p>
                      <h5 class="fw-bold mt-0">9817651091</h5>
                    </div>
                    <div class="align-self-center">
                      <button
                        @click="copyText"
                        style="
                          background-color: transparent;
                          border: none;
                          outline: none;
                        "
                      >
                        <img
                          src="../../assets/copyToClipboard.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </button>
                      <span v-if="copied" class="text-success">Copied!</span>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-body-secondary mb-0">Account Name</p>
                    <h5 class="fw-bold mt-0">Philip's Aiden Wallet</h5>
                  </div>
  
                  <div class="mt-5">
                    <p>
                      <button
                        class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                        @click.prevent="showUploadReceipts = true"
                      >
                        <span>Upload Payment Receipts</span>
                        <img
                          src="../../assets/arrow-right.svg"
                          alt=""
                          width="40"
                          height="40"
                          class="img-fluid"
                        />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <Modal :show="showUploadReceipts" width="700">
        <template #content>
          <img
            src="../../assets/close-circle.png"
            alt=""
            width="35"
            height="40"
            class="img-fluid position-absolute"
            style="top: 0; right: 0; cursor: pointer"
            @click="showUploadReceipts = false"
          />
          <form
            action=""
            class="border-1 rounded-2 p-3"
            style="overflow-y: scroll"
          >
            <h3 class="header-text2">Upload Payment Receipt</h3>
            <div class="row">
              <div class="col-12 dropzone-container">
                <p class="form-label mt-3">Upload Receipts</p>
                <label
                  for="fileInput"
                  class="dropzone bg-dropbox w-100"
                  @dragover.prevent="dragOver"
                  @dragleave="dragLeave"
                  @drop.prevent="handleDrop"
                >
                  <img src="../../assets/cloud-upload-fill.png" class="img-fluid" />
                  <p>Click to upload Payment Receipts</p>
  
                  <input
                    type="file"
                    id="fileInput"
                    @change="handleFiles"
                    class="d-none"
                  />
                </label>
              </div>
  
              <ol v-if="uploadedFiles.length" class="mt-3 ms-3">
                <li v-for="(file, index) in uploadedFiles" :key="index">
                  {{ file.name }}
                  <button class="btn btn-sm ml-2" @click="removeFile(index)">
                    <img src="../../assets/close-circle.png" alt="" />
                  </button>
                </li>
              </ol>
  
              <div class="col-12 mt-5">
                <p>
                  <button
                    class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                    @click.prevent="successfullySubmitted"
                  >
                    <span>Submit Receipt</span>
                    <img
                      src="../../assets/arrow-right.svg"
                      alt=""
                      width="40"
                      height="40"
                      class="img-fluid"
                    />
                  </button>
                </p>
              </div>
            </div>
          </form>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>
  
      <Modal :show="showSubmittedSuccessfully">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/success.png"
                alt=""
                class="img-fluid"
                width="100"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">Receipt Submitted</p>
            <p class="fw-light mt-1 text-center text-body-secondary">
              Thank you for your payment, may the good Lord reward<br class="d-none d-lg-block"/> and answer your
              prayers!
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3 w-50 mx-auto"
            >
              <button
                class="btn updated btn-lg text-capitalize font-weight-bold w-100 ms-3"
                @click="showSubmittedSuccessfully = false"
              >
                Close
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>

    </UserLayout>
  </div>
</template>

<script setup>
import {ref} from 'vue'
import Modal from "@/components/Modal.vue"
import UserLayout from "@/components/Layouts/UserLayout.vue"

const textToCopy = "9817651091";

// Flag to show feedback after copying
const copied = ref(false);
const showUploadReceipts = ref(false);
const showSubmittedSuccessfully = ref(false);

// Function to copy the text
const copyText = async () => {
  try {
    await navigator.clipboard.writeText(textToCopy);
    copied.value = true;
    // Hide the "Copied!" feedback after 2 seconds
    setTimeout(() => {
      copied.value = false;
    }, 2000);
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
};

// Reactive array to store files
const uploadedFiles = ref([]);

// Handle files selected via input
const handleFiles = (event) => {
  const files = event.target.files;
  processFiles(files);
};

// Handle files dropped into the dropzone
const handleDrop = (event) => {
  const files = event.dataTransfer.files;
  processFiles(files);
};

// Add the files to the uploadedFiles array
const processFiles = (files) => {
  for (const file of files) {
    uploadedFiles.value.push(file); // Store each file in the reactive array
  }
};

function removeFile(index) {
  uploadedFiles.value.splice(index, 1);
}

function successfullySubmitted() {
  showUploadReceipts.value = false;
  showSubmittedSuccessfully.value = true;
}
</script>

<style scoped>
.dropzone-container {
  position: relative;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.bg-dropbox {
  background-color: rgba(245, 251, 252, 1);
}
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.py-6 {
  padding: 7rem 0;
}

.header-text {
  font-weight: 700;
  font-size: 2rem;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.8rem;
}
.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
</style>
