<template>
  <div>
    <AdminLayout Title="Announcement" :isRequired="false">
      <TableSkeletonLoader class="pt-6" v-if="loading"/>
      <div class="container" v-else>
        <div class="row d-flex justify-content-end pt-6 mb-3">
          <div class="col-12 d-flex justify-content-end">
            <button
              @click="showAddRecordings = true"
              class="loggedInBtn2 shadow-sm text-decoration-none"
            >
              Add New Announcement
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="row bg-white py-3 px-1 rounded-2 b-color">
              <div class="col-12">
               
               
                <div
                  class="card p-3 border-0 bg-card1 d-flex justify-content-between flex-column"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p class="text-body-secondary">All Announcement</p>
                    <img
                      src="../../assets/megaphone.png"
                      class="img-fluid d-flex align-self-start"
                      width="50"
                    />
                  </div>
                  <div class="d-flex">
                    <h5 class="mt-3 fw-semibold">{{ announcement.length }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div class="bg-white p-2 rounded-2 b-color">
              <div class="row d-flex align-items-center">
                <div class="col-12">
                  <input
                    type="text"
                    placeholder="Enter to search"
                    class="form-control p-2 ms-0"
                  />
                </div>
                <!-- <div class="col-12 col-lg-4 d-flex justify-content-end">
                  <div class="d-flex p-1 mb-1">
                    <button class="m-1 btn-accent1 p-1">All</button>
                    <button class="m-1 btn-accent2 p-1" href="#">Active</button>
                    <button class="m-1 btn-accent2 p-1" href="#">Closed</button>
                  </div>
                </div> -->
              </div>
            </div>

            <div class="card p-3 b-color mt-3">
              <div class="row">
                <div v-if="announcement.length == 0">
                   No Announcement
                </div>
                <div class="col-12 col-lg-6" v-for="item in announcement" :key="item.id">
                  <!-- <router-link
                    :to="{ name: 'AdminCounsellingDetail', params: {id: session?.id} }"
                    class="text-decoration-none"
                  > -->
                    <div
                      class="position-relative rounded-3 border mt-3"
                      style="cursor: pointer"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center px-2 pt-3 pb-2"
                      >
                        <span class="fw-semibold text-dark"
                          >{{ item?.title }}</span
                        >
                        
                      </div>
                      <hr class="" />
                      <div class="row px-2">
                        <div class="col-12">
                           <p>{{ item?.message }}</p>
                        </div>
                      
                        
                       
                      </div>
                    </div>
                  <!-- </router-link> -->
                </div>
               
              
              </div>
            </div>
          </div>
        </div>
      </div>

       <Modal :show="showAddRecordings">
        <template #content>
          <img
            src="../../assets/close-circle.png"
            alt=""
            class="img-fluid position-absolute"
            style="top: 0; right: 0; cursor: pointer"
            @click.prevent="showAddRecordings = false"
          />
          <form
            action=""
            class="border-1 rounded-2 p-3"
            style="overflow-y: scroll"
          >
            <h3 class="header-text2">Add New Announcement</h3>
            <div class="row">
              <div class="col-12">
                <p class="form-label mt-3">Title</p>
                <input
                  type="text"
                  required
                  class="p-2 form-control"
                  placeholder="Enter title here"
                  v-model="announcementForm.title"
                />
                <FormError :errorMessage="formErrors.title" />
              </div>

            
              <div class="col-12">
                <p class="form-label mt-3">Message</p>
                <textarea
                  name=""
                  id=""
                  v-model="announcementForm.message"
                  class="form-control"
                ></textarea>
                <FormError :errorMessage="formErrors.message" />
              </div>

              <div class="col-12 mt-4">
                <p class="">
                  <button
                    class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                    @click.prevent="postAnnouncement"
                  >
                    <span>Add Announcement</span>
                    <div>
                      <img
                        src="../../assets/arrow-right.svg"
                        alt=""
                        width="40"
                        height="40"
                        class="img-fluid"
                        v-if="!loading2"
                      />
                      <div
                        class="spinner-grow text-white"
                        role="status"
                        v-else-if="loading2"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </button>
                </p>
              </div>
            </div>
          </form>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>

      <Modal :show="showSubmittedSuccessfully">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/success.png"
                alt=""
                class="img-fluid"
                width="100"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">Announcement Uploaded</p>
            <p class="fw-light mt-1 text-center text-body-secondary">
              Successfully added Anouncement
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3 w-50 mx-auto"
            >
              <button
                class="btn updated btn-lg text-capitalize font-weight-bold w-100 ms-3"
                @click="showSubmittedSuccessfully = false"
              >
                Close
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import {ref, onMounted} from "vue";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";

import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";


import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";
import FormError from "@/components/FormError.vue";


const formErrors = ref({})

const announcement = ref([])

const showAddRecordings = ref(false);

const showSubmittedSuccessfully = ref(false)

const loading = ref(false);
const loading2 = ref(false);

const announcementForm = ref({
  title: "",
 
  message: null,
});

function postAnnouncement() {
  loading2.value = true;
  axios
    .post(`/admin/announcements`, announcementForm.value)
    .then((response) => {
      showAddRecordings.value = false;
      showSubmittedSuccessfully.value = true;
      loading2.value = false;
      initAnnouncement();
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      formErrors.value = catchAxiosExceptions(error).formErrors;
      loading2.value = false;
    });
}

async function initAnnouncement() {
  loading.value = true;
  try {
    const _announcementData = await axios.get(`/announcements`);

    announcement.value = _announcementData.data.data
  
    loading.value = false;
  } catch (error) {
    utils.showToast(catchAxiosExceptions(error).msg, "error");
    loading.value = false;
  }
}

onMounted(() => {
  initAnnouncement();
});
</script>
<style scoped>
/* *{
  outline: 1px solid red;
} */

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}

.btn-accent1 {
  background-color: rgba(234, 241, 255, 1);
  color: rgba(24, 41, 101, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}
.btn-accent2 {
  background-color: rgba(246, 246, 246, 1);
  color: rgba(96, 96, 96, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 0.5rem 1rem;
  border-radius: 9px;
}

.loggedInBtn2 {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.filter {
  border: 1px solid rgba(218, 229, 239, 1);
  backdrop-filter: blur(20px);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.02);
  border-radius: 9px;
}
.pt-6 {
  padding-top: 6rem !important;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;
  
  font-size: 1rem !important;
  padding: 0.5rem 0.7rem;
  border-radius: 9px;
}

.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}

.bg-white {
  background-color: #fff;
}

.bg-card1 {
  background-color: #e4f5ff !important;
}
.bg-card2 {
  background-color: #e1ffea !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.bg-card3 {
  background-color: #ffeeee !important;
}

.home {
  background-color: rgba(0, 21, 63, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.home2 {
  background-color: rgba(0, 55, 187, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.home3 {
  background-color: rgba(224, 58, 111, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.home4 {
  background-color: rgba(0, 79, 36, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
