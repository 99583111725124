<template>
  <div>
    <AdminLayout Title="Counselling" :isRequired="false">
      <TableSkeletonLoader class="pt-6" v-if="loading2" />

      <div class="container pt-6" v-else>
        <router-link
          :to="{ name: 'AdminCounselling' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <div class="row mt-3">
          <div class="col-12 col-lg-5">
            <div class="row rounded-2">
              <div class="col-12">
                <div class="position-relative rounded-3 border bg-white">
                  <div
                    class="d-flex justify-content-between align-items-center px-3 pt-3 pb-2"
                  >
                    <span class="fw-semibold text-dark">{{
                      counsellingDetail?.title
                    }}</span>
                    <button class="btn-active">
                      {{ counsellingDetail?.status }}
                    </button>
                  </div>
                  <hr class="" />
                  <div class="row px-2">
                    <div class="col-12">
                      <div>
                        <span
                          class="text-body-secondary"
                          style="font-size: 0.9rem"
                          >Created By</span
                        >
                        <p
                          class="fw-semibold text-dark"
                          style="font-size: 0.93rem"
                        >
                          {{ counsellingDetail?.created_by?.first_name }}
                          {{ counsellingDetail?.created_by?.last_name }}
                        </p>
                      </div>
                    </div>
                    <div class="col-12">
                      <div>
                        <span
                          class="text-body-secondary"
                          style="font-size: 0.9rem"
                          >Validity Period</span
                        >
                        <p
                          class="fw-semibold text-dark"
                          style="font-size: 0.93rem"
                        >
                          {{ getFormattedDate(counsellingDetail?.start_time) }}
                          - {{ getFormattedDate(counsellingDetail?.end_time) }}
                        </p>
                      </div>
                    </div>
                    <div class="col-12">
                      <!-- <div>
                        <span
                          class="text-body-secondary"
                          style="font-size: 0.9rem"
                          >Registered Members</span
                        >
                        <p
                          class="fw-semibold text-dark"
                          style="font-size: 0.93rem"
                        >
                          120 people
                        </p>
                      </div> -->
                    </div>
                    <div class="col-12">
                      <div>
                        <span
                          class="text-body-secondary"
                          style="font-size: 0.9rem"
                          >Created On</span
                        >
                        <p
                          class="fw-semibold text-dark"
                          style="font-size: 0.93rem"
                        >
                          {{
                            getFormattedDate(
                              counsellingDetail?.created_by?.created_at
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="col-12">
                      <div>
                        <span
                          class="text-body-secondary"
                          style="font-size: 0.9rem"
                          >Session Time</span
                        >
                        <p
                          class="fw-semibold text-dark"
                          style="font-size: 0.93rem"
                        >
                          {{ counsellingDetail?.session_duration }} Minutes
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <p class="d-flex mt-3">
                  <button
                    class="updated text-center w-100 shadow-sm"
                    @click.prevent="showUpdateCounselling = true"
                  >
                    Update Details
                  </button>

                  <button
                    class="remove-btn text-center w-100 shadow-sm ms-3"
                    @click.prevent="showCloseCounselling = true"
                  >
                    Close Counselling
                  </button>
                </p>
              </div>
            </div>
          </div>
          <!-- // <div class="col-12 col-lg-7">
          //   <div class="bg-white p-2 rounded-2 b-color">
          //     <div class="row d-flex align-items-center">
          //       <div class="col-12 col-lg-6">
          //         <input
          //           type="text"
          //           placeholder="Enter to search"
          //           class="form-control p-2 ms-0"
          //         />
          //       </div>
          //       <div class="col-12 col-lg-6 d-flex justify-content-end">
          //         <div class="d-flex p-1 mb-1">
          //           <button class="m-1 btn-accent1 p-1">Coming Up</button>
          //           <button class="m-1 btn-accent2 p-1" href="#">Active</button>
          //           <button class="m-1 btn-accent2 p-1" href="#">Closed</button>
          //         </div>
          //       </div>
          //     </div>
          //   </div>

          //   <div class="card p-3 b-color mt-3">
          //     <div class="row">
          //       <div class="col-12">
          //         <div class="">
          //           <table class="table">
          //             <thead class="bg-grey">
          //               <tr class="bg-grey">
          //                 <td class="text-body-secondary">Full Name</td>
          //                 <td class=" text-body-secondary">
          //                   Counselling Session
          //                 </td>
          //                 <td class="text-center text-body-secondary">
          //                   Status
          //                 </td>
          //                 <td class="text-end text-body-secondary">
          //                   Date Booked
          //                 </td>
          //               </tr>
          //             </thead>
          //             <tbody>
          //               <tr>
          //                 <td class="d-flex align-items-center">
          //                   <div>
          //                     <img
          //                       src="../../assets/admin-avatar1.png"
          //                       alt=""
          //                       class="img-fluid"
          //                     />
          //                   </div>
          //                   <div class="ms-2">
          //                     <p class="fw-medium mb-0">Chinedu Emmanuel</p>
          //                   </div>
          //                 </td>
          //                 <td>Oreoluwa Ishola</td>
          //                 <td class="text-center">
          //                   <button class="btn-active">Coming Up</button>
          //                 </td>
          //                 <td class="text-end">24th May 2024</td>
          //               </tr>
          //               <tr>
          //                 <td class="d-flex align-items-center">
          //                   <div>
          //                     <img
          //                       src="../../assets/admin-avatar1.png"
          //                       alt=""
          //                       class="img-fluid"
          //                     />
          //                   </div>
          //                   <div class="ms-2">
          //                     <p class="fw-medium mb-0">Chinedu Emmanuel</p>
          //                   </div>
          //                 </td>
          //                 <td>Oreoluwa Ishola</td>
          //                 <td class="text-center">
          //                   <button class="btn-active">Coming Up</button>
          //                 </td>
          //                 <td class="text-end">24th May 2024</td>
          //               </tr>
          //               <tr>
          //                 <td class="d-flex align-items-center">
          //                   <div>
          //                     <img
          //                       src="../../assets/admin-avatar1.png"
          //                       alt=""
          //                       class="img-fluid"
          //                     />
          //                   </div>
          //                   <div class="ms-2">
          //                     <p class="fw-medium mb-0">Chinedu Emmanuel</p>
          //                   </div>
          //                 </td>
          //                 <td>Oreoluwa Ishola</td>
          //                 <td class="text-center">
          //                   <button class="btn-active">Coming Up</button>
          //                 </td>
          //                 <td class="text-end">24th May 2024</td>
          //               </tr>
          //               <tr>
          //                 <td class="d-flex align-items-center">
          //                   <div>
          //                     <img
          //                       src="../../assets/admin-avatar1.png"
          //                       alt=""
          //                       class="img-fluid"
          //                     />
          //                   </div>
          //                   <div class="ms-2">
          //                     <p class="fw-medium mb-0">Chinedu Emmanuel</p>
          //                   </div>
          //                 </td>
          //                 <td>Oreoluwa Ishola</td>
          //                 <td class="text-center">
          //                   <button class="btn-active">Coming Up</button>
          //                 </td>
          //                 <td class="text-end">24th May 2024</td>
          //               </tr>
          //             </tbody>
          //           </table>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div> -->
        </div>
      </div>

      <Modal :show="showCloseCounselling">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="showCloseCounselling = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-sign-out.png"
                alt=""
                class="img-fluid"
                width="100"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">Close Counselling</p>
            <p class="fw-light mt-0 text-center">
              Are you sure you want to close this counselling?
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <button
                class="signOutBtn btn-lg text-capitalize"
                @click.prevent="deleteCounselling"
              >
                Close Counselling
              </button>
              <button
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4"
                @click.prevent="showCloseCounselling = false"
              >
                Cancel and Go back
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>

      <Modal :show="showUpdateCounselling">
        <template #content>
          <img
            src="../../assets/close-circle.png"
            alt=""
            class="img-fluid position-absolute"
            style="top: 0; right: 0; cursor: pointer"
            @click.prevent="showUpdateCounselling = false"
          />
          <form
            action=""
            class="border-1 rounded-2 p-3"
            style="overflow-y: scroll"
          >
            <h3 class="header-text2">Update Counselling</h3>
            <div class="row">
              <div class="col-12">
                <p class="form-label mt-3">Title</p>
                <input
                  type="text"
                  required
                  class="p-2 form-control"
                  v-model="counsellingDetail.title"
                />
                <FormError :errorMessage="formErrors.title" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Assigned To</p>
                <multiselect
                  v-model="selectedUserObject"
                  :options="users"
                  :customLabel="customLabel"
                  track-by="id"
                  value-key="id"
                  :searchable="true"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :show-no-results="false"
                >
                  <template #noResult>
                    <span
                      >Oops! No User found. Consider changing your search
                    </span>
                  </template>
                </multiselect>
                <FormError :errorMessage="formErrors.assigned_to" />
              </div>

              <div class="col-12">
                <p class="form-label mt-3">Start Time</p>
                <input
                  type="time"
                  required
                  class="p-2 form-control"
                  v-model="counsellingDetail.start_time"
                />
                <FormError :errorMessage="formErrors.start_time" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">End Time</p>
                <input
                  type="time"
                  required
                  class="p-2 form-control"
                  v-model="counsellingDetail.end_time"
                />
                <FormError :errorMessage="formErrors.end_time" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Session Duration</p>
                <input
                  type="number"
                  required
                  class="p-2 form-control"
                  v-model="counsellingDetail.session_duration"
                />
                <FormError :errorMessage="formErrors.end_time" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Status</p>
                <multiselect
                  v-model="counsellingDetail.status"
                  :options="status"
                  :searchable="true"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :show-no-results="false"
                >
                  <template #noResult>
                    <span
                      >Oops! No User found. Consider changing your search
                    </span>
                  </template>
                </multiselect>
                <FormError :errorMessage="formErrors.end_time" />
              </div>

              <div class="col-12 mt-4">
                <p class="">
                  <button
                    class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                    @click.prevent="updateCounsellingDetail"
                  >
                    <span>Update Details</span>
                    <div>
                      <img
                        src="../../assets/arrow-right.svg"
                        alt=""
                        width="40"
                        height="40"
                        class="img-fluid"
                        v-if="!loading"
                      />
                      <div
                        class="spinner-grow text-white"
                        role="status"
                        v-else-if="loading"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </button>
                </p>
              </div>
            </div>
          </form>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>

      <Modal :show="counsellingSuccessfullyChanged">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="markedSuccessful = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-check-success.svg"
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Counselling Session Updated
            </p>
            <p class="text-center">
              You have successfully changed this counselling session
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <router-link
                :to="{ name: 'AdminCounselling' }"
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4 text-decoration-none"
                @click.prevent="markedSuccessful = false"
              >
                Back to Home
              </router-link>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import FormError from "@/components/FormError.vue";
import Multiselect from "vue-multiselect";

import {
  getFormattedDate,
  getFormattedDateNumber,
  getFormattedAmount,
} from "@/Utils/handleResponse";

const loading = ref(false);
const loading2 = ref(false);
const showCloseCounselling = ref(false);
const counsellingSuccessfullyChanged = ref(false);
const showUpdateCounselling = ref(false);

const selectedStatus = ref("");

const router = useRouter();

const selectedUserObject = ref({});
const status = ref(["Active", "Upcoming", "Completed", "Inactive"]);

const { id } = useRoute().params;
const formErrors = ref({});

const counsellingDetail = ref({});
const users = ref([]);

const customLabel = ({ first_name, last_name }) =>
  `${first_name ? first_name : ""} ${last_name ? last_name : ""}`;



watch(counsellingDetail, (newVal, oldVal) => {
  counsellingDetail.value.start_time = formatTime(
    counsellingDetail.value?.start_time
  );
  counsellingDetail.value.end_time = formatTime(
    counsellingDetail.value?.end_time
  );

});

function formatTime(time) {
  const date = new Date(time);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
}
function getCounsellingDetail() {
  loading2.value = true;
  axios
    .get(`/admin/counselling-sessions/${id}`)
    .then((response) => {
      // console.log(response.data.data);
      counsellingDetail.value = response.data.data;
      selectedUserObject.value = response.data.data?.assigned_to
      console.log()
      counsellingDetail.value.assigned_to = response.data.data?.assigned_to?.id;
      loading2.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading2.value = false;
    });
}

function updateCounsellingDetail() {
  loading.value = true;
  counsellingDetail.value.assigned_to = selectedUserObject?.id;

  axios
    .put(`/admin/counselling-sessions/${id}`, counsellingDetail.value)
    .then((response) => {
      showUpdateCounselling.value = false;
      counsellingSuccessfullyChanged.value = true;
      loading.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      formErrors.value = catchAxiosExceptions(error).formErrors;
      loading.value = false;
    });
}

function getUsers() {
  loading2.value = true;
  axios
    .get(`/admin/users`)
    .then((response) => {
      users.value = response.data.data;
      loading2.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading2.value = false;
    });
}
function deleteCounselling() {
  axios
    .delete(`/admin/counselling-sessions/${id}`)
    .then((response) => {
      utils.showToast("Successfully Closed", "success");
      router.push({ name: "AdminCounselling" });
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

onMounted(() => {
  getUsers();
  getCounsellingDetail();
});
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
/* *{
  outline: 1px solid red;
} */

 :deep(.multiselect__tags),
:deep(.multiselect__input),
:deep(.multiselect__single) {
  background-color: blue !important;
}

:deep(.multiselect__element) {
  background-color: transparent !important;
}

:deep(.multiselect__option--highlight) {
  background-color: blue !important;
  color: #fff !important;
}
.signOutBtn {
  background-color: #f24c35 !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.btn-accent1 {
  background-color: rgba(234, 241, 255, 1);
  color: rgba(24, 41, 101, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}
.btn-accent2 {
  background-color: rgba(246, 246, 246, 1);
  color: rgba(96, 96, 96, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}

.loggedInBtn2 {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.filter {
  border: 1px solid rgba(218, 229, 239, 1);
  backdrop-filter: blur(20px);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.02);
  border-radius: 9px;
}
.pt-6 {
  padding-top: 6rem !important;
}

.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}

.bg-white {
  background-color: #fff;
}

.bg-card1 {
  background-color: #e4f5ff !important;
}
.bg-card2 {
  background-color: #e1ffea !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.bg-card3 {
  background-color: #ffeeee !important;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.remove-btn {
  background-color: #ffeeee !important;
  font-weight: 500 !important;
  border: none;
  color: #d72509 !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
</style>
