<template>
  <UserLayout>
    <div class="container">

      <div class="pt-6 pb-4">
        <router-link
          :to="{ name: 'AdminPrayerMeetings' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <p class="text-end">
          <button
            class="btn btn-primary"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            v-if="inChannel"
          >
            Chat <i class="bi bi-chat-left-text ms-1 mt-3"></i>
          </button>
        </p>
  
        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasRightLabel">Messages</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <ChatRoom :channelName="channelName" />
          </div>
        </div>
        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasRight1"
          aria-labelledby="offcanvasRightLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasRightLabel">Attendees</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <ul>
              <li v-if="remoteUsers.length == 0" class="fs-4">No Attendees</li>
              <li
                v-for="(user, index) in remoteUsers"
                :key="index"
                class="fs-4 d-flex justify-content-between align-items-center"
              >
                <div>
                  <span class="">{{ user.name || `User ${user.uid}` }}</span>
                </div>
  
                <div v-if="userRole == 'super_admin'">
                  {{ remoteAudioMuted ? "yes" : "no" }}
                  <button
                    class="icon-btn"
                    @click="toggleRemoteAudio(user.uid)"
                    :title="`Mute ${user.name || `User ${user.uid}`}`"
                  >
                    <i
                      :class="remoteAudioMuted ? 'bi bi-mic' : 'bi bi-mic-mute'"
                    ></i>
                  </button>
                  <button
                    class="icon-btn"
                    @click.prevent="toggleRemoteVideo(user)"
                    :title="`Hide Video of ${user.name || `User ${user.uid}`}`"
                  >
                    <i
                      :class="
                        remoteVideoMuted
                          ? 'bi bi-camera-video-off'
                          : 'bi bi-camera-video'
                      "
                    ></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bg-dark">
        <div
          style="min-height: 50vh; background-color: #131313; border-radius: 15px"
          v-if="!inChannel"
        ></div>
  
        <div class="room" v-else>
          <div class="user-list">
            <div class="user-local" v-if="inChannel">
              <div
                id="local-video"
                style="
                  width: 100%;
                  height: 100%;
                  position: relative;
                  background-color: #131313;
                "
              >
                <samp
                  class="user-name position-absolute"
                  style="left: 0; z-index: 999"
                ></samp>
              </div>
            </div>
            <div class="remote-videos">
              <div
                class="user no-users d-flex justify-content-center align-items-center flex-column"
                v-if="remoteUsers.length == 0"
              >
                <p>
                  <img
                    src="@/assets/admin-crowd.svg"
                    alt="People"
                    class="img-fluid"
                    width="100"
                  />
                </p>
                <p class="text-white text-center" style="font-size: 1.4rem">
                  No Participant In the Meeting
                </p>
              </div>
              <div
                v-for="user in visibleUsers"
                :key="user.uid"
                class="user position-relative"
              >
                <samp
                  class="user-name position-absolute"
                  style="background-color: #131313"
                  >{{ user.name }}</samp
                >
                <div :id="`remoteVideo-${user.uid}`" style=""></div>
              </div>
              <div
                v-if="extraUserCount > 0"
                class="extra-users text-center text-white mt-2"
                style="font-size: 1.2rem"
              >
                +{{ extraUserCount }} more
              </div>
            </div>
          </div>
        </div>
        <div class="controls d-flex justify-content-between mt-3 p-1">
          <div class="d-flex align-items-center">
            <button
              @click.prevent="initializeClient"
              class="m-2 signInBtn d-flex align-items-center"
              v-if="!inChannel"
            >
              <span class="">Join Meeting</span>
              <div class="spinner-grow ms-3" role="status" v-if="loading">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
            <button
              @click.prevent="toggleAudio"
              class="toggleBtn rounded-circle shadow-lg"
              v-if="inChannel"
            >
              <i :class="audioMuted ? 'bi bi-mic-mute' : 'bi bi-mic'"></i>
            </button>
            <button
              @click.prevent="toggleVideo"
              class="toggleBtn rounded-circle shadow-lg"
              v-if="inChannel"
            >
              <i
                :class="
                  videoMuted ? 'bi bi-camera-video-off' : 'bi bi-camera-video'
                "
              ></i>
            </button>
        
            <div v-if="inChannel">
              <button
                class="fs-3 mx-2 rounded-circle border-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight1"
                aria-controls="offcanvasRight1"
              >
                <img src="../../assets/groupPeople.svg" alt="" class="img-fluid" />
              </button>
            </div>
          </div>
  
          <div>
            <button @click.prevent="leaveChannel" class="signOutBtn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 8q2.95 0 5.813 1.188T22.9 12.75q.3.3.3.7t-.3.7l-2.3 2.25q-.275.275-.638.3t-.662-.2l-2.9-2.2q-.2-.15-.3-.35t-.1-.45v-2.85q-.95-.3-1.95-.475T12 10t-2.05.175T8 10.65v2.85q0 .25-.1.45t-.3.35l-2.9 2.2q-.3.225-.663.2t-.637-.3l-2.3-2.25q-.3-.3-.3-.7t.3-.7q2.2-2.375 5.075-3.562T12 8"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </UserLayout>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  watch,
  computed,
  nextTick,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import ChatRoom from "@/components/ChatRoom.vue";
import {
  createClient,
  createMicrophoneAndCameraTracks,
} from "agora-rtc-sdk-ng";
import axios from "@/Plugins/axios";
import UserLayout from "@/components/Layouts/UserLayout.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";

import { useAuthStore } from "@/stores/auth";

import utils from "@/Plugins/utils";


const route = useRoute();

const messages = ref([]);

const store = useAuthStore();

const userRole = store?.user?.user_type;

const channelName = 'channel-675c7ce9c35cd';

const remoteAudioMuted = ref(true);
const remoteVideoMuted = ref(false);
// Reactive references
let remoteUsers = reactive([]);

const visibleUsers = computed(() => remoteUsers.slice(0, 6));
const extraUserCount = computed(() =>
  remoteUsers.length > 6 ? remoteUsers.length - 6 : 0
);
const audioMuted = ref(false);
const videoMuted = ref(false);
const router = useRouter();

// State for showing limited or all users
const showAll = ref(false);
const loading = ref(false);

watch(remoteUsers, (newUsers) => {
  remoteUsers = newUsers;
});
watch(messages, (newVal) => {
  messages.value = newVal;
});

const inChannel = ref(false);
watch(inChannel, (newVal) => {
  inChannel.value = newVal;
});



// Agora credentials
// const APP_ID = ref("");
const CHANNEL = ref("");
const TOKEN = ref(null);
const USER_ID = ref("");

// Initialize Agora client
const client = createClient({ mode: "rtc", codec: "vp8" });
// Local tracks
const localTracks = {
  audioTrack: null,
  videoTrack: null,
};

const toggleAudio = async () => {
  if (localTracks.audioTrack) {
    audioMuted.value = !audioMuted.value;
    await localTracks.audioTrack.setMuted(audioMuted.value);
    console.log(audioMuted.value ? "Audio muted" : "Audio unmuted");
  }
};

// Toggle video
const toggleVideo = async () => {
  if (localTracks.videoTrack) {
    videoMuted.value = !videoMuted.value;
    await localTracks.videoTrack.setEnabled(!videoMuted.value);

    if (videoMuted.value) {
      localTracks.videoTrack.stop();
    } else {
      const localContainer = document.getElementById("local-video");
      localTracks.videoTrack.play(localContainer);
    }
    console.log(videoMuted.value ? "Video disabled" : "Video enabled");
  }
};

const toggleRemoteVideo = async (remoteUser) => {
  if (remoteUser.videoTrack) {
    const container = document.getElementById(`remoteVideo-${remoteUser.uid}`);
    if (container) {
      remoteVideoMuted.value = !remoteVideoMuted.value;
      if (!remoteVideoMuted.value) {
        remoteUser?.videoTrack?.play(container);
      } else {
        remoteUser?.videoTrack?.stop();
      }
      console.log(`Toggled video for user: ${remoteUser.uid}`);
    }
  }
};

const toggleRemoteAudio = async (remoteUser) => {
  if (remoteUser.audioTrack) {
    remoteAudioMuted.value = !remoteAudioMuted.value;
    if (remoteAudioMuted.value) {
      remoteUser.audioTrack.setVolume(0);
      remoteUser.audioTrack.stop(); // Stop playing the remote user's audio
    } else {
      remoteUser.audioTrack.setVolume(100);
      remoteUser.audioTrack.play(); // Resume playing the remote user's audio
    }
    console.log(`Toggled audio for user: ${remoteUser.uid}`);
  }
};

// Leave the channel
const leaveChannel = async () => {
  inChannel.value = false;
  if (localTracks.audioTrack) localTracks.audioTrack.stop();
  if (localTracks.videoTrack) localTracks.videoTrack.stop();
  if (localTracks.audioTrack) localTracks.audioTrack.close();
  if (localTracks.videoTrack) localTracks.videoTrack.close();

  await client.leave();
  client.on("user-unpublished", handleUserUnpublished);
  client.on("user-left", handleUserLeft);
  console.log("Left the channel");
  router.push({ name: "AdminPrayerMeetings" });
};

async function generateAgoraToken() {
  let idUser = JSON.parse(localStorage.getItem("user"));
  try {
    const response = await axios.post(`/agora-token`, {
      channel_name: channelName,
      token_expiry: 360000,
      privilege_expiry: 360000,
      uid: idUser?.user_type == "super_admin" ? 1 : 0,
    });
    return response.data?.data?.token;
  } catch (error) {
    console.error("Error generating Agora token:", error.message);
  }
}
// Initialize Agora client and join the channel
const initializeClient = async () => {
  loading.value = true;
  let idUser = JSON.parse(localStorage.getItem("user"));
  USER_ID.value = idUser?.user_type == "super_admin" ? 1 : 0;
  try {
    TOKEN.value = await generateAgoraToken();
    // Join the channel
    await client.join(
      process.env.VUE_APP_AGORA_APP_ID,
      CHANNEL.value,
      TOKEN.value,
      USER_ID.value
    );

    inChannel.value = true;
    console.log("Joined channel successfully");
    loading.value = false;

    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);
    // Create local audio and video tracks
    [localTracks.audioTrack, localTracks.videoTrack] =
      await createMicrophoneAndCameraTracks();

    // Play local video

    const localContainer = document.getElementById("local-video");
    document.querySelector(".user-name").textContent = "You";
    localTracks.videoTrack.play(localContainer);

    // Publish local tracks
    await client.publish([localTracks.audioTrack, localTracks.videoTrack]);
    console.log("Published local tracks successfully");
  } catch (error) {
    loading.value = false;
    // console.error("Error initializing Agora client:", error);
    utils.showToast(catchAxiosExceptions(error).msg, "error");
  }
};

// Handle when a remote user publishes their media
async function handleUserPublished(user, mediaType) {
  await client.subscribe(user, mediaType); // Subscribe to the user's media

  let userAlreadyAdded = remoteUsers.find((u) => u.uid === user.uid);

  if (!userAlreadyAdded) {
    // Push the user to the remoteUsers array
    remoteUsers.push({
      uid: user.uid,
      name: user.name || `Guest`,
      videoTrack: user.videoTrack || null,
      audioTrack: user.audioTrack || null,
    });
  }

  if (mediaType === "video") {
    // Create and display the remote video container
    const videoContainer = document.createElement("div");
    videoContainer.id = `remoteVideo-${user.uid}`;
    videoContainer.classList.add("user"); // Apply the .user CSS class

    const sampElement = document.createElement("samp");
    sampElement.classList.add("user-name", "position-absolute");
    sampElement.textContent = `User ${user.uid}`;
    videoContainer.appendChild(sampElement);

    await nextTick();

    const remoteContainer = document.getElementById(`remoteVideo-${user.uid}`);

    if (remoteContainer) {
      remoteContainer.style.backgroundColor = "#131313";
      remoteContainer.style.width = "100%";
      remoteContainer.style.height = "100%";
      user.videoTrack.play(remoteContainer);
    } else {
      console.error("Remote container not found for user:", user.uid);
    }
  } else if (mediaType === "audio") {
    user.audioTrack.play(); // Play audio if it's an audio track
  }
}

// Function to handle user-unpublished event
const handleUserUnpublished = (user, mediaType) => {
  console.log(`User ${user.uid} unpublished ${mediaType}`);

  if (mediaType === "video") {
    const remoteContainer = document.getElementById(`remoteVideo-${user.uid}`);
    remoteContainer.classList.remove("user");
    remoteContainer.removeChild("samp");
    if (remoteContainer) {
      remoteContainer.parentElement?.removeChild(remoteContainer);
      remoteUsers = remoteUsers.filter((u) => u.uid !== user.uid);
      remoteContainer.style.backgroundColor = "";
      remoteContainer.style.width = "0";
      remoteContainer.style.height = "0";
    }
  }

  if (mediaType === "audio") {
    user.audioTrack.stop();
  }
};

// Function to handle user-joined event
const handleUserJoined = (user) => {
  utils.showToast(`A User has joined the meeting`, "info");
};

// Function to handle user-left event
const handleUserLeft = (user) => {
  utils.showToast(`A User has left the meeting`, "info");

  const index = remoteUsers.findIndex((u) => u.uid === user.uid);
  if (index !== -1) {
    // Remove the user from the `remoteUsers` array
    remoteUsers.splice(index, 1);
  }
  // Remove user from remoteUsers array and clean up their video
  const remoteContainer = document.getElementById(`remoteVideo-${user.uid}`);
  remoteContainer.classList.remove("user");
  remoteContainer.removeChild("samp");
  if (remoteContainer) {
    remoteContainer.style.backgroundColor = "";
    remoteContainer.style.width = "0";
    remoteContainer.style.height = "0";
    // Optionally, clean up the DOM element (only if you're not using Vue's reactivity)
    remoteContainer.parentElement?.removeChild(remoteContainer);
  }
};

// Cleanup function when component is unmounted
const cleanup = async () => {
  // Stop and close local tracks
  if (localTracks.videoTrack) {
    localTracks.videoTrack.stop();
    localTracks.videoTrack.close();
  }
  if (localTracks.audioTrack) {
    localTracks.audioTrack.stop();
    localTracks.audioTrack.close();
  }

  // Leave the channel
  await client.leave();
  console.log("Left the channel");
};


// Lifecycle hooks
onMounted(() => {
  client.on("user-published", handleUserPublished);
  client.on("user-unpublished", handleUserUnpublished);
  client.on("user-joined", handleUserJoined);
  client.on("user-left", handleUserLeft);

  const urlParams = new URLSearchParams(window.location.search);
  CHANNEL.value = urlParams.get("channel") || 'channel-675c7ce9c35cd';

});

onBeforeUnmount(() => {
  cleanup();
});
</script>

<style scoped>
/* * {
  outline: 1px solid red;
} */

.dropdown-toggle::after {
  display: none !important;
}

.btn-primary {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  padding: 0.6rem 1rem;
  border-radius: 9px;
  border: none;
  position: relative;
  overflow: hidden;
}

.btn-primary:hover {
  background-color: rgba(10, 64, 194, 0.8) !important;
  box-shadow: 0 4px 8px rgba(10, 64, 194, 0.3) !important;
  transform: translateY(-4px);
}

.user-list {
  padding: 2.5rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  display: flex;
  flex-wrap: nowrap; /* Ensures items wrap if needed */
  flex-direction: row; /* Side by side layout (default) */
}

.user {
  border-style: solid;
  border-radius: 6px;
  --un-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--un-border-opacity));
  border-width: 1px;
  box-sizing: border-box;
  width: 50vw;
  height: 50vh;
}
.user-local {
  border-style: solid;
  border-radius: 6px;
  --un-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--un-border-opacity));
  border-width: 1px;
  box-sizing: border-box;
  width: 50vw;
  height: 50vh;
}

.extra-users {
  font-size: 1.2rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}
.user-name {
  --un-text-opacity: 1;
  color: rgba(255, 255, 255, var(--un-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  --un-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--un-bg-opacity));
  grid-gap: 0.25rem;
  gap: 0.25rem;
  align-items: center;
  display: inline-flex;
  z-index: 2;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
}

.no-users {
  width: 100%;
  height: 50vh;
  background-color: #131313;
  border-radius: 6px;
}
.pt-6 {
  padding-top: 6rem !important;
}

.room {
  max-width: 80vw;
  width: 70vw;
  margin: 0 auto;
  padding: 0.5rem;
}

@media only screen and (max-width: 596px) {
  .room {
    max-width: 100vw;
    width: 100vw;
    margin: 0 auto;
  }

  .user-local {
    border-style: solid;
    border-radius: 6px;
    --un-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--un-border-opacity));
    border-width: 1px;
    box-sizing: border-box;
    width: 100%;
    height: 50vh;
  }
  .user {
    border-style: solid;
    border-radius: 6px;
    --un-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--un-border-opacity));
    border-width: 1px;
    box-sizing: border-box;
    width: 100%;
    height: 50vh;
  }

  .user-list {
    flex-wrap: wrap; /* Ensures items wrap if needed */
    flex-direction: column-reverse; /* Stack items vertically */
  }
}

@media (max-width: 768px) {
  .user-local {
    border-style: solid;
    border-radius: 6px;
    --un-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--un-border-opacity));
    border-width: 1px;
    box-sizing: border-box;
    width: 100%;
    height: 50vh;
  }
  .user {
    border-style: solid;
    border-radius: 6px;
    --un-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--un-border-opacity));
    border-width: 1px;
    box-sizing: border-box;
    width: 100%;
    height: 50vh;
  }
  .user-list {
    flex-wrap: wrap; /* Ensures items wrap if needed */
    flex-direction: column-reverse; /* Stack items vertically */
  }
}

@media only screen and (min-width: 568px) and (max-width: 821px) {
  .room {
    max-width: 95vw;
    width: 95vw;
    margin: 0 auto;
  }
  .user-list {
    flex-wrap: wrap; /* Ensures items wrap if needed */
    flex-direction: column-reverse; /* Stack items vertically */
  }

  .user-local {
    border-style: solid;
    border-radius: 6px;
    --un-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--un-border-opacity));
    border-width: 1px;
    box-sizing: border-box;
    width: 100%;
    height: 50vh;
  }
  .user {
    border-style: solid;
    border-radius: 6px;
    --un-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--un-border-opacity));
    border-width: 1px;
    box-sizing: border-box;
    width: 100%;
    height: 50vh;
  }
}

.remote-videos {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 0.5rem 0 0.3rem;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

.toggleBtn {
  margin: 5px;
  font-size: 1.4rem;
  padding: 0.4rem 0.7rem;
  border: 1px solid grey;
}

.signOutBtn {
  background-color: #f24c35 !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 0.7rem 1.5rem;
  border-radius: 9px;
}
</style>
