<template>
  <div>
    <AdminLayout :Title="user?.first_name" :isRequired="true">
      <div class="container pt-6">
        <div>
          <TableSkeletonLoader v-if="loading" />

          <div class="row" v-else>
            <div class="col-12 col-lg-8">
              <div class="row g-1">
                <div class="col-6 col-lg-3">
                  <div
                    class="card p-2 border-0 bg-card1 d-flex justify-content-between flex-column"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p>Testimonies</p>
                      <img
                        src="../../assets/admin-testimony-target.png"
                        class="img-fluid d-flex align-self-start"
                      />
                    </div>
                    <div class="d-flex">
                      <h5 class="mt-3 fw-semibold">
                        {{ dashboardData?.testimony_count }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3">
                  <div
                    class="card p-2 border-0 bg-card2 d-flex justify-content-between flex-column"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p>Prayer Request</p>
                      <img
                        src="../../assets/admin-dashboard-prayer-request.png"
                        class="img-fluid d-flex align-self-start"
                      />
                    </div>
                    <div class="d-flex">
                      <h5 class="mt-3 fw-semibold">
                        {{ dashboardData?.prayer_point_count }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3">
                  <div
                    class="card p-2 border-0 bg-card3 d-flex justify-content-between flex-column"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p>Prayer Meetings</p>
                      <img
                        src="../../assets/admin-dashboard-meetings.png"
                        class="img-fluid d-flex align-self-start"
                      />
                    </div>
                    <div class="d-flex">
                      <h5 class="mt-3 fw-semibold">
                        {{ dashboardData?.prayer_meeting_count }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3">
                  <div
                    class="card p-2 border-0 bg-card4 d-flex justify-content-between flex-column"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p>Recordings</p>
                      <img
                        src="../../assets/admin-dashboard-recordings.png"
                        class="img-fluid d-flex align-self-start"
                      />
                    </div>
                    <div class="d-flex">
                      <h5 class="mt-3 fw-semibold">
                        {{ dashboardData.recording_count }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <div class="d-flex justify-content-between">
                  <h5 class="fw-semibold">Prayer Requests</h5>
                  <router-link :to="{ name: 'AdminPrayerRequest' }"
                    >View All</router-link
                  >
                </div>
                <table class="table">
                  <thead class="bg-grey">
                    <tr class="bg-grey">
                      <td class="text-body-secondary">Prayer Request</td>
                      <td class="text-center text-body-secondary">Status</td>
                      <td class="text-end text-body-secondary">
                        Date Submitted
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="dashboardPrayerRequestsEmpty">
                      <td class="text-semibold text-center">
                        No Prayer Request
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr
                      v-for="prayer in dashboardData.new_prayer_points"
                      :key="prayer.id"
                    >
                      <td>{{ prayer?.subject }}</td>
                      <td class="text-center">
                        <button
                          :class="{
                            'btn-active': prayer?.is_answered == false,
                            'btn-inactive': prayer?.is_answered == true,
                          }"
                        >
                          {{
                            prayer?.is_answered == false ? "Active" : "Closed"
                          }}
                        </button>
                      </td>
                      <td class="text-end">
                        {{ getFormattedDate(prayer?.created_at) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12 col-lg-4">
              <div class="">
                <div class="d-flex justify-content-between">
                  <h5 class="fw-semibold">New Members</h5>
                  <router-link :to="{ name: 'AdminMembers' }"
                    >View All</router-link
                  >
                </div>
                <table class="table">
                  <tbody>
                    <tr
                      v-for="(member, index) in dashboardData.new_members"
                      :key="member.id"
                    >
                      <td class="d-flex align-items-center">
                        <div>
                          <img
                            src="../../assets/admin-avatar1.png"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                        <div class="ms-2">
                          <p class="fw-medium mb-0">
                            {{ member?.first_name }} {{ member?.last_name }}
                          </p>
                          <small class="text-body-secondary mt-0">{{
                            member?.email
                          }}</small>
                        </div>
                      </td>
                      <td class="text-end">
                        <small class="text-capitalize">{{
                          member?.user_type
                        }}</small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h5 class="fw-semibold mt-4">Prayer Analytics</h5>
            <VueApexCharts
              type="bar"
              :options="chartOptions"
              :series="chartSeries"
            />
          </div>
        </div>
      </div>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";

import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";
import VueApexCharts from "vue3-apexcharts";


import utils from "@/Plugins/utils";

import axios from "@/Plugins/axios";
import { useAuthStore } from "@/stores/auth";

const { user } = useAuthStore();

const months = ref([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);

const dashboardData = ref({});
const dashboardPrayerRequestsEmpty = ref(false);
const chartOptions = ref({
  chart: {
    type: "bar",
    // stacked: true, // Enable stacking
  },
  plotOptions: {
    bar: {
      horizontal: false, // Set to false for vertical bars (stacked columns)
      columnWidth: "50%",
    },
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: [
      // "January",
      // "February",
      // "March",
      // "April",
      // "May",
      // "June",
      // "July",
      // "August",
      // "September",
      // "October",
    ], // Replace with your own categories
      title: {
      text: 'Months'
    }
  },
  yaxis: {
      title: {
      text: 'Total Number of Users'
    }
  },
  fill: {
    opacity: 1,
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#0A40C2", "#16C046"], // Add colors for the series
  legend: {
    position: "top",
    horizontalAlign: "center",
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
});

const chartSeries = ref([
  {
    name: "Ongoing",
    data: [], // Replace with your own data
  },
  {
    name: "Answered",
    data: [],
  },
]);

const loading = ref(false);

function generateChartOptions(categories) {
  return {
    chart: {
      type: "bar",
      // stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
        title: {
      text: 'Months'
    }
    },
     yaxis: {
      title: {
      text: 'Total Number of Users'
    }
   
  },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#0A40C2", "#16C046"], // You can adjust these colors as needed
    legend: {
      position: "top",
      horizontalAlign: "center",
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
}

function generateChartSeries(data) {
  const ongoingData = data.map((item) => item.ongoing_prayers);
  const answeredData = data.map((item) => item.answered_prayers);

  return [
    {
      name: "Ongoing",
      data: ongoingData,
    },
    {
      name: "Answered",
      data: answeredData,
    },
  ];
}

async function initDashboard() {
  loading.value = true;
  try {
    const _dashboardData = await axios.get(`/admin/dashboard`);
    const _dashboardChart = await axios.get(`/admin/prayer-points-chart`);
    const dashboardChart = _dashboardChart.data.data;
    dashboardData.value = _dashboardData.data.data;
    dashboardPrayerRequestsEmpty.value =
      dashboardData.value.new_prayer_points.length == 0 ? true : false;
    const transformedData = {
      categories: dashboardChart.map((item) => months.value[item.month - 1]),
      series: generateChartSeries(dashboardChart),
    };

    chartOptions.value = generateChartOptions(transformedData.categories);
    chartSeries.value = transformedData.series;
    loading.value = false;
  } catch (error) {
    utils.showToast(catchAxiosExceptions(error).msg, "error");
    loading.value = false;
  }
}

onMounted(() => {
  initDashboard();
});
</script>
<style scoped>
/* * {
  outline: 1px solid red;
} */

.bg-card1 {
  background-color: #ffeded;
}
.bg-card2 {
  background-color: #fdf2ff;
}
.bg-card3 {
  background-color: #fff3ed;
}
.bg-card4 {
  background-color: #e4f0f7;
}

.bg-grey {
  background-color: #f9f8fa !important;
}
.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}
.btn-inactive {
  border: none !important;
  color: green !important;
  background-color: rgba(54, 202, 54, 0.453) !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}
.btn-pending {
  border: none !important;
  color: #f7c708 !important;
  background-color: #fff9e2 !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}

.pt-6 {
  padding-top: 6rem !important;
}

.table {
  --bs-table-bg: #f9f8fa !important;
}
</style>
